import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { AuthStatus } from "../interfaces";
import { checkAuthAction, loginAction, registerAction } from "../actions";
import { useLocalStorage } from "@vueuse/core";

export const useAuthStore = defineStore("auth", () => {
  const authStatus = ref(AuthStatus.Checking);
  const user = ref(undefined);
  const token = ref(useLocalStorage("vacio", ""));

  const login = async (email, password) => {
    try {
      const loginResp = await loginAction(email, password);
      if (!loginResp.ok) {
        logout();
        return false;
      }

      user.value = loginResp.user;
      token.value = loginResp.token;
      authStatus.value = AuthStatus.Authenticated;

      return true;
    } catch (error) {
      return logout();
    }
  };

  const register = async (fullName, email, password) => {
    try {
      const registerResp = await registerAction(fullName, email, password);

      if (!registerResp.ok) {
        logout();
        return { ok: false, message: registerResp.message };
      }

      user.value = registerResp.user;
      token.value = registerResp.token;
      authStatus.value = AuthStatus.Authenticated;

      return { ok: true, message: "" };
    } catch (error) {
      return { ok: false, message: "Error al registrar el usuario" };
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    localStorage.removeItem('restaurant');
    localStorage.removeItem('stripeAccountId');
    
     localStorage.removeItem('customerName');
     localStorage.removeItem('customerAvatar');
     localStorage.removeItem('customerPhone');
     localStorage.removeItem('customerEmail');
     localStorage.removeItem('identifier');
     localStorage.removeItem('country');
     localStorage.removeItem('language');

    authStatus.value = AuthStatus.Unauthenticated;
    user.value = undefined;
    token.value = "";
    return false;
  };

  const checkAuthStatus = async () => {
    try {

      const statusResp = await checkAuthAction();

      if (!statusResp.ok) {
        logout();
        return false;
      }

      authStatus.value = AuthStatus.Authenticated;
      user.value = statusResp.user;
      token.value = statusResp.token;
      return true;
    } catch (error) {
      logout();
      return false;
    }
  };

  return {
    user,
    token,
    authStatus,

    // Getters
    isChecking: computed(() => authStatus.value === AuthStatus.Checking),
    isAuthenticated: computed(
      () => authStatus.value === AuthStatus.Authenticated
    ),
    isAdmin: computed(() => user.value?.roles.includes("admin") ?? false),
    username: computed(() => user.value?.fullName),

    // Actions
    login,
    logout,
    register,
    checkAuthStatus,
  };
});
