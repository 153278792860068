<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)" class="p-2">
      <md-card>
        <md-card-header  v-if="formTitle" class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
            <h4 class="title">{{ formTitle }}</h4>

               <slot name="top"></slot>
        </md-card-header>
        <md-card-content>
          <div v-for="(input, index) in inputs" :key="index" class="field-row" :class="input.type+'-field'">
            <label class="md-layout-item md-form-label">{{ input.label }}</label>
            <div class="md-layout-item md-xsmall-size-100">
              <ValidationProvider
                :name="input.name"
                :rules="getValidationRules(input.name)"
                v-slot="{ passed, failed }"
              >
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <template v-if="input.type === 'image'">
                    <div class="file-input img-circle">
                      <template v-if="!formData[input.name]">
                        <div class="image-container">
                          <img :src="input.placeholder || avatarImg" alt="Image preview" />
                        </div>
                      </template>
                      <div class="image-container" v-else>
                        <img :src="formData[input.name]" alt="Uploaded image" />
                      </div>
                      <div class="button-container">
                        <md-button
                          class="md-danger md-round"
                          v-if="formData[input.name]"
                          @click="removeImage(input.name)"
                        >
                          <i class="fa fa-times"></i> Remove
                        </md-button>
                        <md-button class="md-success md-round md-fileinput" :disabled="isUploading">
                          <template v-if="!formData[input.name]">Select image</template>
                          <template v-else>Change</template>
                          <input type="file" accept="image/png, image/jpeg, image/gif" @change="onFileChange($event, input.name)" />
                        </md-button>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="input.type === 'select'"> 

                    <md-select 
                      v-model="formData[input.name]" 
                      :multiple="input.multiple || false" 
                      v-if="input.options && input.options[0] && input.options[0].value"
                    >
                      <md-option v-for="option in input.options" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </md-option>
                    </md-select>
                    <md-select 
                      v-else
                      v-model="formData[input.name]" 
                      :multiple="input.multiple || false" 
                    >
                      <md-option v-for="option in input.options" :key="option" :value="option">
                        {{ option }}
                      </md-option>
                    </md-select>
                  </template>
                  
                  <!-- Si el tipo es otro, usamos el input por defecto -->

                  <template v-else>
                    <md-input v-model="formData[input.name]" :type="input.type" />
                  </template>

                  <!-- Mostrar iconos de validación -->
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>

            </div>
          </div>
               <slot name="content"></slot>
        </md-card-content>

        <md-card-actions class="text-center">
          <md-button class="md-danger" @click="cancel()">{{$t('cancel')}}</md-button>
          <md-button type="submit" class="md-success" :disabled="isUploading">{{ buttonLabel }}</md-button>
        </md-card-actions>
      </md-card>
    </form>
  </ValidationObserver>
</template>

<script>
import { SlideYDownTransition } from "vue2-transitions";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { uploadImageAction } from "@/pages/Products/actions"; // Importar la acción para subir la imagen
import Swal from "sweetalert2"; // Importar SweetAlert para notificaciones
import Vue from "vue"; // Importar Vue para usar Vue.set
import permissionsMixin from '@/mixins/permissionsMixin';

extend("required", required);

export default {
  mixins: [permissionsMixin],
  components: {
    SlideYDownTransition,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    inputs: {
      type: Array,
      required: true,
      default: () => [],
    },
    requiredFields: {
      type: Array,
      default: () => [],
    },
    preloadedData: {
      type: Object,
      default: () => ({}),
    },
    buttonLabel: {
      type: String,
      default: "Submit",
    },
    formTitle: {
      type: String,
    },
    avatarImg: {
      type: String,
      default: './img/placeholder.jpg',  // Imagen por defecto para el avatar
    },
  },
    created(){
      if(!this.formData.image){
        this.formData.image = 'https://gotakeit.s3.eu-west-1.amazonaws.com/1729869115963_IMG_0834.jpeg';
      }
    },
  data() {
    return {
      formData: { ...this.preloadedData },
      selectedImageFile: null, // Archivo de imagen seleccionado
      isUploading: false, // Estado de carga de imagen
    };
  },
  methods: {
    getValidationRules(fieldName) {
      return this.requiredFields.includes(fieldName) ? "required" : "";
    },
    cancel(){
      this.$emit('cancel')
    },
    async submit() {      

      if(this.isDemo()){
        return;
      }
      // Verificamos si hay una imagen pendiente de subircon

      for (const input of this.inputs) {
        if (input.type === 'image' && this.selectedImageFile) {
          this.isUploading = true;
          const response = await uploadImageAction(this.selectedImageFile); // Subir imagen
          if (response.ok) {
            // Usar Vue.set para actualizar el campo reactivamente
            Vue.set(this.formData, input.name, response.data.data.Location); // URL de la imagen subida
            this.selectedImageFile = null; // Reseteamos el archivo de imagen seleccionado
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: this.$t('errorUploadingImage'),
            });
            this.isUploading = false;
            return;
          }
        }
      }
      this.isUploading = false;
        
      // Emitimos el objeto con los datos del formulario
      this.$emit("formSubmitted", this.formData);
    },
    onFileChange(e, fieldName) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      // Verificar que el archivo es una imagen válida
      const file = files[0];
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

      if (!validImageTypes.includes(file.type)) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid file type',
          text: 'Please upload an image file (png, jpg, jpeg, gif).',
        });
        return;
      }

      this.selectedImageFile = file; // Guardamos la imagen seleccionada

      // Convertimos la imagen a base64 temporalmente para previsualización
      let reader = new FileReader();
      reader.onload = (event) => {
        // Usar Vue.set para actualizar el campo reactivamente
        Vue.set(this.formData, fieldName, event.target.result); // Actualizamos la previsualización del avatar o imagen
      };
      reader.readAsDataURL(file);
    },
    removeImage(fieldName) {
      // Usar Vue.set para eliminar la imagen de manera reactiva
      Vue.set(this.formData, fieldName, "");
      this.selectedImageFile = null; // Reiniciamos el archivo de imagen seleccionado
    },
  },
};
</script>
