<template>
 <div>
     <CreatePlanning
      v-if="openedit"
      :key="item._id" 
      :preloadedData="item"
      @formSubmitted="onPlanningUpdated"
      @cancel="openedit=false"
    />
        <div  class="banner  w-100 bg-white"  >  
          <div class="bannerplanning logoresto p-3" :style="'background:url('+item.image+')'">
            <div class="text-white "><h4 >{{ item.name }} - {{ $t(item.goal) }} </h4>

            <span class="badge badge-danger" v-if="item.myPlanning && item.myPlanning.status == 'pending'">Asingada a mí</span>
            <span class="badge badge-danger" v-if="item.myPlanning && item.myPlanning.status == 'done'">Rutina Finalizada</span>
            <small class="badge" v-if="item.myPlanning && item.myPlanning.status == 'done'">Fecha de finalización: {{localDate(item.myPlanning.endAt)}}</small>
            <span class="badge badge-success" v-if="item.myPlanning && item.myPlanning.status == 'inprogress' && item.myPlanning.startedAt">
             <md-icon class="text-white">directions_run</md-icon> Fecha de inicio: {{localDate(item.myPlanning.startedAt)}}
            </span>

            </div>
          </div>  

          <div class="p-2">

            <md-button @click="start()" class="md-button md-success w-100 " v-if="item.myPlanning && item.myPlanning.status == 'pending'">
              <md-icon>directions_run</md-icon> {{$t('getStarted')}}   <md-icon>play_arrow</md-icon>
            </md-button> 
            <md-button @click="end()" class="md-button md-danger w-100 " v-if="item.myPlanning && item.myPlanning.status == 'inprogress'">
              <md-icon>pan_tool</md-icon> {{$t('Terminar')}}   
            </md-button> 

             <myclientsselect @cancel="choose=false" @result="assignPlanning($event)" v-if="choose" />
          <div class="p-2 mt-2"> 
             <div class="row border-bottom border-1 pb-3"> 
              <div class="w-50 text-center"> 
              <div><md-icon>signal_cellular_alt</md-icon></div><div> {{ item.difficulty }}</div>
              </div>
              <div class="w-50  text-center"> 
                <small  v-if="item.duration < 15">
               <div><md-icon>timer</md-icon></div>
                {{ item.duration }} días</small>
                <div v-else>
                <div><md-icon>timer</md-icon></div>
                {{ Math.round(item.duration/7) }} semanas</div>
                 <small class="text-secondary"> ({{ item.dayperweek }} días por semana)</small>
              </div>
          </div> 
         
            <md-button class="md-sm md-button md-primary" 
            @click="openedit=true" 
            v-if="canEdit()" >
              <md-icon>edit</md-icon> {{$t('edit')}}
            </md-button> 

            <md-button class="md-sm md-button md-danger " 
          @click="confirmDelete(item)"
            v-if="canEdit()" >
              <md-icon>delete</md-icon> {{$t('delete')}}
            </md-button> 


            <md-button  
            v-if="!isOwner() && canEdit()" 
            class="ml-2 md-sm md-button md-primary " 
            @click="onlyOwnerPlease()">
             <md-icon>edit</md-icon> {{$t('edit')}} 
            </md-button>    

            <md-button class="ml-2 md-sm md-button md-success " 
            v-if="(isGym() || isTeacher()) && item.source == 'custom'"
             @click="choose=true" 
             :disabled="choose" >
             <md-icon>person</md-icon> Asignar a un alumno 
            </md-button> 

            <md-button  
            v-if="isGym() && item.source != 'custom'" 
            class="ml-2 md-sm md-button md-success" @click="duplicatePlease()">
             <md-icon>person</md-icon> Asignar a un alumno 
            </md-button>    

            </div>  
            <md-button  class="ml-2 md-sm md-button md-primary " @click="copy()">
             <md-icon>file_copy</md-icon> Duplicar 
            </md-button> 
            
              <div class="mt-3">
                 <small class="text-secondary">{{ item.description }}</small>
              </div> 
       
                      
                    <md-button class="ml-2 md-button md-success " v-if="isClientGym() && !item.myPlanning" @click="assignPlanning()" :disabled="choose" >
                     <md-icon>edit_note</md-icon> Asignarme esta rutina
                    </md-button>    
          </div> 
        </div>  
            <md-button class="w-100 md-button md-primary" @click="open=true" v-if="!open && isOwner()">
              + Agregar Entranamiento
            </md-button>
           <TrainingListClient  
            :myPlanning="item.myPlanning"
            v-if="!open && item._id" 
            @refresh="onPlanningUpdated()"
            :planningId="item._id" 
            :source="isOwner() ? 'custom' : 'default'" />

          <template>
            <CreateTraining  @formSubmitted="trainingCreated($event)" :planningId="item._id" @cancel="open=false" v-if="open"/>
          </template>
          <template>
          </template>
                 
  </div>
</template>

<script>

import { createUserPlanningAction,copyPlanningAction,updateUserPlanningAction ,deletePlanningAction } from "@/pages/Training/actions";
import Swal from 'sweetalert2';
import permissionsMixin from '@/mixins/permissionsMixin';
import CreateTraining from "@/pages/Training/CreateTraining.vue";
import TrainingListClient from "@/pages/Training/TrainingListClient.vue";
import myclientsselect from "@/pages/Users/myclientsselect.vue";
import CreatePlanning from "@/pages/Training/CreatePlanning.vue";

export default {
  mixins: [permissionsMixin],
  data() {
    return {
      open:false,
      openedit:false,
      newTraining: false,
      choose:false
    };
  },
  props: {
    item:Object
  },
  methods: {

    async confirmDelete(planning) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete the planning "${planning.name}". This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel'
      });

      if (result.isConfirmed) {
        this.deletePlanning(planning._id);
      }
    },
    async deletePlanning(id) {
      try {
        const response = await deletePlanningAction(id);
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Planning deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });
          // Recargar la lista de plannings
            this.$router.push(`/planning`);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete planning: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error deleting planning:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    localDate(d){
      return this.getLocalDate(d)
    },
    start(){
      Swal.fire({
        title: 'Comenzar el entrenamiento',
        text: 'Se registrará el dia de hoy como el inicio de tu rutina',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('getStarted')
      }).then((result) => {
        if (result.isConfirmed) {
            this.updateUserPlanning(this.item.myPlanning._id,'inprogress');
        }
      })
    },
    end(){
      Swal.fire({
        title: this.$t('areYouSure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes')
      }).then((result) => {
        if (result.isConfirmed) {
            this.updateUserPlanning(this.item.myPlanning._id,'done');
        }
      })
    },
    async updateUserPlanning(id,status){
      await updateUserPlanningAction(id,status);
       this.$emit('refresh')
    },
    duplicatePlease(){
      Swal.fire({
        title: 'Rutinas precargadas',
        text: 'Para asignar o editar rutinas precargadas primero haz una copia de la misma con el boton duplicar',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    },
    isStarted(){
        return (this.item.myPlanning && this.item.myPlanning.status == 'inprogress')
    },
    isEnd(){
        return (this.item.myPlanning && this.item.myPlanning.status == 'done')
    },
    canEdit(){
        return (this.isOwner() && !this.openedit && this.item.source == 'custom' && !this.isEnd() && !this.isStarted());
    },
    copy(){
      if(this.isDemo()){
        return;
      }

      this.copyPlanning();
    },
    async copyPlanning(){
        const result = await copyPlanningAction(this.item._id);
        if(result.planning){
      
            this.$router.push(`/planning/${result.planning._id}`);
              window.location.reload();
        }
    },
    isOwner(){
      const profile = JSON.parse(localStorage.getItem('profile'));

      return (this.item.user && this.item.user == profile._id)
    },
    onlyOwnerPlease(){
      Swal.fire({
        title: 'Debes ser el dueño',
        text: 'Solo puedes editar tus propias rutinas, puedes hacer una copia de esta con el boton duplicar',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    },
    async assignPlanning(formData){
        if(this.isDemo()){
        return;
      }


      if(formData && formData.users.length){
        await createUserPlanningAction(formData.users,this.item._id);
      }else{
        await createUserPlanningAction(false,this.item._id);
      }
      Swal.fire({
        title: this.$t('success'),
        icon: 'success',
        confirmButtonText: 'OK',
      });
        this.$emit('refresh')
      this.choose=false
    },
    onPlanningUpdated(){
        this.openedit=false
        this.$emit('refresh')
    },
    trainingCreated(response){
      if(response){
      this.newTraining = response.training;

      }
      this.open = false
      this.$emit('refresh')
    }
  },
  components: {
    CreateTraining,
    TrainingListClient,
    CreatePlanning,
    myclientsselect
  },
};
</script>
<style scoped>
.bannerplanning{
    height:250px !important;
    width: 100% !important;
    border-radius: 0;
    background-blend-mode: multiply !important;
    background-color: #787878 !important;
}
</style>

