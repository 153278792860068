import { wencesApi } from "@/api/wencesApi";

// Crear un nuevo elemento
export const createElementAction = async (name, description, bodyZones, image) => {
  try {
    const { data } = await wencesApi.post("/api/element", {
      name,
      description,
      bodyZones,
      image
    });
    return {
      ok: true,
      element: data.element
    };
  } catch (error) {
    return {
      ok: false,
      message: "No se pudo crear el elemento",
    };
  }
};

// Actualizar un elemento
export const updateElementAction = async (id, updates) => {
  try {
    const { data } = await wencesApi.put(`/api/element/${id}`, updates);
    return {
      ok: true,
      element: data.element
    };
  } catch (error) {
    return {
      ok: false,
      message: "No se pudo actualizar el elemento",
    };
  }
};

// Eliminar un elemento
export const deleteElementAction = async (id) => {
  try {
    await wencesApi.delete(`/api/element/${id}`);
    return {
      ok: true,
      message: "Elemento eliminado exitosamente"
    };
  } catch (error) {
    return {
      ok: false,
      message: "No se pudo eliminar el elemento",
    };
  }
};

// Obtener un elemento específico
export const getElementAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/element/${id}`);
    return {
      ok: true,
      element: data
    };
  } catch (error) {
    return {
      ok: false,
      message: "No se pudo obtener el elemento",
    };
  }
};

// Obtener todos los elementos
export const getAllElementsAction = async () => {
  try {
    const { data } = await wencesApi.get("/api/element");

    return {
      ok: true,
      elements: data
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo obtener los elementos",
    };
  }
};
