import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";
import i18n from './i18n';

// Plugins
import App from "./App.vue";
import Chartist from "chartist";
import CountryRegionSelect from "vue-country-region-select";
import { createPinia } from "pinia";

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
// router setup
import routes from "./routes/routes";
import messages from "./locales"; // Asegúrate de tener tus mensajes de localización en esta carpeta

// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(i18n); // Usa VueI18n
Vue.use(CountryRegionSelect);
Vue.use(createPinia());

document.addEventListener('touchstart', function(event) {
  if (event.touches.length > 1) {
    event.preventDefault();
  }
}, { passive: false });

document.addEventListener('gesturestart', function(event) {
  event.preventDefault();
});

// Función para detectar si la URL tiene un hash
function getHistoryMode() {
  return !window.location.hash ? 'history' : 'hash';
}

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
    mode: getHistoryMode(), // Modo de historial para URLs sin el hash #
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash, behavior: "smooth" };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "nav-item active",
});



// Interceptor de navegación
router.beforeEach((to, from, next) => {
  // URL específica que quieres redirigir
  const specificUrl = '/menu/66843e8440848a6b8f1dcd9e';
  const currentDomain = window.location.hostname;

  if (to.path === specificUrl && currentDomain !== 'nun.app.gotake.it') {
    // Redirigir a la nueva URL
    window.location.href = 'https://nun.app.gotake.it/' + to.fullPath;
  } else {
    // Continuar con la navegación normal
    next();
  }
});


export const currencies = [
    { symbol: '€', value: 'eur' },  // Euro
    { symbol: '$', value: 'usd' },  // US Dollar
    { symbol: '£', value: 'gbp' },  // British Pound
    { symbol: '¥', value: 'jpy' },  // Japanese Yen
    { symbol: '₣', value: 'chf' },  // Swiss Franc
    { symbol: 'C$', value: 'cad' }, // Canadian Dollar
    { symbol: 'A$', value: 'aud' }, // Australian Dollar
    { symbol: '₽', value: 'rub' },  // Russian Ruble
    { symbol: '₹', value: 'inr' },  // Indian Rupee
    { symbol: '$', value: 'sgd' },  // Singapore Dollar
    { symbol: '$', value: 'nzd' },  // New Zealand Dollar
    { symbol: 'kr', value: 'sek' }, // Swedish Krona
    { symbol: 'kr', value: 'nok' }, // Norwegian Krone
    { symbol: 'kr', value: 'dkk' }, // Danish Krone
    { symbol: 'HK$', value: 'hkd' },// Hong Kong Dollar
    { symbol: '₩', value: 'krw' },  // South Korean Won
    { symbol: '₪', value: 'ils' },  // Israeli Shekel
    { symbol: 'R$', value: 'brl' }, // Brazilian Real
    { symbol: '$', value: 'mxn' },  // Mexican Peso
    { symbol: '$', value: 'ars' },  // Argentine Peso
    { symbol: 'zł', value: 'pln' }, // Polish Złoty
    { symbol: 'R', value: 'zar' },  // South African Rand
    { symbol: '₫', value: 'vnd' },  // Vietnamese Đồng
    { symbol: '฿', value: 'thb' },  // Thai Baht
    { symbol: '₴', value: 'uah' },  // Ukrainian Hryvnia
    { symbol: 'Kč', value: 'czk' }, // Czech Koruna
    { symbol: 'Ft', value: 'huf' }, // Hungarian Forint
    { symbol: '₸', value: 'kzt' },  // Kazakhstani Tenge
    { symbol: '₱', value: 'php' },  // Philippine Peso
    { symbol: 'د.إ', value: 'aed' },// UAE Dirham
    { symbol: 'MAD', value: 'mad' },// Moroccan Dirham
    { symbol: 'EGP', value: 'egp' },// Egyptian Pound
    { symbol: 'CLP$', value: 'clp' },// Chilean Peso
    { symbol: 'MYR', value: 'myr' }, // Malaysian Ringgit
    { symbol: 'IDR', value: 'idr' }, // Indonesian Rupiah
    { symbol: 'TWD$', value: 'twd' },// Taiwan Dollar
    { symbol: 'P', value: 'bwp' },   // Botswanan Pula
    { symbol: 'NGN', value: 'ngn' }, // Nigerian Naira
    { symbol: 'L', value: 'hnl' },   // Honduran Lempira
    { symbol: 'Q', value: 'gtq' },   // Guatemalan Quetzal
    { symbol: 'S/', value: 'pen' },  // Peruvian Sol
    { symbol: '$', value: 'dop' },   // Dominican Peso
    { symbol: '$', value: 'cup' },   // Cuban Peso
    { symbol: 'Bs.', value: 'bob' }, // Bolivian Boliviano
    { symbol: 'CRC', value: 'crc' }, // Costa Rican Colón
    { symbol: 'UYU$', value: 'uyu' },// Uruguayan Peso
    { symbol: 'L', value: 'lsl' },   // Lesotho Loti
    { symbol: 'MUR', value: 'mur' }, // Mauritian Rupee
    { symbol: 'XOF', value: 'xof' }, // CFA Franc (West Africa)
    { symbol: 'XAF', value: 'xaf' }, // CFA Franc (Central Africa)
    { symbol: 'GHS', value: 'ghs' }, // Ghanaian Cedi
  ]

export const languages = [
  { code: 'AR', codelowercase: 'ar', name: 'Arabic', flag: '🇸🇦' },
  { code: 'BN', codelowercase: 'bn', name: 'Bengali', flag: '🇧🇩' },
  { code: 'BG', codelowercase: 'bg', name: 'Bulgarian', flag: '🇧🇬' },
  { code: 'ZH', codelowercase: 'zh', name: 'Chinese', flag: '🇨🇳' },
  { code: 'HR', codelowercase: 'hr', name: 'Croatian', flag: '🇭🇷' },
  { code: 'CS', codelowercase: 'cs', name: 'Czech', flag: '🇨🇿' },
  { code: 'DA', codelowercase: 'da', name: 'Danish', flag: '🇩🇰' },
  { code: 'NL', codelowercase: 'nl', name: 'Dutch', flag: '🇳🇱' },
  { code: 'EN', codelowercase: 'en', name: 'English', flag: '🇬🇧' },
  { code: 'FI', codelowercase: 'fi', name: 'Finnish', flag: '🇫🇮' },
  { code: 'FR', codelowercase: 'fr', name: 'French', flag: '🇫🇷' },
  { code: 'DE', codelowercase: 'de', name: 'German', flag: '🇩🇪' },
  { code: 'EL', codelowercase: 'el', name: 'Greek', flag: '🇬🇷' },
  { code: 'GU', codelowercase: 'gu', name: 'Gujarati', flag: '🇮🇳' },
  { code: 'HE', codelowercase: 'he', name: 'Hebrew', flag: '🇮🇱' },
  { code: 'HI', codelowercase: 'hi', name: 'Hindi', flag: '🇮🇳' },
  { code: 'HU', codelowercase: 'hu', name: 'Hungarian', flag: '🇭🇺' },
  { code: 'IS', codelowercase: 'is', name: 'Icelandic', flag: '🇮🇸' },
  { code: 'ID', codelowercase: 'id', name: 'Indonesian', flag: '🇮🇩' },
  { code: 'GA', codelowercase: 'ga', name: 'Irish', flag: '🇮🇪' },
  { code: 'IT', codelowercase: 'it', name: 'Italian', flag: '🇮🇹' },
  { code: 'JA', codelowercase: 'ja', name: 'Japanese', flag: '🇯🇵' },
  { code: 'JV', codelowercase: 'jv', name: 'Javanese', flag: '🇮🇩' },
  { code: 'KN', codelowercase: 'kn', name: 'Kannada', flag: '🇮🇳' },
  { code: 'KO', codelowercase: 'ko', name: 'Korean', flag: '🇰🇷' },
  { code: 'LV', codelowercase: 'lv', name: 'Latvian', flag: '🇱🇻' },
  { code: 'LT', codelowercase: 'lt', name: 'Lithuanian', flag: '🇱🇹' },
  { code: 'MS', codelowercase: 'ms', name: 'Malay', flag: '🇲🇾' },
  { code: 'ML', codelowercase: 'ml', name: 'Malayalam', flag: '🇮🇳' },
  { code: 'MR', codelowercase: 'mr', name: 'Marathi', flag: '🇮🇳' },
  { code: 'NE', codelowercase: 'ne', name: 'Nepali', flag: '🇳🇵' },
  { code: 'NO', codelowercase: 'no', name: 'Norwegian', flag: '🇳🇴' },
  { code: 'FA', codelowercase: 'fa', name: 'Persian', flag: '🇮🇷' },
  { code: 'PL', codelowercase: 'pl', name: 'Polish', flag: '🇵🇱' },
  { code: 'PT', codelowercase: 'pt', name: 'Portuguese', flag: '🇵🇹' },
  { code: 'PA', codelowercase: 'pa', name: 'Punjabi', flag: '🇮🇳' },
  { code: 'RO', codelowercase: 'ro', name: 'Romanian', flag: '🇷🇴' },
  { code: 'RU', codelowercase: 'ru', name: 'Russian', flag: '🇷🇺' },
  { code: 'SR', codelowercase: 'sr', name: 'Serbian', flag: '🇷🇸' },
  { code: 'SK', codelowercase: 'sk', name: 'Slovak', flag: '🇸🇰' },
  { code: 'SL', codelowercase: 'sl', name: 'Slovenian', flag: '🇸🇮' },
  { code: 'ES', codelowercase: 'es', name: 'Spanish', flag: '🇪🇸' },
  { code: 'SW', codelowercase: 'sw', name: 'Swahili', flag: '🇹🇿' },
  { code: 'SV', codelowercase: 'sv', name: 'Swedish', flag: '🇸🇪' },
  { code: 'TL', codelowercase: 'tl', name: 'Tagalog', flag: '🇵🇭' },
  { code: 'TA', codelowercase: 'ta', name: 'Tamil', flag: '🇮🇳' },
  { code: 'TE', codelowercase: 'te', name: 'Telugu', flag: '🇮🇳' },
  { code: 'TH', codelowercase: 'th', name: 'Thai', flag: '🇹🇭' },
  { code: 'TR', codelowercase: 'tr', name: 'Turkish', flag: '🇹🇷' },
  { code: 'UK', codelowercase: 'uk', name: 'Ukrainian', flag: '🇺🇦' },
  { code: 'UR', codelowercase: 'ur', name: 'Urdu', flag: '🇵🇰' },
  { code: 'VI', codelowercase: 'vi', name: 'Vietnamese', flag: '🇻🇳' },
  { code: 'ZU', codelowercase: 'zu', name: 'Zulu', flag: '🇿🇦' }
  ];


export const countryCodes = [{ code: "+1", name: "United States", flag: "🇺🇸" },
          { code: "+93", name: "Afghanistan", flag: "🇦🇫" },
          { code: "+355", name: "Albania", flag: "🇦🇱" },
          { code: "+213", name: "Algeria", flag: "🇩🇿" },
          { code: "+376", name: "Andorra", flag: "🇦🇩" },
          { code: "+244", name: "Angola", flag: "🇦🇴" },
          { code: "+54", name: "Argentina", flag: "🇦🇷" },
          { code: "+374", name: "Armenia", flag: "🇦🇲" },
          { code: "+61", name: "Australia", flag: "🇦🇺" },
          { code: "+43", name: "Austria", flag: "🇦🇹" },
          { code: "+994", name: "Azerbaijan", flag: "🇦🇿" },
          { code: "+973", name: "Bahrain", flag: "🇧🇭" },
          { code: "+880", name: "Bangladesh", flag: "🇧🇩" },
          { code: "+375", name: "Belarus", flag: "🇧🇾" },
          { code: "+32", name: "Belgium", flag: "🇧🇪" },
          { code: "+501", name: "Belize", flag: "🇧🇿" },
          { code: "+229", name: "Benin", flag: "🇧🇯" },
          { code: "+975", name: "Bhutan", flag: "🇧🇹" },
          { code: "+591", name: "Bolivia", flag: "🇧🇴" },
          { code: "+387", name: "Bosnia and Herzegovina", flag: "🇧🇦" },
          { code: "+267", name: "Botswana", flag: "🇧🇼" },
          { code: "+55", name: "Brazil", flag: "🇧🇷" },
          { code: "+246", name: "British Indian Ocean Territory", flag: "🇮🇴" },
          { code: "+673", name: "Brunei", flag: "🇧🇳" },
          { code: "+359", name: "Bulgaria", flag: "🇧🇬" },
          { code: "+226", name: "Burkina Faso", flag: "🇧🇫" },
          { code: "+257", name: "Burundi", flag: "🇧🇮" },
          { code: "+855", name: "Cambodia", flag: "🇰🇭" },
          { code: "+237", name: "Cameroon", flag: "🇨🇲" },
          { code: "+238", name: "Cape Verde", flag: "🇨🇻" },
          { code: "+236", name: "Central African Republic", flag: "🇨🇫" },
          { code: "+235", name: "Chad", flag: "🇹🇩" },
          { code: "+56", name: "Chile", flag: "🇨🇱" },
          { code: "+86", name: "China", flag: "🇨🇳" },
          { code: "+57", name: "Colombia", flag: "🇨🇴" },
          { code: "+269", name: "Comoros", flag: "🇰🇲" },
          { code: "+242", name: "Congo", flag: "🇨🇬" },
          { code: "+243", name: "Congo, Democratic Republic of the", flag: "🇨🇩" },
          { code: "+506", name: "Costa Rica", flag: "🇨🇷" },
          { code: "+385", name: "Croatia", flag: "🇭🇷" },
          { code: "+53", name: "Cuba", flag: "🇨🇺" },
          { code: "+357", name: "Cyprus", flag: "🇨🇾" },
          { code: "+420", name: "Czech Republic", flag: "🇨🇿" },
          { code: "+45", name: "Denmark", flag: "🇩🇰" },
          { code: "+253", name: "Djibouti", flag: "🇩🇯" },
          { code: "+593", name: "Ecuador", flag: "🇪🇨" },
          { code: "+20", name: "Egypt", flag: "🇪🇬" },
          { code: "+503", name: "El Salvador", flag: "🇸🇻" },
          { code: "+240", name: "Equatorial Guinea", flag: "🇬🇶" },
          { code: "+291", name: "Eritrea", flag: "🇪🇷" },
          { code: "+372", name: "Estonia", flag: "🇪🇪" },
          { code: "+251", name: "Ethiopia", flag: "🇪🇹" },
          { code: "+500", name: "Falkland Islands", flag: "🇫🇰" },
          { code: "+298", name: "Faroe Islands", flag: "🇫🇴" },
          { code: "+679", name: "Fiji", flag: "🇫🇯" },
          { code: "+358", name: "Finland", flag: "🇫🇮" },
          { code: "+33", name: "France", flag: "🇫🇷" },
          { code: "+594", name: "French Guiana", flag: "🇬🇫" },
          { code: "+689", name: "French Polynesia", flag: "🇵🇫" },
          { code: "+241", name: "Gabon", flag: "🇬🇦" },
          { code: "+220", name: "Gambia", flag: "🇬🇲" },
          { code: "+995", name: "Georgia", flag: "🇬🇪" },
          { code: "+49", name: "Germany", flag: "🇩🇪" },
          { code: "+233", name: "Ghana", flag: "🇬🇭" },
          { code: "+350", name: "Gibraltar", flag: "🇬🇮" },
          { code: "+30", name: "Greece", flag: "🇬🇷" },
          { code: "+299", name: "Greenland", flag: "🇬🇱" },
          { code: "+502", name: "Guatemala", flag: "🇬🇹" },
          { code: "+224", name: "Guinea", flag: "🇬🇳" },
          { code: "+245", name: "Guinea-Bissau", flag: "🇬🇼" },
          { code: "+592", name: "Guyana", flag: "🇬🇾" },
          { code: "+509", name: "Haiti", flag: "🇭🇹" },
          { code: "+504", name: "Honduras", flag: "🇭🇳" },
          { code: "+852", name: "Hong Kong", flag: "🇭🇰" },
          { code: "+36", name: "Hungary", flag: "🇭🇺" },
          { code: "+354", name: "Iceland", flag: "🇮🇸" },
          { code: "+91", name: "India", flag: "🇮🇳" },
          { code: "+62", name: "Indonesia", flag: "🇮🇩" },
          { code: "+98", name: "Iran", flag: "🇮🇷" },
          { code: "+964", name: "Iraq", flag: "🇮🇶" },
          { code: "+353", name: "Ireland", flag: "🇮🇪" },
          { code: "+972", name: "Israel", flag: "🇮🇱" },
          { code: "+39", name: "Italy", flag: "🇮🇹" },
          { code: "+225", name: "Ivory Coast", flag: "🇨🇮" },
          { code: "+81", name: "Japan", flag: "🇯🇵" },
          { code: "+962", name: "Jordan", flag: "🇯🇴" },
          { code: "+254", name: "Kenya", flag: "🇰🇪" },
          { code: "+686", name: "Kiribati", flag: "🇰🇮" },
          { code: "+965", name: "Kuwait", flag: "🇰🇼" },
          { code: "+996", name: "Kyrgyzstan", flag: "🇰🇬" },
          { code: "+856", name: "Laos", flag: "🇱🇦" },
          { code: "+371", name: "Latvia", flag: "🇱🇻" },
          { code: "+961", name: "Lebanon", flag: "🇱🇧" },
          { code: "+266", name: "Lesotho", flag: "🇱🇸" },
          { code: "+231", name: "Liberia", flag: "🇱🇷" },
          { code: "+218", name: "Libya", flag: "🇱🇾" },
          { code: "+423", name: "Liechtenstein", flag: "🇱🇮" },
          { code: "+370", name: "Lithuania", flag: "🇱🇹" },
          { code: "+352", name: "Luxembourg", flag: "🇱🇺" },
          { code: "+853", name: "Macau", flag: "🇲🇴" },
          { code: "+389", name: "Macedonia", flag: "🇲🇰" },
          { code: "+261", name: "Madagascar", flag: "🇲🇬" },
          { code: "+265", name: "Malawi", flag: "🇲🇼" },
          { code: "+60", name: "Malaysia", flag: "🇲🇾" },
          { code: "+960", name: "Maldives", flag: "🇲🇻" },
          { code: "+223", name: "Mali", flag: "🇲🇱" },
          { code: "+356", name: "Malta", flag: "🇲🇹" },
          { code: "+692", name: "Marshall Islands", flag: "🇲🇭" },
          { code: "+596", name: "Martinique", flag: "🇲🇶" },
          { code: "+222", name: "Mauritania", flag: "🇲🇷" },
          { code: "+230", name: "Mauritius", flag: "🇲🇺" },
          { code: "+52", name: "Mexico", flag: "🇲🇽" },
          { code: "+691", name: "Micronesia", flag: "🇫🇲" },
          { code: "+373", name: "Moldova", flag: "🇲🇩" },
          { code: "+377", name: "Monaco", flag: "🇲🇨" },
          { code: "+976", name: "Mongolia", flag: "🇲🇳" },
          { code: "+382", name: "Montenegro", flag: "🇲🇪" },
          { code: "+212", name: "Morocco", flag: "🇲🇦" },
          { code: "+258", name: "Mozambique", flag: "🇲🇿" },
          { code: "+95", name: "Myanmar", flag: "🇲🇲" },
          { code: "+264", name: "Namibia", flag: "🇳🇦" },
          { code: "+674", name: "Nauru", flag: "🇳🇷" },
          { code: "+977", name: "Nepal", flag: "🇳🇵" },
          { code: "+31", name: "Netherlands", flag: "🇳🇱" },
          { code: "+687", name: "New Caledonia", flag: "🇳🇨" },
          { code: "+64", name: "New Zealand", flag: "🇳🇿" },
          { code: "+505", name: "Nicaragua", flag: "🇳🇮" },
          { code: "+227", name: "Niger", flag: "🇳🇪" },
          { code: "+234", name: "Nigeria", flag: "🇳🇬" },
          { code: "+683", name: "Niue", flag: "🇳🇺" },
          { code: "+672", name: "Norfolk Island", flag: "🇳🇫" },
          { code: "+850", name: "North Korea", flag: "🇰🇵" },
          { code: "+47", name: "Norway", flag: "🇳🇴" },
          { code: "+968", name: "Oman", flag: "🇴🇲" },
          { code: "+92", name: "Pakistan", flag: "🇵🇰" },
          { code: "+680", name: "Palau", flag: "🇵🇼" },
          { code: "+970", name: "Palestine", flag: "🇵🇸" },
          { code: "+507", name: "Panama", flag: "🇵🇦" },
          { code: "+675", name: "Papua New Guinea", flag: "🇵🇬" },
          { code: "+595", name: "Paraguay", flag: "🇵🇾" },
          { code: "+51", name: "Peru", flag: "🇵🇪" },
          { code: "+63", name: "Philippines", flag: "🇵🇭" },
          { code: "+48", name: "Poland", flag: "🇵🇱" },
          { code: "+351", name: "Portugal", flag: "🇵🇹" },
          { code: "+974", name: "Qatar", flag: "🇶🇦" },
          { code: "+262", name: "Reunion", flag: "🇷🇪" },
          { code: "+40", name: "Romania", flag: "🇷🇴" },
          { code: "+7", name: "Russia", flag: "🇷🇺" },
          { code: "+250", name: "Rwanda", flag: "🇷🇼" },
          { code: "+590", name: "Saint Barthelemy", flag: "🇧🇱" },
          { code: "+290", name: "Saint Helena", flag: "🇸🇭" },
          { code: "+508", name: "Saint Pierre and Miquelon", flag: "🇵🇲" },
          { code: "+685", name: "Samoa", flag: "🇼🇸" },
          { code: "+378", name: "San Marino", flag: "🇸🇲" },
          { code: "+239", name: "Sao Tome and Principe", flag: "🇸🇹" },
          { code: "+966", name: "Saudi Arabia", flag: "🇸🇦" },
          { code: "+221", name: "Senegal", flag: "🇸🇳" },
          { code: "+381", name: "Serbia", flag: "🇷🇸" },
          { code: "+248", name: "Seychelles", flag: "🇸🇨" },
          { code: "+232", name: "Sierra Leone", flag: "🇸🇱" },
          { code: "+65", name: "Singapore", flag: "🇸🇬" },
          { code: "+421", name: "Slovakia", flag: "🇸🇰" },
          { code: "+386", name: "Slovenia", flag: "🇸🇮" },
          { code: "+677", name: "Solomon Islands", flag: "🇸🇧" },
          { code: "+252", name: "Somalia", flag: "🇸🇴" },
          { code: "+27", name: "South Africa", flag: "🇿🇦" },
          { code: "+82", name: "South Korea", flag: "🇰🇷" },
          { code: "+211", name: "South Sudan", flag: "🇸🇸" },
          { code: "+34", name: "Spain", flag: "🇪🇸" },
          { code: "+94", name: "Sri Lanka", flag: "🇱🇰" },
          { code: "+249", name: "Sudan", flag: "🇸🇩" },
          { code: "+597", name: "Suriname", flag: "🇸🇷" },
          { code: "+268", name: "Swaziland", flag: "🇸🇿" },
          { code: "+46", name: "Sweden", flag: "🇸🇪" },
          { code: "+41", name: "Switzerland", flag: "🇨🇭" },
          { code: "+963", name: "Syria", flag: "🇸🇾" },
          { code: "+886", name: "Taiwan", flag: "🇹🇼" },
          { code: "+992", name: "Tajikistan", flag: "🇹🇯" },
          { code: "+255", name: "Tanzania", flag: "🇹🇿" },
          { code: "+66", name: "Thailand", flag: "🇹🇭" },
          { code: "+228", name: "Togo", flag: "🇹🇬" },
          { code: "+690", name: "Tokelau", flag: "🇹🇰" },
          { code: "+676", name: "Tonga", flag: "🇹🇴" },
          { code: "+216", name: "Tunisia", flag: "🇹🇳" },
          { code: "+90", name: "Turkey", flag: "🇹🇷" },
          { code: "+993", name: "Turkmenistan", flag: "🇹🇲" },
          { code: "+688", name: "Tuvalu", flag: "🇹🇻" },
          { code: "+256", name: "Uganda", flag: "🇺🇬" },
          { code: "+380", name: "Ukraine", flag: "🇺🇦" },
          { code: "+971", name: "United Arab Emirates", flag: "🇦🇪" },
          { code: "+44", name: "United Kingdom", flag: "🇬🇧" },
          { code: "+598", name: "Uruguay", flag: "🇺🇾" },
          { code: "+998", name: "Uzbekistan", flag: "🇺🇿" },
          { code: "+678", name: "Vanuatu", flag: "🇻🇺" },
          { code: "+379", name: "Vatican City", flag: "🇻🇦" },
          { code: "+58", name: "Venezuela", flag: "🇻🇪" },
          { code: "+84", name: "Vietnam", flag: "🇻🇳" },
          { code: "+681", name: "Wallis and Futuna", flag: "🇼🇫" },
          { code: "+967", name: "Yemen", flag: "🇾🇪" },
          { code: "+260", name: "Zambia", flag: "🇿🇲" },
          { code: "+263", name: "Zimbabwe", flag: "🇿🇼" }];


//if ('serviceWorker' in navigator) {
//  navigator.serviceWorker.register('/firebase-messaging-sw.js')
//    .then(function(registration) {
//      console.log('Service Worker registrado con éxito en scope:', registration.scope);
//    })
//   .catch(function(err) {
//      console.error('Error al registrar el Service Worker:', err);
//    });
//} else {
//  console.error('Este navegador no soporta Service Workers.');
//}


// global library setup
Vue.prototype.$Chartist = Chartist;
Vue.prototype.$languages = languages;
Vue.prototype.$currencies = currencies;
Vue.prototype.$countryCodes = countryCodes;
Vue.prototype.$getLanguageName = function(code) {
    const language = languages.find(lang => lang.code === code || lang.codelowercase === code.toLowerCase());
    return language ? language.name : null;
}

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  watch: {
    '$route.meta.title' (newName) {
      if(newName !== undefined){
        document.title = 'GO take it | ' + newName;
      }
    }
  },
  router,
  i18n, // Asegúrate de incluir i18n en la instancia de Vue
});

// Exporta i18n para que esté disponible en otras partes de tu aplicación
export { i18n,router };


