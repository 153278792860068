<template>
  <div :id="carouselId" class="carousel slide">
    <div class="carousel-inner">
      <div
        class="carousel-item"
        :class="{ active: index === 0 }"
        v-for="(item, index) in items"
        :key="index"
      >
        <!-- Aquí se renderiza el contenido dinámico -->
        <slot :item="item"></slot>
      </div>
    </div>
    <button v-if="items.length > 1" class="carousel-control-prev" type="button" :data-bs-target="'#' + carouselId" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button   v-if="items.length > 1"  class="carousel-control-next" type="button" :data-bs-target="'#' + carouselId" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
  export default {
    name: "CarouselItem",
    props: {
      id: {
        type: String,
        required: true
      },
      items: { // Cambiamos 'images' por 'items' para mayor flexibilidad
        type: Array,
        required: true
      }
    },
    computed: {
      carouselId() {
        return this.id;
      }
    }
  };
</script>

<style>
  /* Puedes agregar estilos personalizados si los necesitas */
  .carousel-caption {
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente para mejor legibilidad */
    padding: 10px;
    border-radius: 10px;
  }
</style>
