<template>
  <div>
    <md-button class="w-100 md-button md-primary" @click="open=true" v-if="!open">
      + Agregar Rutina
    </md-button>
    <md-table 

      v-if="!open"
      v-model="trainings"
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      class="paginated-table table-striped table-hover"
    >
      <md-table-row 
        slot="md-table-row"  
        slot-scope="{ item }"
      >
        <!-- Columna Nombre del Entrenamiento -->
        <md-table-cell :md-label="$t('name')" md-sort-by="name">
          <span>{{ item.name }}</span>
        </md-table-cell>

        <!-- Columna Elemento Usado -->
        <md-table-cell :md-label="$t('exercises')">
          <span v-for="element in item.exercises" class="p-2">{{ element.name }}</span>
        </md-table-cell>

        <!-- Columna Objetivo -->
        <md-table-cell :md-label="$t('goal')">
          <span>{{ item.goal }}</span>
        </md-table-cell>

        <!-- Columna Duración -->
        <md-table-cell :md-label="$t('duration')">
          <span>{{ item.duration }} minutes</span>
        </md-table-cell>

        <!-- Columna Dificultad -->
        <md-table-cell :md-label="$t('difficulty')">
          <span>{{ item.difficulty }}</span>
        </md-table-cell>

        <!-- Columna de Acciones -->
        <md-table-cell>
          <md-button class="md-primary" @click="editTraining(item);open=true">
            Edit
          </md-button>
          <md-button class="md-danger" @click="confirmDelete(item)">
            Delete
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <!-- Cargar formulario de edición solo si hay un entrenamiento seleccionado -->
    <CreateTraining
      v-if="selectedTraining && open"
      :key="selectedTraining._id"
      :preloadedData="selectedTraining"
      @formSubmitted="onTrainingUpdated"
        @cancel="cancel()"
    />
  </div>
</template>

<script>
import { getAllTrainingsAction, deleteTrainingAction, getAllTrainingsOfPlanningAction } from '@/pages/Training/actions';
import CreateTraining from '@/pages/Training'; // El formulario de edición
import Swal from 'sweetalert2';

export default {
  data() {
    return {  
    open:false,
      trainings: [],
      currentSort: 'name',
      currentSortOrder: 'asc',
      selectedTraining: null // Entrenamiento seleccionado para editar
    };
  },
  props: {
    planningId:String
  },
  components: {
    CreateTraining
  },
  async created() {
    this.loadTrainings();
  },
  methods: {
    cancel(){
      this.open=false;
    },
    async loadTrainings() {
      try {
       let response;
        if(!this.planningId){
           response = await getAllTrainingsAction();
        }else{
           response = await getAllTrainingsOfPlanningAction(this.planningId); 
        }
        if (response.ok) {
          this.trainings = response.trainings;
        } else {
          console.error('Failed to fetch trainings: ', response.message);
        }
      } catch (error) {
        console.error('Error fetching trainings: ', error);
      }
    },
    editTraining(training) {
      this.selectedTraining = training;
    },
    async confirmDelete(training) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete the training "${training.name}". This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel'
      });

      if (result.isConfirmed) {
        this.deleteTraining(training._id);
      }
    },
    async deleteTraining(id) {
      try {
        const response = await deleteTrainingAction(id);
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Training deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });
          // Recargar la lista de entrenamientos
          this.loadTrainings();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete training: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error deleting training:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    onTrainingUpdated() {
      // Actualiza la lista de entrenamientos después de la edición
      this.selectedTraining = null;
      this.loadTrainings(); // Recargar la lista
    }
  }
};

</script>
