<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100" v-if="routename != 'Oauth'">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
            <h4 class="title">{{ $t('connectStripeAccount') }}</h4>
        </md-card-header>

        <md-card-content>
            <div class="stripe-container">
              <div>
                <p class="p-2 md-text text-secondary"><md-icon>chat</md-icon> {{ $t('syncStripeInfo') }}</p>
                <small v-if="!isSync()">{{ $t('noStripeAccount') }}</small>
              </div>
              <p class="text-primary mt-3"  v-if="!isSync()">{{ $t('alreadyHaveStripeAccount') }}</p>
              <div class="md-layout"  v-if="!isSync()">
                <div class="md-layout-item md-size-50">
                  <md-button class="md-raised md-primary" @click="handleStripeAction" :disabled="loading">
                    {{ $t('syncMyStripeAccount') }}
                    <div class="loading-container mr-2" v-if="loading">
                      <md-progress-spinner 
                        :md-diameter="20" 
                        md-mode="indeterminate" 
                        class="md-secondary mr-2">
                      </md-progress-spinner>
                    </div>
                  </md-button>
                </div>
              </div>
              <div class="md-layout"  v-if="isSync()">
                <p class="text-primary"> {{ $t('stripeAccountSync')}}</p>
                <div class="w-100">
                  <md-button class="md-raised md-danger" @click="unsync()" :disabled="loading">
                    {{ $t('Unsync')}}
                    <div class="loading-container mr-2" v-if="loading">
                      <md-progress-spinner 
                        :md-diameter="20" 
                        md-mode="indeterminate" 
                        class="md-secondary mr-2">
                      </md-progress-spinner>
                    </div>
                  </md-button>
                </div> 
              </div> 
            </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import { connectStripeAccount, createStripeAccount,syncStripeAccount,removeSync } from "@/pages/Stripe/actions";
import Swal from 'sweetalert2';
import permissionsMixin from '@/mixins/permissionsMixin';


export default {
  mixins: [permissionsMixin],

  name: "Stripe",
  data() {
    return {
      loading: false,
      stripeAccountId: false,
      routename: false,
    };
  },
  created() {
    this.loadinit();
  },
  methods: {
    isSync(){
        return this.stripeAccountId != false && this.stripeAccountId != null && this.stripeAccountId != 'false'
    },
    loadinit(){
      this.routename = this.$route.name;
      if( this.$route.name !== "Oauth"){
        this.stripeAccountId = localStorage.getItem('stripeAccountId');
      }
      this.checkStripe();
    },
    async unsync() {
      if(this.isDemo()){
        return;
      }
      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('ifyouunsync'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yesDeleteIt')
      }).then((result) => {
        if (result.isConfirmed) {
          removeSync();
          Swal.fire(
            this.$t('deleted'),
            '',
            'success'
          ).then((result) => {

            window.location.reload();

          })
        }
      })
    },
    async checkStripe(){
          const code = this.$route.query.code;
          if(code){
            const { ok,stripeAccountId } = await syncStripeAccount(code);
            if(stripeAccountId){
                Swal.fire(
                  this.$t('stripeAccountSync'),
                  this.$t('nowYouCanAccess'),
                  'success'
                );
                this.stripeAccountId = stripeAccountId;
                this.stripeAccountId = localStorage.setItem('stripeAccountId',stripeAccountId);
                this.$router.push(`/stripe`);
                 window.location.reload();
            }
          }
    },
    async handleStripeAction() {
      this.loading = true;
      try {
        
          // Sincronizar cuenta existente
          const { ok, url } = await connectStripeAccount();
          if (ok) {
            window.location.href = url; // Redirige al usuario a la URL de Stripe para conectar su cuenta
          } else {
            Swal.fire(this.$t('error'), this.$t('stripeConnectError'), 'error');
            this.loading = false;
          }
      
       
      } catch (error) {
        console.error(error);
        Swal.fire($t('error'), $t('genericError'), 'error');
      } 
    },
  },
};
</script>
