<template>
  <div class="md-layout-item md-size-100 my-2">

    
          <div class="loading-container mr-2 w-100 text-center p-4" v-if="isLoading">
            <md-progress-spinner 
              :md-diameter="80" 
              md-mode="indeterminate" 
              class="text-red bg-red md-red">
            </md-progress-spinner>
          </div>
          <div class="w-100 text-center customborder" v-if="activitiesWithDate.length == 0 && !isLoading">
             <h5 class="text-secondary p-4">{{$t('nothingtosee')}}</h5>
          </div>
    <!-- Listado de actividades con startDate no nulo -->
    <div v-if="activitiesWithDate.length">
      <div v-for="act in activitiesWithDate" class="card p-4" :class="isPastDay(act.startDate) ? 'bg-grey':''"> 
        <div class="d-inline">           
          <span class="text-secondary" v-if="act.startDate">{{ labelDate(act.startDate) }} {{act.startTime}}hs.</span>
          <Activity  :myclasses="true"  :activity="act" 
          :edit="false" />
          <md-button class="md-btn md-danger md-sm" 
            v-if="!isPastDay(act.startDate) && isClientGym()" 
            @click="deleteClass(act._id)">{{$t('unsubscribe')}}</md-button>
        </div>
      </div>
    </div>

    <!-- Listado de actividades con startDate nulo -->
    <div v-if="activitiesWithoutDate.length">
      <div v-for="act in activitiesWithoutDate" class="card p-4">
        <div class="d-inline">
          <Activity :myclasses="true" :activity="act" :edit="false" />
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import permissionsMixin from '@/mixins/permissionsMixin';
import { deleteClassAction, getMyClassesAction } from "@/pages/Stablishments/actions";
import Swal from 'sweetalert2';
import { format, isBefore, isToday } from 'date-fns';
import { Activity } from "@/components";

export default {
  name: "myclasses",
  mixins: [permissionsMixin],
  components: {
    Activity
  },
  data() {
    return { 
      isLoading: false,
      activitiesWithDate: [], // Actividades con startDate no nulo
      activitiesWithoutDate: [], // Actividades con startDate nulo
      daysOfWeek: ['Domingo','Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
    };
  },
  props: { 
    all: Boolean,
    owner: Boolean
  },
  created(){
    this.getMyClasses();
  },
  methods: {
    isPastDay(day) {
      const today = new Date();
      return isBefore(day, today) && !isToday(day);
    },
    async deleteClass(id){
      await deleteClassAction(id,false);
      this.$emit("deleted");
    },
    labelDate(startDate){
      return format(startDate, 'd') + ' ' + this.$t(format(startDate, 'MMMM')) + ' ' + format(startDate, 'yyyy');
    },
    async getMyClasses(){
    this.isLoading = true;
      const response = await getMyClassesAction(this.all,this.owner);

      // Filtrar actividades según si tienen o no startDate
      this.activitiesWithDate = response.data.filter(act => act.startDate);
      this.activitiesWithoutDate = response.data.filter(act => !act.startDate);

    this.isLoading = false;
    }
  },
};
</script>

<style>
.bg-grey{ 
  background:#c8c8c8;
}
</style>
