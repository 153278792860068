import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";
import axios from "axios"; // Asegúrate de importar axios si lo necesitas

export const loginAction = async (email, password) => {

  try {
    const { data } = await wencesApi.post("/api/users/login", {
      email,
      password,
    });

    const subdomain = window.location.hostname.split('.')[0];
    if(data.restaurant !== subdomain){
      window.location.href = 'https://'+data.restaurant+'.app.gotake.it/login';
      return {
        ok: true
      };
    }

    // Guardar la información en el localStorage
    localStorage.setItem('token', data.token);
    localStorage.setItem('userId', data.userId);
    localStorage.setItem('role', data.role);
    localStorage.setItem('name', data.name);
    localStorage.setItem('restaurant', data.restaurant);
    localStorage.setItem('country', data.country);
    if(data.language){
      localStorage.setItem('language', data.language); 
    }
    if(data.role == 'client'){
      localStorage.setItem('customerName',data.name);
      localStorage.setItem('customerAvatar',data.avatar);
      localStorage.setItem('identifier',data.identifier);
      localStorage.setItem('remainingCupos',data.remainingCupos);
    }

    if(data.stripeAccountId){
      localStorage.setItem('stripeAccountId', data.stripeAccountId);
    }

    if(!data.country){
      try {
        // Hacemos una solicitud a ip-api.com para obtener la información de la IP
       // const response = await axios.get('http://ip-api.com/json/');
       // if (response.data.status === 'success') {
         // const country = response.data.country; // Obtenemos el país del usuario
         // localStorage.setItem('country', country);
         // editUser({country})
       // }
      } catch (error) {

        throw new Error("No se pudo realizar la petición");
      }
    }
    
    return {
      ok: true,
      role: data.role,
      token: data.token,
      userId: data.userId,
    };
  } catch (error) {
    if (isAxiosError(error) && error.response.status === 401) {
      return {
        ok: false,
        message: this.$t('incorrectUserOrPassword'),
      };
    }
    if (isAxiosError(error) && error.response.status === 404) {
      return {
        ok: false,
        message: this.$t('incorrectUserOrPassword'),
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

// Función de logout
export const logoutAction = async () => {
  try {

    // Limpiar el localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    localStorage.removeItem('restaurant');
    localStorage.removeItem('stripeAccountId');
    
     localStorage.removeItem('customerName');
     localStorage.removeItem('customerAvatar');
     localStorage.removeItem('customerPhone');
     localStorage.removeItem('customerEmail');
     localStorage.removeItem('identifier');
     localStorage.removeItem('country');
     localStorage.removeItem('language');
     localStorage.removeItem('remainingCupos');



    return {
      ok: true,
    };
  } catch (error) {
    console.log(error);
    throw new Error("No se pudo realizar el logout");
  }
};


export const changePassword = async (password,token) => {
  try {
    const { data } = await wencesApi.post("/api/users/newpassword", {password,token});

    return {
      ok: true
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo cambiar la contraseña",
    };
  }
};


export const editUser = async (user) => {
  try {
    const { data } = await wencesApi.post("/api/users/edit", {user});

    return {
      ok: true
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo cambiar la contraseña",
    };
  }
};


// Función de logout envia el email con el link
export const sendPassword = async (email) => {
  try {
    const { data } = await wencesApi.post("/api/users/forgot-password", {email});

    return {
      ok: true,
    };
  } catch (error) {
    throw new Error("No se pudo realizar el recover password");
  }
};


