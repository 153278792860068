import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";


export const payAction = async (email) => {
  try {
    const { data } = await wencesApi.post(`/api/mp/create-order`,{ email });

    return {
      ok: true,
      data: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const payClientAction = async (email,orderId) => {
  try {
    const { data } = await wencesApi.post(`/api/mp/pay-client-order`,{ email, orderId });

    return {
      ok: true,
      data: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const oauthAction = async (code) => {
  try {
    const { data } = await wencesApi.post(`/api/mp/oauth`,{ code });

    return {
      ok: true,
      data: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const onePayAction = async () => {
  try {
    const { data } = await wencesApi.post(`/api/mp/pay`);

    return {
      ok: true,
      data: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getPriceAction = async (currency) => {
  try {
    const { data } = await wencesApi.post(`/api/mp/get-price-sub`,{currency});

    return {
      ok: true,
      data: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const unSyncAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/mp/unsync`);

    return {
      ok: true,
      data: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const confirmPaymentOrderAction = async (params) => {
  try {
    const { data } = await wencesApi.post(`/api/mp/confirm-payment-order`,params);

    return {
      ok: true,
      data: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const hasMercadoPagoAction = async (currency) => {
  try {
    const { data } = await wencesApi.post(`/api/mp/sync`,{currency});

    return {
      ok: true,
      data: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const removeMercadoPagoAction = async (currency) => {
  try {
    const { data } = await wencesApi.post(`/api/mp/unsync`,{currency});

    return {
      ok: true,
      data: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};