<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>campaign</md-icon>
          </div>
          <h5 class="title">Difundir un mensaje</h5>
          <small>Cada cliente recibirá un email y una alerta en las notificaciones cuando se conecte.</small>
        </md-card-header>

        <md-card-content>
          <ValidationProvider
            v-if="false"
            name="email"
            rules="required|email"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>Email Adress</label>
              <md-input v-model="email" type="email"> </md-input>

              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>

            <md-field>
              <label>Para</label>
              <md-input v-model="to" disabled=true type="text"> </md-input>
            </md-field>

          <ValidationProvider
            name="subject"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>Subject</label>
              <md-input v-model="subject" type="text"> </md-input>

              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>

          <ValidationProvider
            name="body"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>Body</label>
              <md-textarea  class="text-black" v-model="body" type="textarea"> </md-textarea>

              <slide-y-down-transition>
                <md-icon class="error" v-show="failed">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="passed">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </ValidationProvider>

        </md-card-content>

        <md-card-actions>
          <md-button type="submit" class="md-success">{{$t('sendAlert')}} </md-button>
        </md-card-actions>
      </md-card>
    </form>
  </ValidationObserver>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    SlideYDownTransition,
  },
  data() {
    return {
      to: "",
      subject: "",
      body: "",
    };
  },
  methods: {
    submit() {
      alert("Form has been submitted!");
    },
  },
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}
</style>
