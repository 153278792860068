import axios from "axios";
import { router } from '../main'; // Asegúrate de que la ruta a tu main.js es correcta
import Swal from "sweetalert2";
import i18n from '../i18n'; // Ajusta la ruta según tu configuración


const wencesApi = axios.create({
  baseURL: process.env.VUE_APP_WENCES_API_URL+"/"
});


wencesApi.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  const subdomain = window.location.hostname.split('.')[0];
  config.headers['X-Subdomain'] = subdomain;
  const savedLanguage = localStorage.getItem('language') || 'en';
  config.headers['savedLanguage'] = savedLanguage;

  return config;
});

// Interceptor de respuesta
/*wencesApi.interceptors.response.use(
  response => response,
  error => {
    const subdomain = window.location.hostname.split('.')[0];
    if (error.response && error.response.status === 500) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: i18n.t('temporaryIssue')
      }).then((result) => {
        if (result.isConfirmed) {
          router.push('/login');
        }
      });
    }
    return Promise.reject(error);
  }
);*/

export { wencesApi };
