<template> 
  <div class="md-layout">
    <div class="mb-4 md-layout-item md-size-100 md-small-size-100 w-100">
      <md-button v-if="!showNewQrForm" class="md-primary md-sm" @click="addUser()">
        + {{ $t('addUser') }}
      </md-button>
    
      <md-button v-if="false" class="md-primary md-sm" @click="toggleSelectAll" >{{ selectAll ? $t('deselectAll') : $t('selectAll') }}</md-button>
    </div>

    <!-- Formulario para añadir nuevo QR -->

    <div class="md-layout">
    <div v-if="showNewQrForm" class="md-layout-item md-size-50 md-small-size-100 mt-4 p-2">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>group</md-icon>
          </div>
          <h4 class="title">{{ $t('users') }}</h4>
          <p class="p-2 md-text text-secondary" v-if="isRestaurant()"><md-icon>chat</md-icon> "{{$t('userRolesDescription')}}"</p>

        </md-card-header>
        </md-card-content>
      </md-card>
    </div>


    <div v-if="showNewQrForm" class="md-layout-item md-size-50 md-small-size-100 mt-4 p-2">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>add_circle</md-icon>
          </div>
          <h4 class="title"  v-if="useredit.email">{{ $t('edit') }}</h4> 
          <h4 class="title" v-else>{{ $t('addUser') }}</h4>
        </md-card-header>
        <md-card-content>
          <NewUserForm 
          :user="useredit" 
          :required="['name', 'email', 'role']"
          :roles="['employee','restaurant']"
          :hide="['paymentType']" 
          type="restaurant"
          @userCreated="userCreated" 
          v-if="isRestaurant() && showNewQrForm"></NewUserForm>


      <md-button v-if="showNewQrForm" class="md-danger md-sm w-100" @click="showNewQrForm = !showNewQrForm">
        {{ $t('cancel') }}
      </md-button>
        </md-card-content>
      </md-card>
    </div>
    </div>

    <div class="md-layout-item md-size-100 mt-4 p-2" v-if="!showNewQrForm">
      <md-card>
        <md-card-content>
          <md-table v-model="filteredAndSortedUsers" class="table-full-width">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell class="text-center">     
                  <h5 class="mb-2">{{ item.name }}</h5> <small>({{ $t(item.role+'_role') }})</small>
                    <avatar :logo="item.avatar"></avatar>

                    
                <div   class="d-sm-none d-block mt-2">
                    <md-button class="md-button md-sm md-primary w-100 mb-2" @click="editUser(item)">
                      {{$t('edit')}}
                    </md-button>
                </div>
                <div   class="d-sm-none d-block">
                    <md-button class="md-button md-sm md-danger w-100 mb-2" @click="confirmDelete(item._id)">
                      {{$t('delete')}}
                    </md-button>
                </div>
                    </md-table-cell>
                    <md-table-cell class="text-primary">
                      <div  v-if="isGym() && item.role == 'client'" >
                       
                      <div  v-if="item.paymentType == 'month'" class="mb-1" >
                        <small class="badge badge-primary">
                          <md-icon class="text-white">money</md-icon>
                          {{$t("monthlyPayment")}} 
                        </small>
                      </div>
                      <div>
                        <small  class="badge badge-primary">
                          <md-icon class="text-white">key</md-icon>
                        {{ item.identifier }}
                        </small>
                      </div>
                      <div>
                        <small  class="badge badge-primary mt-1">
                          <md-icon class="text-white">email</md-icon>
                        {{ item.email }}
                        </small>
                      </div>
                      <div v-if="item.phone">
                        <small  class="badge badge-success mt-1">
                          <md-icon class="text-white">phone</md-icon>
                        {{ item.phone }}
                        </small>
                      </div>
                      <div v-if="item.access < 0 || item.access == null" class=" mt-1">
                        <small   class="badge badge-primary text-white">
                          <md-icon class="text-white">meeting_room</md-icon>
                          <md-icon class="text-white">all_inclusive</md-icon>
                        </small>
                      </div>
                      <div   v-if="item.cupos < 0" class=" mt-1">
                        <small class="badge badge-primary text-white">
                          <md-icon class="text-white">self_improvement</md-icon>
                          <md-icon class="text-white">all_inclusive</md-icon>
                        </small>
                      </div>
                      <div  v-if="item.cupos >= 0  || item.cupos == null"  class=" mt-1">
                      <small  class="badge badge-primary text-white">
                        <md-icon class="text-white">self_improvement</md-icon>
                        <span v-if="!item.cupos">0</span> 
                        <span v-else>{{ item.cupos }}</span>
                      </small>
                      </div>
                      <div  v-if="item.access >= 0" >
                      <small   class="badge badge-primary text-white">
                        <md-icon class="text-white">meeting_room</md-icon>
                        <span v-if="!item.access">0</span> 
                        <span v-else>{{ item.access }}</span>
                      </small>
                      </div>
                    </div>

                  <div v-if="item.role == 'client'" class="mt-2 card float-left p-2 d-sm-none d-block" >
                      <div class="mb-2">
                        <span class=" text-danger mb-2" v-if="!isPaymentDateValid(item)" >
                          <md-icon class="text-danger">no_meeting_room</md-icon> {{$t('paymentPending')}}
                        </span>      
                        <span class=" text-success mb-2" v-else >
                          <md-icon class="text-success">meeting_room</md-icon> {{$t('yespaid')}}
                        </span>
                      </div>  
                      <div class="mb-2">   
                        <span v-if="item.paymentDate && item.paymentType == 'month'">
                          <strong>{{ $t('paymentDate') }}:</strong> <br>{{ localDate(item.paymentDate) }}
                        </span> 
                      </div>
                      <div v-if="item.role == 'client'">
                        <button class="btn btn-success" @click="paySelected(item)" v-if="isMoreThanOneMonthAway(item.paymentDate) && item.paymentType == 'month'">
                          <md-icon class="text-white">money</md-icon> {{ $t('markAsPaid') }}
                        </button>
                      </div>
                  </div>

              </md-table-cell>
              <md-table-cell v-if="isGym()" >
                <div v-if="item.role == 'client'" class="mt-2 card float-left p-4 d-none d-sm-block" >
                    <div class="mb-2">
                        <span class=" text-danger mb-2" v-if="!isPaymentDateValid(item)" >
                          <md-icon class="text-danger">no_meeting_room</md-icon> {{$t('paymentPending')}}
                        </span>      
                        <span class=" text-success mb-2" v-else >
                          <md-icon class="text-success">meeting_room</md-icon> {{$t('yespaid')}}
                        </span>
                      </div>  
                      <div class="mb-2">   
                        <span v-if="item.paymentDate && item.paymentType == 'month'">
                          <strong>{{ $t('paymentDate') }}:</strong> <br>{{ localDate(item.paymentDate) }}
                        </span> 
                      </div>
                      <div v-if="item.role == 'client'">
                        <button class="btn btn-success" @click="paySelected(item)" v-if="isMoreThanOneMonthAway(item.paymentDate) && item.paymentType == 'month'">
                          <md-icon class="text-white">money</md-icon> {{ $t('markAsPaid') }}
                        </button>
                      </div>
                </div>
              </md-table-cell>
              <md-table-cell v-if="false">
                <md-checkbox v-model="selectedUsers" :value="item._id"></md-checkbox>
              </md-table-cell>
              <md-table-cell>

                    
                <div   class="d-none d-sm-block">
                    <md-button class="mb-1 md-just-icon md-button md-primary" @click="editUser(item)">
                      <md-icon class="text-white">edit</md-icon>
                    </md-button>
                </div>
                <div   class="d-none d-sm-block">
                    <md-button class="md-just-icon md-button md-danger" @click="confirmDelete(item._id)">
                      <md-icon class="text-white">delete</md-icon>
                    </md-button>
                </div>


              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>


      <md-card v-if="isGym()">
        <md-card-content>
          <Excel @reload="getAll()"></Excel>
        </md-card-content>
      </md-card>

    </div>
  </div>
</template>

<script>
import { getAllUsersAction,createUserAction,deleteUserAction, markAsPaidAction } from "@/pages/Users/actions";
import NewUserForm from "@/pages/Dashboard/Pages/UserProfile/NewUserForm.vue";
import Swal from 'sweetalert2';
import { uploadImageAction } from "@/pages/Products/actions";
import permissionsMixin from '@/mixins/permissionsMixin';
import { format } from 'date-fns';

import {
  Avatar,
  Excel
} from "@/components";


export default {  
  name: 'Restaurant', // Asegúrate de que el nombre coincida si planeas usar este componente recursivamente

  mixins: [permissionsMixin],
  created() {
    this.subdomain = window.location.hostname;
    this.getAll();
  },
  components: {
    NewUserForm,
    Avatar,
    Excel
  },
  computed: {
    userAdmin() {
      return this.users.filter(user => user.role == 'restaurant' || user.role == 'gym');
    },
    userOthers() {
      return this.users.filter(user => user.role != 'restaurant' && user.role != 'gym');
    },
    filteredAndSortedUsers() {
        // Filtrar por nombre
        let filteredUsers = this.users.filter(user => 
          user.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );

        // Ordenar por nombre
        return filteredUsers.sort((a, b) => {
          if (this.sortOrder === 'asc') {
            return a.name.localeCompare(b.name);
          } else {
            return b.name.localeCompare(a.name);
          }
        });
      }
  },
  data() {
    return {
      isLoading:false,
      errorMessage: false,
      subdomain: false,
      users: [],
      useredit: {},
      searchQuery: '', // Para búsqueda
      selectedUsers: [],
      showNewQrForm: false,
      selectAll: false,
      editingQrId: null,
      originalName: ''
    };
  },
  methods: {
    isMoreThanOneMonthAway(paymentDate) {
      const today = new Date();
      const oneMonthLater = new Date(today.setMonth(today.getMonth() + 1)); // Suma 1 mes a la fecha actual

      const paymentDateObj = new Date(paymentDate);

      return paymentDateObj < oneMonthLater; // Devuelve true si paymentDate es más de un mes en el futuro
    },
    isPaymentDateValid(item) {

      if(item.paymentType == 'month'){
        const paymentDate = item.paymentDate
        const today = new Date(); // Obtiene la fecha actual
        today.setHours(0, 0, 0, 0); // Establece la hora a las 00:00:00 para comparar solo la fecha

        const paymentDateObj = new Date(paymentDate); // Convierte la cadena de fecha a un objeto Date
        paymentDateObj.setHours(0, 0, 0, 0); // Establece la hora a las 00:00:00 para comparar solo la fecha

        return paymentDateObj > today; // Devuelve true si paymentDate es hoy o en el futuro, false si es pasada
      }else{
        return true;
      }

    },
    addUser(){
      this.useredit = {}
      this.showNewQrForm = !this.showNewQrForm;
    },  
    editUser(item){
        this.showNewQrForm = false;
        this.useredit = item
        this.showNewQrForm = true;
    },
    addOneMonthToDate(paymentDate) {
      const dateObj = new Date(paymentDate); // Convierte la fecha a un objeto Date

      dateObj.setMonth(dateObj.getMonth() + 1); // Suma 1 mes

      return dateObj;
    },
    async paySelected(item) {
        const newDate = this.addOneMonthToDate(item.paymentDate);
        item.paymentDate = newDate.toISOString()
        try {
          const response = await createUserAction(item);
          if (response.ok) {
            this.getAll()
          }
        } catch (error) {
          this.errorMessage = this.$t('userExists');
        }
    },
    localDate(utcDate) {
      const date = new Date(utcDate);
      return format(date, 'PP');  // Formato de fecha y hora según la hora local del navegador
    },
    showDelete(userId){
      if(userId == localStorage.getItem('userId')){
        return false;
      }
      return true;
    },
    async confirmDelete(userId) {
      if(this.isDemo()){
        return;
      }
      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('youWontBeAbleToRevertThis'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yesDeleteIt')
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteUser(userId);
          Swal.fire(
            this.$t('deleted'),
            'success'
          )
        }
      })
    },
    async deleteUser(userid) {
      try {
        const response = await deleteUserAction(userid);
         this.getAll()
      } catch (error) {
        console.log(error.message);
      }
    },

    async userCreated(event){
        if(event){
          this.showNewQrForm = false
          this.getAll();
        }      
    },
    async getAll() {
      try {
        const response = await getAllUsersAction();
        this.users = response.users;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    toggleSelectAll() {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        this.selectedUsers = this.users.map(user => user._id);
      } else {
        this.selectedUsers = [];
      }
    }
  }
};
</script>
<style>
.badge > .md-icon {
    width: 18px;
    min-width: 18px;
    height: 18px;
    font-size: 18px !important;
    font-weight: 100;
}
</style>

<style scoped>
.deactivated {
  opacity: 0.2;
}
.qrcode {
  width: 50px !important;  
}
.menu {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.menu-actions {
  display: flex;
  justify-content: flex-end;
}
.product {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 5px;
}
.product span {
  flex-grow: 1;
}
.md-select {
  width: auto; /* Ajusta el tamaño del select */
}
</style>
