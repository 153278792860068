<template>
  <div class="md-layout">
    
        <div class="mb-4 md-layout-item md-size-100 md-small-size-100 w-100">
          <md-button class="md-primary md-sm" @click="$router.push({ name: 'CreateCategory' })">+ Add Category</md-button>
      
      </div>

    
    <div class="md-layout-item md-size-100 mt-4">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>restaurant</md-icon>
          </div>
          <h4 class="title">Categories</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="categories" class="table-full-width">
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
            >
              <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>

  </div>
</template>

<script>
import {
} from "@/components";
import { getAllCategoriesAction } from "@/pages/Categories/actions";


export default {
  components: {
  },
  created() {
    this.getAll();
  },
  data() {
    return {
      categories:[]
    };
  },
  methods:{

    async getAll() {
      try {
        const response = await getAllAction();
        this.categories = response.categories;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  }
};
</script>
