<template>
  <div class="md-layout">
    
    <div
    v-for="item in menus" :key="item.id"
      class="mt-4 md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <product-card class="mt-4" header-animation="true">
        <img class="img" slot="imageHeader" :src="product2" />
        <md-icon slot="fixed-button">build</md-icon>
        <template slot="second-button">
          <router-link  class="text-white" :to="{ path: '/menu/'+item._id+'/qrs' }" ><md-icon>qr_code</md-icon>

          <md-tooltip md-direction="bottom">Qrs</md-tooltip></router-link>
        </template>
        <h4 slot="title" class="title">
          {{item.name}}
        </h4>
        <template slot="footer">
          
        </template>
      </product-card>
    </div>
   
  </div>
</template>

<script>
import {
  ProductCard,
} from "@/components";
import { getMenus } from "@/pages/Stablishments/actions";



export default {
  components: {
    ProductCard,
  },
  created() {
    this.getAllMenus();
  },
  data() {
    return {
      menus:[],
      product2: "./img/card-3.jpg",
    };
  },
  methods:{

    async getAllMenus() {
      try {
        const response = await getMenus(this.$route.params.id);
        this.menus = response.menus;
      } catch (error) {
        let errorMessage = error.message;
      console.log(errorMessage)
      }
    },
  }
};
</script>
