<template>
  <div class="md-layout">
   <div class="md-layout-item">    

      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
            <h4 class="title">{{title}}</h4>

               <slot name="top"></slot>
        </md-card-header>
        <md-card-content>
  
         <collapse 
            :open="true" 
            :collapse="['Futuras']" 
            icon="keyboard_arrow_up" 
            color-collapse="rose" 
            key="2332">
              <template slot="md-collapse-pane-1">
                 <MyClasses @deleted="deletedClass" :key="reloadclasses"  :all="false" :owner="owner" />
              </template>
          </collapse>
         <collapse 
            :open="open" 
            :collapse="['Pasadas']" 
            icon="keyboard_arrow_up" 
            color-collapse="rose" 
            key="2332">
              <template slot="md-collapse-pane-1">
                <MyClasses @deleted="deletedClass" :key="reloadclasses"  :all="true" :owner="owner"  />
              </template>
          </collapse>
        </md-card-content>
      </md-card>

    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { createAccessAction } from "@/pages/Orders/actions";
import { getUserAction,getMyProfileAction } from "@/pages/Users/actions";
import { getQrAction } from "@/pages/Qrs/actions";
import { i18n } from '@/i18n';
import { format } from 'date-fns';
import NewUserForm from "@/pages/Dashboard/Pages/UserProfile/NewUserForm.vue";
import permissionsMixin from '@/mixins/permissionsMixin';
import { logoutAction } from "@/pages/Auth/actions";
import { Collapse} from "@/components";


import {
  MyClasses,
  ScheduleWeekGym,
} from "@/components";


export default {
  mixins: [permissionsMixin],
  components: {
    MyClasses,
    ScheduleWeekGym,
    Collapse
  },  
  data() {
    return{
      reloadclasses: 3232,
      subdomain:'',
      open:false,
      owner: false
    }
  },
  created() {    
    this.subdomain = window.location.hostname;
    if(this.$route.name == 'MyClasses'){
      this.owner = true;
      this.title = this.$t("myClasses");
    }else{
      this.title = "Clases";
    }
  },
  methods: {
    deletedClass(){
      this.reloadclasses++;
    }
  }
};
</script>