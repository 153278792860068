 <template>
  <div>
    <ScheduleCalendar 
      v-if="activities.length > 0" 
      :activities="activities" 
      :phone="phone" 
      :edit="edit"
      @deleteAct="deleteAct($event)"
      @editActivity="editActivity($event)"></ScheduleCalendar>

  </div>

</template>

<script>
import { saveActivity,getActivity,deleteActivity,getAllAction } from "@/pages/Stablishments/actions";
import permissionsMixin from '@/mixins/permissionsMixin';
import Swal from 'sweetalert2';
import { Collapse,ScheduleCalendar} from "@/components";

export default {
  components: {
    Collapse,
    ScheduleCalendar
  },
  mixins: [permissionsMixin],

  data() {
    return {
      startDate:false,
      specificDate: false,
      activities:[],
      open:true,
      subdomain: window.location.hostname,
      allStablishments: [],
      edit:false,
      newActivity: {
        name: '',
        day: 1, // Cambiado a número (0 = Domingo, 1 = Lunes, etc.)
        startTime: '',
        endTime: ''
      },
      errors: {
        name: false,
        day: false,
        startTime: false,
      },
      daysOfWeek: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado','Domingo']
    };
  },
  async created() {

    if(this.isGym()){
      this.edit = true
    }
    this.getAll();
  },
  props: {
    phone: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    completeview: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    qr: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
  },
  computed: {
    activitiesByDay() {
      const sortedActivities = this.activities.reduce((acc, activity) => {
        if (!acc[activity.day]) {
          acc[activity.day] = [];
        }
        acc[activity.day].push(activity);
        return acc;
      }, {});

      // Ordenar las actividades por la hora de inicio
      Object.keys(sortedActivities).forEach(day => {
        sortedActivities[day].sort((a, b) => a.startTime.localeCompare(b.startTime));
      });

      return sortedActivities;
    }
  },
  methods: {
    cancel(){
      this.newActivity = {
        name: '',
        day: 1, // Cambiado a número (0 = Domingo, 1 = Lunes, etc.)
        startTime: '',
        endTime: ''
      }
      this.open=true
    },
    validateFields() {
      this.errors.name = !this.newActivity.name;
      this.errors.day = this.newActivity.day === null || this.newActivity.day === undefined;
      this.errors.startTime = !this.newActivity.startTime;

      return !this.errors.name && !this.errors.day && !this.errors.startTime;
    },
    async deleteAllActivity(id){
            await deleteActivity(id);
            this.getAll();
    },
    deleteAct(id){
        if(this.isDemo()){
          return;
        }
        Swal.fire({
          title: this.$t('areYouSure'),
          text: this.$t('youWontBeAbleToRevertThis'),
          type: "warning",
          position: 'top',
          confirmButtonText: this.$t('delete'),
          showCancelButton: true
        }).then((result) => {
          if(result.isConfirmed){ 
            this.deleteAllActivity(id);
          }
        });
    },
    async editActivity(act){
      if(act.startDate){
        this.startDate = new Date(act.startDate);
        this.specificDate = true;
      }else{
        this.specificDate = false;
      }

        this.open = true;
      this.newActivity = act
    },
    async getAll() {
        try {

          const data = await getActivity();
          this.activities = data.activities;


          const stablishmentsResponse = await getAllAction();
          this.allStablishments = stablishmentsResponse.stablishments

        } catch (error) {
          this.errorMessage = error.message;
        }
      },
  },
};
</script>
