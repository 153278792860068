<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card v-if="!editing">
        <md-card-header class="mb-3 md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>store</md-icon>
          </div>
          <h4 class="title">{{ $t('stablishments') }}</h4>
        </md-card-header>
        
        <!-- Implementamos el carrusel aquí -->
        <CarouselItem :id="'stablishmentCarousel'" :items="stablishments" >
          <template #default="slotProps">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100">
              <product-card class="" header-animation="false">
                <img class="img" slot="imageHeader" :src="slotProps.item.coverImage ? slotProps.item.coverImage : product2" />
                <md-icon slot="fixed-button">build</md-icon>
                <template slot="first-button">
                  <md-icon>map</md-icon>
                  <md-tooltip md-direction="bottom">{{ $t('map') }}</md-tooltip>
                </template>
                <h4 slot="title" class="title">
                  {{ slotProps.item.name }}
                </h4>
                <template slot="footer" v-if="isRestaurant()">
                  <div class="text-center md-layout-item md-size-100">
                    <router-link class="text-white" :to="{ path: 'stablishments/'+slotProps.item._id+'/orders' }">
                      <md-button class="md-primary md-sm">
                        <md-icon class="material-icons">restaurant</md-icon> 
                        {{ $t('viewOrders') }}
                      </md-button>
                    </router-link>
                    <router-link class="text-white" :to="{ path: 'stablishments/'+slotProps.item._id+'/ordersmap' }">
                      <md-button class="md-primary md-sm">
                        <md-icon class="material-icons">restaurant</md-icon> 
                        {{ $t('viewOrdersMap') }}
                      </md-button>
                    </router-link>
                  </div>
                </template>
              </product-card>
            </div>
          </template>
        </CarouselItem>
        
      </md-card>
    </div>
  </div>
</template>

<script>
import { ProductCard } from "@/components";
import { getAllAction } from "@/pages/Stablishments/actions";
import permissionsMixin from '@/mixins/permissionsMixin';
import { CarouselItem } from "@/components"; // Importamos el mismo componente Carousel

export default {
  mixins: [permissionsMixin],
  components: {
    ProductCard,
    CarouselItem  // Usamos el componente Carousel para ambos casos
  },
  created() {
    this.getAll();
  },
  data() {
    return {
      editing: false,
      stablishments: [],
      product1: "./img/card-2.jpg",
      product2: "./img/card-3.jpg",
      product3: "./img/card-1.jpg"
    };
  },
  methods: {
    edit(item) {
      this.editing = item;
    },
    save(item) {
      this.editing = null;
    },
    cancel(item) {
      this.editing = null;
    },
    async getAll() {
      try {
        const response = await getAllAction();
        this.stablishments = response.stablishments;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  }
};
</script>

<style scoped>
/* Estilos personalizados si los necesitas */
</style>
