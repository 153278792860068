<template>
  <div class="not-found">
    <h1>Nothing here</h1></p>
    <router-link to="/"><span class="text-primary">Home</span></router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 50px;
}

.not-found h1 {
  font-size: 2.5rem;
}

.not-found p {
  font-size: 1.2rem;
}

.not-found a {
  color: #42b983;
  text-decoration: none;
}
</style>
