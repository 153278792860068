import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";


export const getAllProductAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/products`);

    return {
      ok: true,
      products: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const uploadImageAction = async (imageFile) => {
  const formData = new FormData();
  formData.append('image', imageFile);

  try {
    const { data } = await wencesApi.post(`/api/upload/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return {
      ok: true,
      data: data
    };
  } catch (error) {
    if (isAxiosError(error)) {
      return {
        ok: false,
        message: error.response?.data?.message || "Error al subir la imagen",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const saveAllAction = async (jsonData) => {
  try {
    const { data } = await wencesApi.post(`/api/products/saveall`, 
      jsonData
    );

    return {
      ok: true
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};