<template>
  <div class="w-100 float-left bg-white">

    <md-table 
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      class="paginated-table  paginated-table-exercises  mb-4 theadnone d-none d-sm-block"
    >
      <md-table-row 
        slot="md-table-row"  
         v-for="(item,index) in myExercises"
      >
        <md-table-cell :md-label="$t('name')" md-sort-by="name">
           <div  class="p-2 float-left width56" v-if="source == 'custom' && !myPlanning">
            <small class="cursor-pointer p-2" v-if="index > 0"  @click="moveExerciseUp(index)"> 
              <md-icon class="text-primary">arrow_upward</md-icon>
            </small>
            <small class="p-2" v-if="index < (exercises.length-1)"  @click="moveExerciseDown(index)"> 
              <md-icon class="cursor-pointer  text-primary">arrow_downward</md-icon>
            </small>
          </div>
          <div class="">
            <div class="float-left">
              <img :src="item.image" loading="lazy" width="90px"/> 
            </div>
            <div>
              <div>
              <strong>{{ item.name }}</strong>
              </div>
              <div class="ml-2 float-left">
                <span v-for="body in item.bodyZone" class="px-1"><span  class=" mr-1 badge badge-primary"> {{  body }} </span></span>
             
                    <span v-if="item.sets && source !== 'custom'" class="badge badge-warning">{{item.sets}} series</span>
            
                <div class="" v-if="source !== 'custom' || myPlanning">
                    <small class="d-block text-secondary " v-if="item.repetitions.length > 0" >Repeticiones: {{item.repetitions.join(',')}}   </small>
                    <small class="d-block text-secondary" v-if="item.duration" >Duración: {{item.duration}} min</small>
                    <small class="d-block text-secondary" v-if="item.distance" >distancia:  {{item.distance}} m</small>
                    <small class="d-block text-secondary" v-if="item.weight && item.weight.length > 0 && item.weight[0] > 0" > Peso: {{item.weight.join(',')}} </small>
                    <small class="d-block" v-if="item.speed" >velocidad: {{item.speed}} km/h</small>
                    <small class="d-block text-secondary" v-if="item.rest" >Descanso:  {{item.rest}} seg</small>
                  </div>
                
              </div>
            </div>
          </div>
        </md-table-cell>
        <md-table-cell class="position-inherit" v-if="!myPlanning">
          <div class="float-right">
            <EditMyExercises :source="source"  :myExercise="item" @updateTraining="updateTraining($event,index)"/>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <div 
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      class="d-block d-sm-none myexercises"
    >
      <div v-for="(item,index) in myExercises" class="bg-white  float-left my-2 py-2 w-100">
        
          <div  class="p-2 float-right width56" v-if="source == 'custom' && !myPlanning">
            <small class="cursor-pointer p-2" v-if="index > 0"  @click="moveExerciseUp(index)"> 
              <md-icon class="text-primary">arrow_upward</md-icon>
            </small>
            <small class="p-2 float-right" v-if="index < (exercises.length-1)"  @click="moveExerciseDown(index)"> 
              <md-icon class="cursor-pointer  text-primary">arrow_downward</md-icon>
            </small>
          </div>
          <div>
              <div class="float-left">
                <img :src="item.image" loading="lazy" width="90px"/> 
              </div>
              <div>
                <div>
                  <small><strong>{{ item.name }}</strong></small>
                </div>
                <div class="pl-3 float-left">  
                  <span v-for="body in item.bodyZone" class="px-1"><span  class=" mr-1 badge badge-primary"> {{  body }} </span></span>
                  <span v-if="item.sets && source !== 'custom'" class="badge badge-warning">{{item.sets}} series</span>
                  <div class=""  v-if="source !== 'custom' || myPlanning">
                    <small class="d-block text-secondary " v-if="item.repetitions.length > 0" >Repeticiones: {{item.repetitions.join(',')}}   </small>
                    <small class="d-block text-secondary" v-if="item.duration" >Duración: {{item.duration}} min</small>
                    <small class="d-block text-secondary" v-if="item.distance" >distancia:  {{item.distance}} m</small>
                    <small class="d-block text-secondary" v-if="item.weight && item.weight.length > 0 && item.weight[0] > 0" > Peso: {{item.weight.join(',')}} </small>
                    <small class="d-block" v-if="item.speed" >velocidad: {{item.speed}} km/h</small>
                    <small class="d-block text-secondary" v-if="item.rest" >Descanso:  {{item.rest}} seg</small>
                  </div>
                </div>
              </div>
          </div>
        <div>

          <div class="d-grid w-100">
            <EditMyExercises v-if="source=='custom' && !myPlanning" :source="source" :myExercise="item" @updateTraining="updateTraining($event,index)"/>
          </div>
        </div>
      </div>
    </div>      
    </div>
  </div>
</template>

<script>
import { getAllExercisesAction, deleteExerciseAction ,getAllExercisesOfTrainingAction ,updateTrainingExercisesAction, updateUserPlanningAction} from '@/pages/Training/actions';
import CreateExercise from './CreateExercise.vue'; // El formulario de edición
import EditMyExercises from './EditMyExercises.vue'; // El formulario de edición

import Swal from 'sweetalert2';

export default {
  data() {
    return {
      exercises: [],
      currentSort: 'name',
      currentSortOrder: 'asc',
      mobile: false, // Se puede configurar según sea necesario
      selectedExercise: null // Ejercicio seleccionado para editar
    };
  },
  props:{
    trainingId: String,
    myExercises:{},
    source:String,
    myPlanning:Object
  },
  components: {
    CreateExercise,
    EditMyExercises
  },
  async created() {
    this.exercises = this.myExercises
  },
  methods: {
    updateTraining(exercisenew,index){

     this.exercises[index] = exercisenew
      this.updateAll()
    },
    async updateAll(){
        const response = await updateTrainingExercisesAction(
          this.trainingId,
          this.exercises
        );
        this.$emit("updateTraining")
    },
    moveExerciseUp(index) {
      const temp = this.exercises[index];
      this.exercises.splice(index, 1);
      this.exercises.splice(index - 1, 0, temp);

      this.updateAll()
    },
    moveExerciseDown(index) {

      const temp = this.exercises[index];
      this.exercises.splice(index, 1);
      this.exercises.splice(index + 1, 0, temp);

  
      this.updateAll()
    },
    
    editExercise(exercise) {
      this.selectedExercise = exercise;
    },
    async confirmDelete(exercise) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete the exercise "${exercise.name}". This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel'
      });

      if (result.isConfirmed) {
        this.deleteExercise(exercise._id);
      }
    },
    async deleteExercise(id) {
      try {
        const response = await deleteExerciseAction(id);
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Exercise deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });
          // Recargar la lista de ejercicios
     
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete exercise: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error deleting exercise:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    onExerciseUpdated() {
      // Actualiza la lista de ejercicios después de la edición
      this.selectedExercise = null;

    }
  }
};
</script>

<style>
.myexercises{
  
}
.width56{
  width:56px;
}
</style>

<style scoped>
.myexercises .md-theme-default{
  display:contents !important;
}
.position-inherit {
  position:inherit;
}
</style>
