import { wencesApi } from "@/api/wencesApi";

// Crear un nuevo Planning
export const createPlanningAction = async (formData) => {
  try {
    const { data } = await wencesApi.post("/api/plannings", formData);

    return {
      ok: true,
      planning: data.planning
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo crear el planning",
    };
  }
};


// Obtener un Planning específico por ID
export const copyPlanningAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/plannings/${id}/duplicate`);

    return {
      ok: true,
      planning: data.planning
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo obtener el planning",
    };
  }
};

// Obtener todos los Plannings
export const getAllPlanningsAction = async () => {
  try {
    const { data } = await wencesApi.get("/api/plannings");

    return {
      ok: true,
      plannings: data
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo obtener los plannings",
    };
  }
};

// Obtener todos los Plannings
export const getMyOwnerPlanningsAction = async () => {
  try {
    const { data } = await wencesApi.get("/api/plannings/myownerplanning");

    return {
      ok: true,
      plannings: data
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo obtener los plannings",
    };
  }
};


// Obtener un Planning específico por ID
export const getPlanningAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/plannings/${id}`);

    return {
      ok: true,
      planning: data
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo obtener el planning",
    };
  }
};

// Actualizar un Planning
export const updatePlanningAction = async (id, formData) => {
  try {
    const { data } = await wencesApi.put(`/api/plannings/${id}`, formData);

    return {
      ok: true,
      planning: data.planning
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo actualizar el planning",
    };
  }
};

// Eliminar un Planning
export const deletePlanningAction = async (id) => {
  try {
    await wencesApi.delete(`/api/plannings/${id}`);

    return {
      ok: true,
      message: "Planning eliminado exitosamente"
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo eliminar el planning",
    };
  }
};


