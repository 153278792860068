<template>
  
</template>

<script>
import { logoutAction } from "@/pages/Auth/actions";

export default {
 
 
  mounted() {

  },
  async created(){

        await logoutAction();

        this.$router.push('login')

  },
  methods: {

  }
};
</script>
