<template>
  <div class="board">
    <!-- Columna TO DO -->
    <div 
      class="column"
      @dragover.prevent
      @drop="drop('todo')"
    >
      <h3>TO DO</h3>
      <div 
        v-for="ticket in columns.todo" 
        :key="ticket.id" 
        :draggable="true" 
        @dragstart="dragStart(ticket, 'todo')" 
        :class="['ticket', ticket.colorClass]"
      >
        <span class="remove-ticket" @click.stop="removeTicket('todo', ticket.id)">✕</span>
        <input class="titleinput" v-model="ticket.title" @input="saveToLocalStorage" placeholder="Título" />
        <textarea v-model="ticket.description" @input="saveToLocalStorage" placeholder="Descripción"></textarea>
        <div class="color-palette">
          <span @click.stop="changeTicketColor(ticket, 'color-red')" class="color-circle red"></span>
          <span @click.stop="changeTicketColor(ticket, 'color-blue')" class="color-circle blue"></span>
          <span @click.stop="changeTicketColor(ticket, 'color-green')" class="color-circle green"></span>
          <span @click.stop="changeTicketColor(ticket, 'color-yellow')" class="color-circle yellow"></span>
        </div>
      </div>
      <!-- Área para hacer clic y agregar un nuevo ticket -->
      <div class="add-new-ticket-area" @click="addNewTicket('todo')">Agregar nuevo ticket</div>
    </div>

    <!-- Columna ON GOING -->
    <div 
      class="column"
      @dragover.prevent
      @drop="drop('ongoing')"
    >
      <h3>ON GOING</h3>
      <div 
        v-for="ticket in columns.ongoing" 
        :key="ticket.id" 
        :draggable="true" 
        @dragstart="dragStart(ticket, 'ongoing')" 
        :class="['ticket', ticket.colorClass]"
      >
        <span class="remove-ticket" @click.stop="removeTicket('ongoing', ticket.id)">✕</span>
        <input class="titleinput" v-model="ticket.title" @input="saveToLocalStorage" placeholder="Título" />
        <textarea v-model="ticket.description" @input="saveToLocalStorage" placeholder="Descripción"></textarea>
        <div class="color-palette">
          <span @click.stop="changeTicketColor(ticket, 'color-red')" class="color-circle red"></span>
          <span @click.stop="changeTicketColor(ticket, 'color-blue')" class="color-circle blue"></span>
          <span @click.stop="changeTicketColor(ticket, 'color-green')" class="color-circle green"></span>
          <span @click.stop="changeTicketColor(ticket, 'color-yellow')" class="color-circle yellow"></span>
        </div>
      </div>
      <!-- Área para hacer clic y agregar un nuevo ticket -->
      <div class="add-new-ticket-area" @click="addNewTicket('ongoing')">Agregar nuevo ticket</div>
    </div>

    <!-- Columna DONE -->
    <div 
      class="column"
      @dragover.prevent
      @drop="drop('done')"
    >
      <h3>DONE</h3>
      <div 
        v-for="ticket in columns.done" 
        :key="ticket.id" 
        :draggable="true" 
        @dragstart="dragStart(ticket, 'done')" 
        :class="['ticket', ticket.colorClass]"
      >
        <span class="remove-ticket" @click.stop="removeTicket('done', ticket.id)">✕</span>
        <input class="titleinput" v-model="ticket.title" @input="saveToLocalStorage" placeholder="Título" />
        <textarea v-model="ticket.description" @input="saveToLocalStorage" placeholder="Descripción"></textarea>
        <div class="color-palette">
          <span @click.stop="changeTicketColor(ticket, 'color-red')" class="color-circle red"></span>
          <span @click.stop="changeTicketColor(ticket, 'color-blue')" class="color-circle blue"></span>
          <span @click.stop="changeTicketColor(ticket, 'color-green')" class="color-circle green"></span>
          <span @click.stop="changeTicketColor(ticket, 'color-yellow')" class="color-circle yellow"></span>
        </div>
      </div>
      <!-- Área para hacer clic y agregar un nuevo ticket -->
      <div class="add-new-ticket-area" @click="addNewTicket('done')">Agregar nuevo ticket</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: {
        todo: [],
        ongoing: [],
        done: []
      },
      draggedTicket: null,
      draggedFromColumn: ''
    };
  },
  mounted() {
    this.loadFromLocalStorage();
  },
  methods: {
    dragStart(ticket, column) {
      this.draggedTicket = ticket;
      this.draggedFromColumn = column;
    },
    drop(column) {
      if (this.draggedTicket) {
        this.columns[this.draggedFromColumn] = this.columns[this.draggedFromColumn].filter(t => t.id !== this.draggedTicket.id);
        this.columns[column].push(this.draggedTicket);
        this.draggedTicket = null;
        this.saveToLocalStorage();
      }
    },
    addNewTicket(column) {
      const newTicket = {
        id: Date.now(),
        title: '',
        description: '',
        colorClass: 'default'
      };
      this.columns[column].push(newTicket);
      this.saveToLocalStorage();
    },
    removeTicket(column, ticketId) {
      this.columns[column] = this.columns[column].filter(ticket => ticket.id !== ticketId);
      this.saveToLocalStorage();
    },
    changeTicketColor(ticket, colorClass) {
      ticket.colorClass = colorClass;
      this.saveToLocalStorage();
    },
    saveToLocalStorage() {
      localStorage.setItem('columns', JSON.stringify(this.columns));
    },
    loadFromLocalStorage() {
      const savedColumns = JSON.parse(localStorage.getItem('columns'));
      if (savedColumns) {
        this.columns = savedColumns;
      } else {
        this.columns = {
          todo: [],
          ongoing: [],
          done: []
        };
      }
    }
  }
};
</script>

<style>
.container{
  max-width:100%;
}
</style>
<style scoped>
.board {
  display: flex;
  width: 100%;
  color: black;
  background: white;
  justify-content: space-around;
}

.column {
  width: 30%;
  padding: 10px;
  border: 1px solid #ccc;
  min-height: 600px;
  position: relative;
}

.ticket {
  padding: 10px;
  margin: 10px 0;
  background-color: #f4f4f4;
  cursor: pointer;
  position: relative;
}

.color-palette {
  display: flex;
  justify-content: space-between;
  width: 100px;
  position: absolute;
  top: 5px;
  left: 10px;
}

.color-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 5px;
}

.red {
  background-color: #ffacac;
}

.blue {
  background-color: #b8b8ff;
}

.green {
  background-color: #92b892;
}

.yellow {
  background-color: #ffffff;
}

.color-red {
  background-color: #ffacac;
}

.color-blue {
  background-color: #b8b8ff;
}

.color-green {
  background-color: #92b892;
}

.color-yellow {
  background-color: #ffffff;
}

input, textarea {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 5px;
  font-size: 16px;
  margin-top: 5px;
  color: #333;
  height: 100px;
  resize: none;
}

.titleinput {
  font-size: 20px;
}

input::placeholder, textarea::placeholder {
  color: #999;
}

input:focus, textarea:focus {
  outline: none;
}

.remove-ticket {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  color: red;
  font-weight: bold;
}

.add-new-ticket-area {
  margin-top: 20px;
  padding: 10px;
  background-color: #e0e0e0;
  text-align: center;
  cursor: pointer;
}
</style>
