<template>
 <div>
  <md-card>
      <md-card-header>
          <div class="card-icon p-1 bg-danger">
            <md-icon class="">sports_volleyball</md-icon>
          </div>
          <h4 class="title mt-2">Ejercicios</h4>
      </md-card-header>
      <md-card-content>
       <ExerciseList/>

      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import permissionsMixin from '@/mixins/permissionsMixin';
import ExerciseList from "@/pages/Training/ExerciseList.vue";
import CreateExercise from "@/pages/Training/CreateExercise.vue";



export default {
  mixins: [permissionsMixin],
  data() {
    return {
      open:false
    };
  },
  components: {
    ExerciseList,
    CreateExercise
  },
  async created() {
  
  },
  methods:{
 
  
  },
};
</script>

