<template>
  <div class="md-layout p-4">
   Redireccionando...
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  created() {   
    if(this.$route.name == 'MercadoPagoRedirect' && this.$route.query.state && this.$route.query.code){

      let state = this.$route.query.state;
        state = state.split('-');
        state = state[0];
        const newurl = "https://"+state+".app.gotake.it/mercadopago/confirm?code="+this.$route.query.code+"&state="+this.$route.query.state;
        window.location.href = newurl
        return;
      
    }
  },
};
</script>