import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";


export const getAllQrAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/qrs`);

    return {
      ok: true,
      qrs: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getQrAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/qrs/getqr/${id}`);

    return {
      ok: true,
      data: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getMap = async () => {
  try {
    const { data } = await wencesApi.get(`/api/qrs/getmap`);

    return {
      ok: true,
      data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const saveMap = async (jsonData) => {
  try {

    const { data } = await wencesApi.post(`/api/qrs/savemap`, {lines: jsonData});

    return {
      ok: true
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    throw new Error("No se pudo realizar la petición");
  }
};


export const savePositions = async (jsonData) => {
  try {

    const { data } = await wencesApi.post(`/api/qrs/savepositions`, jsonData);

    return {
      ok: true
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    throw new Error("No se pudo realizar la petición");
  }
};

export const saveAllAction = async (jsonData) => {
  try {
    // Copia profunda de jsonData para no modificar el original
    const dataToSend = JSON.parse(JSON.stringify(jsonData));

    // Eliminar los IDs temporales
    dataToSend.qrs = dataToSend.qrs.map(qr => {
      if (!qr._id || qr._id.length !== 24) {
        delete qr._id;
      }
      return qr;
    });

    const { data } = await wencesApi.post(`/api/qrs/saveall`, dataToSend);

    return {
      ok: true
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};
