<template>
  <div>
    <md-button class="w-100 md-button md-primary" @click="open=true" v-if="!open">
      + Agregar Planning
    </md-button>
    <md-table 
      v-model="plannings"
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      class="paginated-table table-striped table-hover"
    >
      <md-table-row 
        slot="md-table-row"  
        slot-scope="{ item }"
      >
        <!-- Columna Nombre del Planning -->
        <md-table-cell :md-label="$t('name')" md-sort-by="name">
          <span>{{ item.name }}</span>
        </md-table-cell>

        <!-- Columna Descripción -->
        <md-table-cell :md-label="$t('description')">
          <span>{{ item.description }}</span>
        </md-table-cell>

        <!-- Columna Dificultad -->
        <md-table-cell :md-label="$t('difficulty')">
          <span>{{ item.difficulty }}</span>
        </md-table-cell>

        <!-- Columna Objetivo -->
        <md-table-cell :md-label="$t('goal')">
          <span>{{ item.goal }}</span>
        </md-table-cell>

        <!-- Columna Días por Semana -->
        <md-table-cell :md-label="$t('dayperweek')">
          <span>{{ item.dayperweek }}</span>
        </md-table-cell>

        <!-- Columna de Acciones -->
        <md-table-cell>
          <md-button class="md-primary" @click="editPlanning(item);open=true" v-if="item.source !== 'default'">
            Edit
          </md-button>
          <md-button class="md-danger" @click="confirmDelete(item)" v-if="item.source !== 'default'">
            Delete
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <!-- Cargar formulario de edición solo si hay un planning seleccionado -->
    <CreatePlanning
      v-if="selectedPlanning && open"
      :key="selectedPlanning._id" 
      :preloadedData="selectedPlanning"
      @formSubmitted="onPlanningUpdated"
    />
  </div>
</template>

<script>
import { getAllPlanningsAction, deletePlanningAction } from '@/pages/Training/actions';

import CreatePlanning from './CreatePlanning.vue'; // El formulario de edición
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      plannings: [],
        open:false,
      currentSort: 'name',
      currentSortOrder: 'asc',
      selectedPlanning: null // Planning seleccionado para editar
    };
  },
  components: {
    CreatePlanning
  },
  async created() {
    this.start();
  },
  methods: {
    async start() {
      try {
        const response = await getAllPlanningsAction();
        if (response.ok) {
          this.plannings = response.plannings;
        } else {
          console.error('Failed to fetch plannings: ', response.message);
        }
      } catch (error) {
        console.error('Error fetching plannings: ', error);
      }
    },
    editPlanning(planning) {
      this.selectedPlanning = planning;
    },
    async confirmDelete(planning) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete the planning "${planning.name}". This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel'
      });

      if (result.isConfirmed) {
        this.deletePlanning(planning._id);
      }
    },
    async deletePlanning(id) {
      try {
        const response = await deletePlanningAction(id);
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Planning deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });
          // Recargar la lista de plannings
          this.start();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete planning: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error deleting planning:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    onPlanningUpdated() {
      // Actualiza la lista de plannings después de la edición
      this.selectedPlanning = null;
      this.start(); // Recargar la lista
    }
  }
};
</script>
