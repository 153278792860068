import { render, staticRenderFns } from "./ExerciseList.vue?vue&type=template&id=cd475714&scoped=true"
import script from "./ExerciseList.vue?vue&type=script&lang=js"
export * from "./ExerciseList.vue?vue&type=script&lang=js"
import style0 from "./ExerciseList.vue?vue&type=style&index=0&id=cd475714&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd475714",
  null
  
)

export default component.exports