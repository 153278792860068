<template>
  <div id="app">
    <h1>Order map</h1>
    <div class="canvas-container" ref="canvasContainer">
      <canvas ref="canvas" width="800" height="600"></canvas>
      <div
        v-for="(element, index) in elements"
        :key="index"
        class="element cursor-pointer"
        :class="[getStatusClass(element._id), getBackgroundColor(element._id)]"
        :style="{ left: element.x + 'px', top: element.y + 'px' }"
        @click="openOrders(element._id)"
      >
        <small> <md-icon>qr_code</md-icon>{{ element.name }} </small>
      </div>
    </div>

    <!-- Modal para mostrar órdenes -->
    <div v-if="showOrdersModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeOrdersModal">&times;</span>
        <h2>Órdenes para {{ selectedQrName }}</h2>
        <order-card 
          v-for="order in filteredOrdersByQr(selectedQrId)" 
          :key="order._id" 
          :data="order" 
          :currency="currency" 
          class="card-order float-left d-block mx-2  mt-4"
        ></order-card> 
      </div>
    </div>
  </div>
</template>

<script>
import { getAllQrAction, getMap } from "@/pages/Qrs/actions";
import { getAllAction } from "@/pages/Orders/actions";
import { OrderCard } from "@/components";

export default {
  name: 'App',
  components: {
    OrderCard
  },
  data() {
    return {
      currency:false,
      jsonData: {
        walls: []
      },
      elements: [],
      walls: [],
      orders: [],
      errorMessage: "",
      showOrdersModal: false,
      selectedQrId: null,
      selectedQrName: ''
    };
  },
  methods: {
    async getAll() {
      try {
        // Obtener QRs
        const qrResponse = await getAllQrAction();
        this.elements = qrResponse.qrs.map((qr, index) => ({
          _id: qr._id,
          name: qr.name,
          x: qr.x, // Posicionarlas inicialmente
          y: qr.y
        }));

        // Obtener mapa
        const mapResponse = await getMap();
        this.walls = mapResponse.data[0].lines;
        this.jsonData.walls = [...this.walls];
        this.drawWalls();

        // Obtener órdenes
        const ordersResponse = await getAllAction(this.$route.params.id,['pending','ongoing']);
        this.orders = ordersResponse.orders;
        this.currency = ordersResponse.result.currency;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    getBackgroundColor(qrId) {
      const order = this.orders.find(order => order.qr._id === qrId);
      if (order) {
        if (order.status === 'pending') {
          return '';
        } else if (order.status === 'ongoing') {
          return 'bg-warning';
        }
      }
      return 'bg-white';
    },
    getStatusClass(qrId) {
      const order = this.orders.find(order => order.qr._id === qrId);
      if (order && order.status === 'pending') {
        return 'pending';
      }
      return '';
    },
    openOrders(qrId) {
      const qr = this.elements.find(element => element._id === qrId);
      if (qr) {
        this.selectedQrId = qrId;
        this.selectedQrName = qr.name;
        this.showOrdersModal = true;
      }
    },
    closeOrdersModal() {
      this.showOrdersModal = false;
      this.selectedQrId = null;
      this.selectedQrName = '';
    },
    filteredOrdersByQr(qrId) {
      const filteredOrders = this.orders.filter(order => order.qr._id == qrId);
      return filteredOrders
    },
    drawWalls() {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.beginPath();
      this.walls.forEach(wall => {
        context.moveTo(wall.x1, wall.y1);
        context.lineTo(wall.x2, wall.y2);
      });
      context.stroke();
    }
  },
  async mounted() {
    await this.getAll();
    setInterval(this.getAll, 2000); // Llama a getAll cada 2 segundos
  }
};
</script>

<style scoped>

.cursor-pointer{
  cursor:pointer;
}
.float-left{
  float:left;
}
.float-right{
  float:right;
}

@keyframes blink {
  0% { background-color: green; }
  50% { background-color: white; }
  100% { background-color: green; }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.canvas-container {
  position: relative;
  width: 800px;
  height: 600px;
  margin: 0 auto;
  border: 1px solid #ccc;
}

canvas {
  border: 1px solid #ccc;
}

.element {
  position: absolute;
  width: 100px; /* Increased width to fit table names */
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #000;
  user-select: none; /* Prevent text selection */
  cursor: pointer;
}

.element.square {
  border-radius: 0;
}

.element.round {
  border-radius: 50%;
}

.element.umbrella {
  background-color: #add8e6;
  border-radius: 50%;
}

.pending {
  animation: blink 1s infinite;
}

.bg-success {
  background-color: green;
}

.bg-warning {
  background-color: yellow;
}

.bg-white {
  background-color: white;
}

/* Estilos del modal */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  display:block;
}

.card-order {
  max-width: 300px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
