<template>
  <div>
    <div class="header text-center">
      <h3 class="title">ADMIN</h3>
    </div>

    <div class="md-layout">
      <div class="md-layout-item md-size-100 md-small-size-100 float-right p-2"
       v-for="db in dbs.result" :key="db"  v-if="db !== 'app' && db !== 'localhost' ">
        DB : {{db}} <br>
             <AdminbLock :name="db"></AdminbLock>
      </div>

    </div>
  </div>
</template>

<script>
import AdminbLock from "@/pages/Dashboard/AdminbLock.vue";

import { getSettingsAction, getAdminAction, getDbInfoAction } from "@/pages/Auth/actions";

export default {
  components:{
    AdminbLock
  },
  data() {
    return {
      dbs: { result: [] }, // Asegúrate de que dbs sea un objeto reactivo con un array result
      info: {}
    };
  },
  created() {
    this.subdomain = window.location.hostname;
    const parts = this.subdomain.split(".");
    if (parts[0] === 'localhost') {
      this.getDbs();
    }
  },
  methods: {
    async getDbs() {
      const data = await getAdminAction();
      this.$set(this.dbs, 'result', data.result); // Usar $set para reactividad
    },
    async open(name) {
      if(!this.info[name]){

      const dbInfo = await getDbInfoAction(name);
      this.$set(this.info, name, dbInfo); // Usar $set para que sea reactivo
      }
    }
  }
};
</script>
