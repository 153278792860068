<template>
  <div>
    <h2>Opening Hours</h2>
    <div v-for="(hours, day) in openingHours" :key="day" class="day-hours">
      <label :for="`open-${day}`">{{ capitalize(day) }}:</label>
      <input 
        type="time" 
        :id="`open-${day}`" 
        v-model="hours.open" 
        @input="emitChanges" 
      />
      to
      <input 
        type="time" 
        :id="`close-${day}`" 
        v-model="hours.close" 
        @input="emitChanges" 
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialHours: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      openingHours: { ...this.initialHours }
    };
  },
  methods: {
    emitChanges() {
      this.$emit('update:hours', this.openingHours);
    },
    capitalize(day) {
      return day.charAt(0).toUpperCase() + day.slice(1);
    }
  }
};
</script>

<style scoped>
.day-hours {
  margin-bottom: 10px;
}
</style>
