import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";

export const menuAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/menus/${id}`);

    return {
      ok: true,
      name: data.menu.name,
      stablishment: data.menu.stablishment,
      pendingOrders: data.pendingOrders,
      qrName: data.qrName,
      menuDisabled: data.menuDisabled,
      products: data.menu.products,
      business: data.business,
      result: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


