<template>
  <stats-card header-color="success" class="ticket">
    <template slot="content">
        <div class="w-100 pt-2 float-left">
        <h4 class="float-left mt-2">#{{ data.turnNumber }} - {{ data.qr.name }}</h4>

      <h3 class="float-right">{{ data.totalPrice }} {{ currencySymbol }}</h3>
        </div>

      <md-button v-if="data.status == 'pending'" @click="changeStatus('ongoing')" class="md-primary card-icon my-3 w-100">
      <span style="margin-left:10px;"><md-icon class="text-white">restaurant</md-icon>{{ $t('sendToKitchen') }}</span>
      </md-button>

      <div v-if="data.status == 'ongoing' || data.status == 'done'" class="align-left mb-3">
        <div class="row">
        <span class="col-12 btn-succes float-left btn bg-success text-white" v-if="data.status == 'ongoing'" @click="changeStatus('done')" >
            <md-icon class="text-white">smartphone</md-icon><span style="margin-left:10px;">{{ $t('sendAlert') }}</span>
        </span>
        <span class="col-12 mt-3 btn bg-primary text-white" v-if="(data.status == 'ongoing' || data.status == 'done') && payed" @click="changeStatus('close')" >
          <md-icon class="text-white">check</md-icon><span style="margin-left:10px;">{{ $t('finish') }}</span>
        </span>
        <span class="col-12 mt-3 btn bg-danger text-white" v-if="(data.status == 'ongoing' || data.status == 'done') && !payed" @click="changeStatus('payment')" >
          <md-icon class="text-white">payment</md-icon><span style="margin-left:10px;">{{ $t('paymentPending') }}</span>
        </span>
        </div>
      </div>
  
        <md-progress-spinner  v-if="loading"
          :md-diameter="20" 
          md-mode="indeterminate" 
          class="md-secondary mr-2">
        </md-progress-spinner>
        
      <div v-if="data.status == 'payment'" class="align-left mb-3">
        <span v-if="payed" @click="changeStatus('close')" class=" mt-3 btn bg-primary text-white">
          <md-icon class="text-white">check</md-icon><span style="margin-left:10px;">{{ $t('finish') }}</span>
        </span>
      </div>


    </template>
    <template slot="content"> 
<div class="w-100">


        <p class="category" v-if="data.status == 'pending'">
      <strong class="title  text-success"  v-if="payed"> {{ $t('yespaid') }}</strong>
      <strong class="title  text-danger" v-else>{{ $t('nopaid') }}</strong>
      </p>

      <p class="category" v-else>
                <md-switch  v-model="payed" 
                  @change="(payed) => updateValuePaid()"
                 class="w-100">
                  <span v-if="!payed" class="title  text-danger" >{{ $t('nopaid') }}</span>
                  <span v-else class="title  text-success">{{ $t('yespaid') }}</span>
                </md-switch>
      </p>

      <div class="title" v-if="data.status != 'done'">
        <h5 class="text-secondary mt-2">{{ data.buyerName }}</h5>
      </div>
        <h5 class="text-secondary mt-2">{{ data.buyerPhone }}</h5>

    
      <div v-if="data.status == 'ongoing'">
      <p class="category">{{ $t('inKitchenFor') }}
      <span class="title">{{ updated }}</span></p>
      </div>

      <div class="row">
      <p class="category">
      <span class="title">{{ data.buyerEmail }}</span></p>
      <p class="category" v-if="data.comments">{{ $t('comments') }}:
      <strong class="title">"{{ data.comments }}"</strong></p>
      </div>
      <div v-if="data.status == 'done'">
      <p class="category">{{ $t('alertSentAgo') }}
      <span class="title">{{ updated }}</span></p>
      </div>
      <h5 class="text-info" v-if="data.status == 'done'">
        <md-icon><img class="img cooking" :src="alert" /></md-icon> {{ $t('alerting') }} {{ data.buyerName }}
      </h5>
      </div>
    </template>

    <template slot="footer">
      <div class="md-layout-item md-size-100 md-medium-size-100">
        <collapse :open.sync="open" :collapse="[ $t('details') ]" icon="keyboard_arrow_up" 
        color-collapse="rose">
          <template slot="md-collapse-pane-1">
            <md-table>
              <md-table-row slot="md-table-row" v-for="item in data.products" :key="item.id" md-auto-select>
                <md-table-cell class="tab-column">
                  <span> {{ item.product.name }}</span> x <strong>{{ item.quantity }}</strong>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </template>
        </collapse>

        <md-button @click="changeStatus('pending')" class="md-warning" v-if="data.status == 'ongoing'">
          <md-icon class="material-icons">reply</md-icon>
        </md-button>
        <md-button @click="changeStatus('done')" class="md-warning" v-if="data.status == 'payment'">
          <md-icon class="material-icons">reply</md-icon>
        </md-button>
        <md-button @click="changeStatus('ongoing')" class="md-warning" v-if="data.status == 'done' || data.status == 'cancelled' ">
          <md-icon class="material-icons">reply</md-icon>
        </md-button>
        <md-button class="md-simple md-danger" @click="changeStatus('cancelled')"  v-if="data.status != 'cancelled' && data.status != 'close'">
          <md-icon>close</md-icon> {{ $t('cancelOrder') }}
          <md-tooltip md-direction="top">{{ $t('cancel') }}</md-tooltip>
        </md-button>

      <p class="category">{{ $t('createdAgo') }}
      <span class="title">{{ created }}</span></p>
      </div>
    </template>
  </stats-card>
</template>

<script>
import AsyncWorldMap from "@/components/WorldMap/AsyncWorldMap.vue";
import { updateAction } from "@/pages/Orders/actions";
import { Collapse, StatsCard } from "@/components";

export default {
  name: "order-card",
  components: {
    StatsCard,
    Collapse
  },
  computed: {
    currencySymbol() {
      const selectedCurrency = this.$currencies.find(option => option.value === this.currency);
      return selectedCurrency ? selectedCurrency.symbol : '';
    },
  },
  props: {
    currency: {
      type: String,
      default: '$'
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
      currentTime: new Date() // Mantiene la hora actual para actualizar dinámicamente
    },
  },
  beforeDestroy() {
    // Limpia el intervalo cuando se destruya el componente
    clearInterval(this.interval);
  },
  mounted() {
    // Actualiza la hora actual cada segundo
    this.interval = setInterval(this.updateTime, 1000);
  },
  created() {
    this.payed = this.data.payed
    this.status = this.data.status;
    this.updateTime()
  },
  data() {
    return {
      loading:false,
      payed:false,
      open: false,
      status: 'pending',
      alert: './img/alert.gif',
      created:'',
      updated:''
    };
  },
  methods: {
    async updateValuePaid() {
      let info = {}
      info.id = this.data._id;
      info.payed = this.payed;
      await updateAction(info);
    },
    updateTime() {
      this.currentTime = new Date();
      this.created = this.timeSinceCreation(this.data.createdAt);
      this.updated = this.timeSinceCreation(this.data.updatedAt);
    },
    timeSinceCreation(date) {
        const now = this.currentTime;
        const createdAt = new Date(date);
        const diffInMs = now - createdAt;
        const diffInSeconds = Math.floor(diffInMs / 1000);
        const diffInMinutes = Math.floor(diffInMs / 1000 / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);

        if (diffInMinutes < 60) {
          return `${diffInMinutes} min.`;
        } else {
        return `${diffInHours} horas, ${diffInMinutes % 60} min.`;
        }
    },
    formatDate(value) {
      if (!value) return '';
      const date = new Date(value);
      return date.toLocaleString(); // Puedes personalizar el formato según tus necesidades
    },
    async changeStatus(status) {
      this.loading=true;
      this.status = status;
      let info = {}
      info.id = this.data._id;
      info.status = status;
      await updateAction(info);
      this.$emit("change", this.data, status);
      this.loading=false;
    }
  }
};
</script>

<style>
 .align-left{
  text-align:left;
 }
 .ticket {
  position: relative;
  background-color: #f3f3f3;
  border: 10px solid transparent;
   transform: rotate(-1deg);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2); /* Sombra para el efecto de papel */
}
.ticket:hover{
}


</style>

