import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";


export const ingressByUserAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/access/ingress-by-user`);

    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const ingressByDayAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/access/ingress-by-day`);

    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};