import { useAuthStore } from "../stores/auth.store";
import { AuthStatus } from "../interfaces";


export function isAuthenticatedGuard(to, from, next) {
  const isLoggedIn = !!localStorage.getItem('token'); // Ejemplo de autenticación

  if (isLoggedIn) {
    if (to.path === from.path) {
      next(false); // Evita la navegación
    } else {
      next(); // Permite la navegación
    }
  } else {
    const orderPathPattern = /^\/order\/\d+$/; // Patrón para coincidir con /order/ seguido de un número
    if (orderPathPattern.test(to.path)) {
      next(); // Permite la navegación
    } else {
      next('/login'); 
    }
  }
}

export default isAuthenticatedGuard;
