<template>
  <div>
    <md-button class="w-100 md-button md-primary" @click="open=true" v-if="!open">
      + Agregar Elemento
    </md-button>
    <md-table   
    v-if="!open"
      v-model="elements"
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      class="paginated-table table-striped table-hover"
    >
      <md-table-row 
        slot="md-table-row"  
        slot-scope="{ item }"
      >
        <!-- Columna Nombre -->
        <md-table-cell :md-label="$t('name')" md-sort-by="name">
          <img v-if="item.image" :src="item.image" width="30px" alt="Element Image" class="element-image" />
          <span>{{ item.name }}</span>
        </md-table-cell>

        <!-- Columna Body Zones -->
        <md-table-cell :md-label="$t('description')">
            {{ item.description }}
  
        </md-table-cell>

        <!-- Columna de Acciones -->
        <md-table-cell>
          <md-button class="md-primary md-icon" @click="editElement(item);open=true">
            <md-icon>edit</md-icon>
          </md-button>
          <md-button class="md-danger md-icon" @click="confirmDelete(item)">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>


    <CreateElement
      v-if="selectedElement && open"
      :key="selectedElement._id" 
      :preloadedData="selectedElement"
      @formSubmitted="onElementUpdated"
      @cancel="open=false;selectedElement=null"
    />
    <CreateElement
      v-if="!selectedElement && open"
      @formSubmitted="onElementUpdated"
      @cancel="open=false;selectedElement=null"/>
  </div>
</template>

<script>
import { getAllElementsAction, deleteElementAction } from '@/pages/Training/actions';
import CreateElement from './CreateElement.vue'; // El formulario de edición
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      elements: [],
      open: false,
      currentSort: 'name',
      currentSortOrder: 'asc',
      mobile: false, // Se puede configurar según sea necesario
      selectedElement: null // Elemento seleccionado para editar
    };
  },
  components: {
    CreateElement
  },
  async created() {
    this.start();

  },
  methods: {
    async start(){
      try {
        const response = await getAllElementsAction();
        if (response.ok) {
          this.elements = response.elements;
        } else {
          console.error('Failed to fetch elements: ', response.message);
        }
      } catch (error) {
        console.error('Error fetching elements: ', error);
      }
    },
    editElement(element) {
      this.selectedElement = element;
    },
    async confirmDelete(element) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete the element "${element.name}". This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel'
      });

      if (result.isConfirmed) {
        this.deleteElement(element._id);
      }
    },
    async deleteElement(id) {
      try {
        const response = await deleteElementAction(id);
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Element deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });
          // Recargar la lista de elementos
          this.start();
      
      } catch (error) {
        console.error('Error deleting element:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    onElementUpdated() {
      // Actualiza la lista de elementos después de la edición
      this.selectedElement = null;
      this.created(); // Recargar la lista
    }
  }
};
</script>

<style scoped>
.element-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.body-zone {
  display: inline-block;
  margin-right: 5px;
  padding: 2px 5px;
  background-color: #f0f0f0;
  border-radius: 3px;
}
</style>
