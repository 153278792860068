import { wencesApi } from "@/api/wencesApi";

// Crear un nuevo entrenamiento
export const createTrainingAction = async (formData) => {
  try {
    const { data } = await wencesApi.post("/api/training", formData);

    return {
      ok: true,
      training: data.training
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo crear el entrenamiento",
    };
  }
};

// Obtener todos los entrenamientos
export const getAllTrainingsAction = async () => {
  try {
    const { data } = await wencesApi.get("/api/training");

    return {
      ok: true,
      trainings: data
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo obtener los entrenamientos",
    };
  }
};



// Actualizar los ejercicios asociados a un Training
export const updateTrainingExercisesAction = async (trainingId, exercises) => {
  try {
    // Hacer una solicitud PUT a la API para actualizar los ejercicios de un training específico
    const { data } = await wencesApi.post(`/api/training/update/${trainingId}/exercises`, {
      exercises
    });

    return {
      ok: true,
      training: data.training // La respuesta contiene el training actualizado
    };
  } catch (error) {
    console.error('Error updating training exercises:', error);
    return {
      ok: false,
      message: "No se pudo actualizar los ejercicios del training",
    };
  }
};

export const getAllExercisesOfTrainingAction = async (trainingId) => {
  try {
    const { data } = await wencesApi.get(`/api/training/exercises/${trainingId}`);
    return {
      ok: true,
      exercises: data
    };
  } catch (error) {
    return {
      ok: false,
      message: "No se pudo obtener los ejercicios",
    };
  }
};


// Obtener todos los entrenamientos
export const getAllTrainingsOfPlanningAction = async (planningId) => {

  try {
    const { data } = await wencesApi.get(`/api/training/planning/${planningId}`);

    return {
      ok: true,
      trainings: data
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo obtener los entrenamientos",
    };
  }
};

// Obtener un entrenamiento específico por ID
export const getTrainingAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/training/${id}`);

    return {
      ok: true,
      training: data
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo obtener el entrenamiento",
    };
  }
};

// Actualizar un entrenamiento
export const updateTrainingAction = async (id,formData) => {
  try {
    const { data } = await wencesApi.put(`/api/training/${id}`, formData);

    return {
      ok: true,
      training: data.training
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo actualizar el entrenamiento",
    };
  }
};

// Eliminar un entrenamiento
export const deleteTrainingAction = async (id) => {
  try {
    await wencesApi.delete(`/api/training/${id}`);

    return {
      ok: true,
      message: "Entrenamiento eliminado exitosamente"
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo eliminar el entrenamiento",
    };
  }
};
