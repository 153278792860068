<template>
  <div class="md-layout">   
       <md-button @click="cancel()" class="md-primary md-sm"><md-icon class="material-icons">arrow_back</md-icon> 
                  {{ $t('stablishments') }} </md-button>
            
    <div class="md-layout-item md-size-100">
      <md-card>
        
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>edit</md-icon>
          </div>
            <h4 class="title">{{ stablishment ? $t('edit') : $t('addNewStablishment') }}</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 p-2">
              <label class="md-form-label">
                {{ $t('name') }}
              </label>
              <md-field>
                <md-input v-model="name" type="text" :placeholder="$t('enterName')"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100 p-2">
              <label class="md-form-label">
                {{ $t('email') }}
              </label>
              <md-field>
                <md-input v-model="email" type="email" :placeholder="$t('enterEmail')"></md-input>
              </md-field>
              <small class="text-secondary">{{$t('importantNotifications')}}</small>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100 p-2" v-if="isRestaurant()">
              <label class="md-form-label">
                {{ $t('maxOrders') }}
              </label>
              <md-field>
                <md-input v-model="limit" type="number" :placeholder="$t('maxOrders')"></md-input>
              </md-field>
              <small class="text-secondary">{{$t('maxOrdersLimit')}} ( {{ $t('kitchenStatusOrders') }} )</small>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100 p-2">
              <label class="md-form-label">
                {{ $t('Website') }}
              </label>
              <md-field>
                <md-input v-model="data.website" type="text" placeholder="https://www.myrestaurant.com"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100 p-2">
              <label class="md-form-label">
                {{ $t('Instagram') }}
              </label>
              <md-field>
                <md-input v-model="data.instagram" type="text" placeholder="https://www.instagram.com/..."></md-input>
              </md-field>
            </div>


            <div class="md-layout-item md-size-50 md-small-size-100 p-2">
              <label class="md-form-label">
                {{ $t('country') }}
              </label>
              <md-field>
                <md-select v-model="country" name="country" id="country">
                  <md-option v-for="(c,k) in countries" :key="k" :value="c.name">{{ c.name }}</md-option>
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item md-size-50 md-small-size-100 p-2">
              <label class="md-form-label">
                {{ $t('address') }}
              </label>
              <md-field>
                <md-input v-model="address" @change="geocodeAddress" type="text" :placeholder="$t('enterAddress')"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100 p-2">


          <div class="row">
            <div class="col-6">
            <label class="md-form-label">{{ $t('primary_color') }}</label>
            <md-field>
              <input type="color" v-model="primaryColor" />
            </md-field>
            </div>
            <div class="col-6">
            <label class="md-form-label">{{ $t('secondary_color') }}</label>
            <md-field>
              <input type="color" v-model="secondaryColor" />
            </md-field>  
            </div> 
          </div>
            </div>


            <div class="md-layout-item md-size-50 md-small-size-100 p-2">
              <label class="md-form-label">
                {{ $t('phone') }}
              </label>
              <md-field>
                <md-input v-model="phoneNumber" :placeholder="$t('enterPhone')"></md-input>
              </md-field>
            </div>

            <div class="md-layout-item md-size-50 md-small-size-100 p-2">


              <div class="file-input">
                <label class="md-form-label">
                  {{ $t('logo') }}
                </label>
                <div class="button-container">
                  <md-button class="md-danger md-sm md-round" @click="removeLogo" v-if="logo">
                    <i class="fa fa-times"></i>{{ $t('remove') }}
                  </md-button>
                  <md-button class="md-success md-sm md-round md-fileinput">
                    <template v-if="!logo">{{ $t('selectImage') }}</template>
                    <template v-else>{{ $t('change') }}</template>
                    <input type="file" accept=".png, .jpg, .jpeg"  @change="onLogoChange" />
                  </md-button>
                </div>
                <div v-if="!logo">
                  <div>
                    <img :src="logo" title="" />
                  </div>
                </div>
                <div v-else>
                  <img :src="logo" />
                </div>
              </div>      
               <div class="file-input float-right">
                <label class="md-form-label">
                  {{ $t('coverImage') }}
                </label>
                <div class="button-container">
                  <md-button class="md-danger md-sm md-round" @click="removeCoverImage" v-if="coverImage">
                    <i class="fa fa-times"></i>{{ $t('remove') }}
                  </md-button>
                  <md-button class="md-success md-sm md-round md-fileinput">
                    <template v-if="!coverImage">{{ $t('selectImage') }}</template>
                    <template v-else>{{ $t('change') }}</template>
                    <input type="file" accept=".png, .jpg, .jpeg" @change="onCoverImageChange" />
                  </md-button>
                </div>
                <div>
                  <img :src="coverImage" />
                </div>
              </div>

            </div>



            <div class="md-layout-item md-size-50 md-small-size-100 p-2">
              <label class="md-form-label">
                {{ $t('location') }}
              </label>
              <l-map 
                style="height: 300px; width: 100%" 
                :zoom="13" 
                :center="mapCenter" 
                @click="updateLocation"
              >
                <l-tile-layer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
                ></l-tile-layer>
                <l-marker :icon="customIcon"  :lat-lng="location" :draggable="true" @moveend="onMarkerMove"></l-marker>
              </l-map>
            </div>
          </div>


          <div class="md-layout">
            <md-button class="md-primary md-sm" @click="save">{{ stablishment ? $t('save') : $t('add') }}</md-button>
            <md-button class="md-danger md-sm ml-2" @click="cancel">{{  $t('cancel') }}</md-button>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import { saveAllAction,createStabAction } from "@/pages/Stablishments/actions";
import { uploadImageAction } from "@/pages/Products/actions";
import permissionsMixin from '@/mixins/permissionsMixin';

import Swal from 'sweetalert2';

export default {  
  mixins: [permissionsMixin],
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  props: {
    regularImg: {
      type: String,
      default: "./img/image_placeholder.jpg",
    },
    stablishment: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      initialHours:{
            "monday": { "open": "08:00", "close": "18:00" },
            "tuesday": { "open": "08:00", "close": "18:00" },
            "wednesday": { "open": "08:00", "close": "18:00" },
            "thursday": { "open": "08:00", "close": "18:00" },
            "friday": { "open": "08:00", "close": "22:00" },
            "saturday": { "open": "10:00", "close": "22:00" },
            "sunday": { "open": "10:00", "close": "18:00" }
          },
      data: {},
      customIcon: L.icon({
        iconUrl: './img/resto.png',
        iconSize: [50, 55],
        iconAnchor: [22, 94],
        popupAnchor: [-3, -76],
        shadowUrl: null,
        shadowSize: [68, 95],
        shadowAnchor: [22, 94]
      }),
      phoneNumber: '',
      email: '',
      address: '',
      name: '',
      country: '',
      primaryColor:'',
      secondaryColor:'',
      limit:500,
      logo: '',
      coverImage: '',
      location: [51.505, -0.09],
      mapCenter: [51.505, -0.09],
      countries: [{ code: "+93", name: "Afghanistan", flag: "🇦🇫" },
          { code: "+355", name: "Albania", flag: "🇦🇱" },
          { code: "+213", name: "Algeria", flag: "🇩🇿" },
          { code: "+376", name: "Andorra", flag: "🇦🇩" },
          { code: "+244", name: "Angola", flag: "🇦🇴" },
          { code: "+54", name: "Argentina", flag: "🇦🇷" },
          { code: "+374", name: "Armenia", flag: "🇦🇲" },
          { code: "+61", name: "Australia", flag: "🇦🇺" },
          { code: "+43", name: "Austria", flag: "🇦🇹" },
          { code: "+994", name: "Azerbaijan", flag: "🇦🇿" },
          { code: "+973", name: "Bahrain", flag: "🇧🇭" },
          { code: "+880", name: "Bangladesh", flag: "🇧🇩" },
          { code: "+375", name: "Belarus", flag: "🇧🇾" },
          { code: "+32", name: "Belgium", flag: "🇧🇪" },
          { code: "+501", name: "Belize", flag: "🇧🇿" },
          { code: "+229", name: "Benin", flag: "🇧🇯" },
          { code: "+975", name: "Bhutan", flag: "🇧🇹" },
          { code: "+591", name: "Bolivia", flag: "🇧🇴" },
          { code: "+387", name: "Bosnia and Herzegovina", flag: "🇧🇦" },
          { code: "+267", name: "Botswana", flag: "🇧🇼" },
          { code: "+55", name: "Brazil", flag: "🇧🇷" },
          { code: "+246", name: "British Indian Ocean Territory", flag: "🇮🇴" },
          { code: "+673", name: "Brunei", flag: "🇧🇳" },
          { code: "+359", name: "Bulgaria", flag: "🇧🇬" },
          { code: "+226", name: "Burkina Faso", flag: "🇧🇫" },
          { code: "+257", name: "Burundi", flag: "🇧🇮" },
          { code: "+855", name: "Cambodia", flag: "🇰🇭" },
          { code: "+237", name: "Cameroon", flag: "🇨🇲" },
          { code: "+1", name: "Canada", flag: "🇨🇦" },
          { code: "+238", name: "Cape Verde", flag: "🇨🇻" },
          { code: "+236", name: "Central African Republic", flag: "🇨🇫" },
          { code: "+235", name: "Chad", flag: "🇹🇩" },
          { code: "+56", name: "Chile", flag: "🇨🇱" },
          { code: "+86", name: "China", flag: "🇨🇳" },
          { code: "+57", name: "Colombia", flag: "🇨🇴" },
          { code: "+269", name: "Comoros", flag: "🇰🇲" },
          { code: "+242", name: "Congo", flag: "🇨🇬" },
          { code: "+243", name: "Congo, Democratic Republic of the", flag: "🇨🇩" },
          { code: "+506", name: "Costa Rica", flag: "🇨🇷" },
          { code: "+385", name: "Croatia", flag: "🇭🇷" },
          { code: "+53", name: "Cuba", flag: "🇨🇺" },
          { code: "+357", name: "Cyprus", flag: "🇨🇾" },
          { code: "+420", name: "Czech Republic", flag: "🇨🇿" },
          { code: "+45", name: "Denmark", flag: "🇩🇰" },
          { code: "+253", name: "Djibouti", flag: "🇩🇯" },
          { code: "+1", name: "Dominica", flag: "🇩🇲" },
          { code: "+1", name: "Dominican Republic", flag: "🇩🇴" },
          { code: "+593", name: "Ecuador", flag: "🇪🇨" },
          { code: "+20", name: "Egypt", flag: "🇪🇬" },
          { code: "+503", name: "El Salvador", flag: "🇸🇻" },
          { code: "+240", name: "Equatorial Guinea", flag: "🇬🇶" },
          { code: "+291", name: "Eritrea", flag: "🇪🇷" },
          { code: "+372", name: "Estonia", flag: "🇪🇪" },
          { code: "+251", name: "Ethiopia", flag: "🇪🇹" },
          { code: "+500", name: "Falkland Islands", flag: "🇫🇰" },
          { code: "+298", name: "Faroe Islands", flag: "🇫🇴" },
          { code: "+679", name: "Fiji", flag: "🇫🇯" },
          { code: "+358", name: "Finland", flag: "🇫🇮" },
          { code: "+33", name: "France", flag: "🇫🇷" },
          { code: "+594", name: "French Guiana", flag: "🇬🇫" },
          { code: "+689", name: "French Polynesia", flag: "🇵🇫" },
          { code: "+241", name: "Gabon", flag: "🇬🇦" },
          { code: "+220", name: "Gambia", flag: "🇬🇲" },
          { code: "+995", name: "Georgia", flag: "🇬🇪" },
          { code: "+49", name: "Germany", flag: "🇩🇪" },
          { code: "+233", name: "Ghana", flag: "🇬🇭" },
          { code: "+350", name: "Gibraltar", flag: "🇬🇮" },
          { code: "+30", name: "Greece", flag: "🇬🇷" },
          { code: "+299", name: "Greenland", flag: "🇬🇱" },
          { code: "+1", name: "Grenada", flag: "🇬🇩" },
          { code: "+590", name: "Guadeloupe", flag: "🇬🇵" },
          { code: "+1", name: "Guam", flag: "🇬🇺" },
          { code: "+502", name: "Guatemala", flag: "🇬🇹" },
          { code: "+224", name: "Guinea", flag: "🇬🇳" },
          { code: "+245", name: "Guinea-Bissau", flag: "🇬🇼" },
          { code: "+592", name: "Guyana", flag: "🇬🇾" },
          { code: "+509", name: "Haiti", flag: "🇭🇹" },
          { code: "+504", name: "Honduras", flag: "🇭🇳" },
          { code: "+852", name: "Hong Kong", flag: "🇭🇰" },
          { code: "+36", name: "Hungary", flag: "🇭🇺" },
          { code: "+354", name: "Iceland", flag: "🇮🇸" },
          { code: "+91", name: "India", flag: "🇮🇳" },
          { code: "+62", name: "Indonesia", flag: "🇮🇩" },
          { code: "+98", name: "Iran", flag: "🇮🇷" },
          { code: "+964", name: "Iraq", flag: "🇮🇶" },
          { code: "+353", name: "Ireland", flag: "🇮🇪" },
          { code: "+972", name: "Israel", flag: "🇮🇱" },
          { code: "+39", name: "Italy", flag: "🇮🇹" },
          { code: "+225", name: "Ivory Coast", flag: "🇨🇮" },
          { code: "+1", name: "Jamaica", flag: "🇯🇲" },
          { code: "+81", name: "Japan", flag: "🇯🇵" },
          { code: "+962", name: "Jordan", flag: "🇯🇴" },
          { code: "+7", name: "Kazakhstan", flag: "🇰🇿" },
          { code: "+254", name: "Kenya", flag: "🇰🇪" },
          { code: "+686", name: "Kiribati", flag: "🇰🇮" },
          { code: "+965", name: "Kuwait", flag: "🇰🇼" },
          { code: "+996", name: "Kyrgyzstan", flag: "🇰🇬" },
          { code: "+856", name: "Laos", flag: "🇱🇦" },
          { code: "+371", name: "Latvia", flag: "🇱🇻" },
          { code: "+961", name: "Lebanon", flag: "🇱🇧" },
          { code: "+266", name: "Lesotho", flag: "🇱🇸" },
          { code: "+231", name: "Liberia", flag: "🇱🇷" },
          { code: "+218", name: "Libya", flag: "🇱🇾" },
          { code: "+423", name: "Liechtenstein", flag: "🇱🇮" },
          { code: "+370", name: "Lithuania", flag: "🇱🇹" },
          { code: "+352", name: "Luxembourg", flag: "🇱🇺" },
          { code: "+853", name: "Macau", flag: "🇲🇴" },
          { code: "+389", name: "Macedonia", flag: "🇲🇰" },
          { code: "+261", name: "Madagascar", flag: "🇲🇬" },
          { code: "+265", name: "Malawi", flag: "🇲🇼" },
          { code: "+60", name: "Malaysia", flag: "🇲🇾" },
          { code: "+960", name: "Maldives", flag: "🇲🇻" },
          { code: "+223", name: "Mali", flag: "🇲🇱" },
          { code: "+356", name: "Malta", flag: "🇲🇹" },
          { code: "+692", name: "Marshall Islands", flag: "🇲🇭" },
          { code: "+596", name: "Martinique", flag: "🇲🇶" },
          { code: "+222", name: "Mauritania", flag: "🇲🇷" },
          { code: "+230", name: "Mauritius", flag: "🇲🇺" },
          { code: "+262", name: "Mayotte", flag: "🇾🇹" },
          { code: "+52", name: "Mexico", flag: "🇲🇽" },
          { code: "+691", name: "Micronesia", flag: "🇫🇲" },
          { code: "+373", name: "Moldova", flag: "🇲🇩" },
          { code: "+377", name: "Monaco", flag: "🇲🇨" },
          { code: "+976", name: "Mongolia", flag: "🇲🇳" },
          { code: "+382", name: "Montenegro", flag: "🇲🇪" },
          { code: "+212", name: "Morocco", flag: "🇲🇦" },
          { code: "+258", name: "Mozambique", flag: "🇲🇿" },
          { code: "+95", name: "Myanmar", flag: "🇲🇲" },
          { code: "+264", name: "Namibia", flag: "🇳🇦" },
          { code: "+674", name: "Nauru", flag: "🇳🇷" },
          { code: "+977", name: "Nepal", flag: "🇳🇵" },
          { code: "+31", name: "Netherlands", flag: "🇳🇱" },
          { code: "+687", name: "New Caledonia", flag: "🇳🇨" },
          { code: "+64", name: "New Zealand", flag: "🇳🇿" },
          { code: "+505", name: "Nicaragua", flag: "🇳🇮" },
          { code: "+227", name: "Niger", flag: "🇳🇪" },
          { code: "+234", name: "Nigeria", flag: "🇳🇬" },
          { code: "+683", name: "Niue", flag: "🇳🇺" },
          { code: "+672", name: "Norfolk Island", flag: "🇳🇫" },
          { code: "+850", name: "North Korea", flag: "🇰🇵" },
          { code: "+47", name: "Norway", flag: "🇳🇴" },
          { code: "+968", name: "Oman", flag: "🇴🇲" },
          { code: "+92", name: "Pakistan", flag: "🇵🇰" },
          { code: "+680", name: "Palau", flag: "🇵🇼" },
          { code: "+970", name: "Palestine", flag: "🇵🇸" },
          { code: "+507", name: "Panama", flag: "🇵🇦" },
          { code: "+675", name: "Papua New Guinea", flag: "🇵🇬" },
          { code: "+595", name: "Paraguay", flag: "🇵🇾" },
          { code: "+51", name: "Peru", flag: "🇵🇪" },
          { code: "+63", name: "Philippines", flag: "🇵🇭" },
          { code: "+48", name: "Poland", flag: "🇵🇱" },
          { code: "+351", name: "Portugal", flag: "🇵🇹" },
          { code: "+1", name: "Puerto Rico", flag: "🇵🇷" },
          { code: "+974", name: "Qatar", flag: "🇶🇦" },
          { code: "+262", name: "Reunion", flag: "🇷🇪" },
          { code: "+40", name: "Romania", flag: "🇷🇴" },
          { code: "+7", name: "Russia", flag: "🇷🇺" },
          { code: "+250", name: "Rwanda", flag: "🇷🇼" },
          { code: "+590", name: "Saint Barthelemy", flag: "🇧🇱" },
          { code: "+290", name: "Saint Helena", flag: "🇸🇭" },
          { code: "+1", name: "Saint Kitts and Nevis", flag: "🇰🇳" },
          { code: "+1", name: "Saint Lucia", flag: "🇱🇨" },
          { code: "+590", name: "Saint Martin", flag: "🇲🇫" },
          { code: "+508", name: "Saint Pierre and Miquelon", flag: "🇵🇲" },
          { code: "+1", name: "Saint Vincent and the Grenadines", flag: "🇻🇨" },
          { code: "+685", name: "Samoa", flag: "🇼🇸" },
          { code: "+378", name: "San Marino", flag: "🇸🇲" },
          { code: "+239", name: "Sao Tome and Principe", flag: "🇸🇹" },
          { code: "+966", name: "Saudi Arabia", flag: "🇸🇦" },
          { code: "+221", name: "Senegal", flag: "🇸🇳" },
          { code: "+381", name: "Serbia", flag: "🇷🇸" },
          { code: "+248", name: "Seychelles", flag: "🇸🇨" },
          { code: "+232", name: "Sierra Leone", flag: "🇸🇱" },
          { code: "+65", name: "Singapore", flag: "🇸🇬" },
          { code: "+1", name: "Sint Maarten", flag: "🇸🇽" },
          { code: "+421", name: "Slovakia", flag: "🇸🇰" },
          { code: "+386", name: "Slovenia", flag: "🇸🇮" },
          { code: "+677", name: "Solomon Islands", flag: "🇸🇧" },
          { code: "+252", name: "Somalia", flag: "🇸🇴" },
          { code: "+27", name: "South Africa", flag: "🇿🇦" },
          { code: "+82", name: "South Korea", flag: "🇰🇷" },
          { code: "+211", name: "South Sudan", flag: "🇸🇸" },
          { code: "+34", name: "Spain", flag: "🇪🇸" },
          { code: "+94", name: "Sri Lanka", flag: "🇱🇰" },
          { code: "+249", name: "Sudan", flag: "🇸🇩" },
          { code: "+597", name: "Suriname", flag: "🇸🇷" },
          { code: "+47", name: "Svalbard and Jan Mayen", flag: "🇸🇯" },
          { code: "+268", name: "Swaziland", flag: "🇸🇿" },
          { code: "+46", name: "Sweden", flag: "🇸🇪" },
          { code: "+41", name: "Switzerland", flag: "🇨🇭" },
          { code: "+963", name: "Syria", flag: "🇸🇾" },
          { code: "+886", name: "Taiwan", flag: "🇹🇼" },
          { code: "+992", name: "Tajikistan", flag: "🇹🇯" },
          { code: "+255", name: "Tanzania", flag: "🇹🇿" },
          { code: "+66", name: "Thailand", flag: "🇹🇭" },
          { code: "+228", name: "Togo", flag: "🇹🇬" },
          { code: "+690", name: "Tokelau", flag: "🇹🇰" },
          { code: "+676", name: "Tonga", flag: "🇹🇴" },
          { code: "+1", name: "Trinidad and Tobago", flag: "🇹🇹" },
          { code: "+216", name: "Tunisia", flag: "🇹🇳" },
          { code: "+90", name: "Turkey", flag: "🇹🇷" },
          { code: "+993", name: "Turkmenistan", flag: "🇹🇲" },
          { code: "+1", name: "Turks and Caicos Islands", flag: "🇹🇨" },
          { code: "+688", name: "Tuvalu", flag: "🇹🇻" },
          { code: "+256", name: "Uganda", flag: "🇺🇬" },
          { code: "+380", name: "Ukraine", flag: "🇺🇦" },
          { code: "+971", name: "United Arab Emirates", flag: "🇦🇪" },
          { code: "+44", name: "United Kingdom", flag: "🇬🇧" },
          { code: "+1", name: "United States", flag: "🇺🇸" },
          { code: "+598", name: "Uruguay", flag: "🇺🇾" },
          { code: "+998", name: "Uzbekistan", flag: "🇺🇿" },
          { code: "+678", name: "Vanuatu", flag: "🇻🇺" },
          { code: "+379", name: "Vatican City", flag: "🇻🇦" },
          { code: "+58", name: "Venezuela", flag: "🇻🇪" },
          { code: "+84", name: "Vietnam", flag: "🇻🇳" },
          { code: "+681", name: "Wallis and Futuna", flag: "🇼🇫" },
          { code: "+967", name: "Yemen", flag: "🇾🇪" },
          { code: "+260", name: "Zambia", flag: "🇿🇲" },
          { code: "+263", name: "Zimbabwe", flag: "🇿🇼" }],
      selectedLogoFile: null,
      selectedCoverImageFile: null,
    };
  },
  methods: {
    cancel(){
      this.$emit('cancel')
    },
    save() {
          if(this.isDemo()){
            return;
          }
      const stablishmentData = {
        id: this.stablishment._id,
        name: this.name,
        email: this.email,
        address: this.address,
        primaryColor: this.primaryColor,
        secondaryColor: this.secondaryColor,
        country: this.country,
        phoneNumber: this.phoneNumber,
        logo: this.logo,
        coverImage: this.coverImage,
        location: this.location.join(','),
        instagram: this.data.instagram,
        website: this.data.website,
        limit: this.limit
      };

       Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('doYouWantToSaveAllChanges'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yesSaveIt')
      }).then((result) => {
        if (result.isConfirmed) {

          this.saveNow(stablishmentData);
          
        }
      })
    },
    async saveNow(stablishmentData){
          let imageUrl = '';
          if (this.selectedCoverImageFile) {
            const response = await uploadImageAction(this.selectedCoverImageFile);

            if (response.ok) {
              imageUrl = response.data.data.Location; // URL de la imagen subida
              stablishmentData.coverImage = imageUrl;
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.$t('errorUploadingImage'),
              });
              return;
            }
          }
          if (this.selectedLogoFile) {
            const response = await uploadImageAction(this.selectedLogoFile);
            if (response.ok) {
              imageUrl = response.data.data.Location; // URL de la imagen subida
              stablishmentData.logo = imageUrl;
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.$t('errorUploadingImage'),
              });
              return;
            }
          }
          if(stablishmentData.id == undefined){
            await createStabAction(stablishmentData);
          }else{
            await saveAllAction(stablishmentData);
          }
          Swal.fire(
            this.$t('saved'),
            this.$t('yourChangesHaveBeenSaved'),
            'success'
          ).then((result) => {
       
                this.$emit('save')
          }) 
    },
    onLogoChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.selectedLogoFile = files[0];
      this.createImagePreview(this.selectedLogoFile, 'logo');
    },
    onCoverImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.selectedCoverImageFile = files[0];
      this.createImagePreview(this.selectedCoverImageFile, 'coverImage');
    },
    createImagePreview(file, type) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (type === 'logo') {
          this.logo = e.target.result;
        } else if (type === 'coverImage') {
          this.coverImage = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeLogo() {
      this.logo = "";
      this.selectedLogoFile = null;
    },
    removeCoverImage() {
      this.coverImage = "";
      this.selectedCoverImageFile = null;
    },
    updateLocation(event) {
      this.location = [event.latlng.lat, event.latlng.lng];
    },
    onMarkerMove(event) {
      this.location = [event.target.getLatLng().lat, event.target.getLatLng().lng];
    },
    async geocodeAddress() {
      if (!this.address) return;

      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: this.address+', '+this.country,
          format: 'json',
          addressdetails: 1,
          limit: 1,
        },
      });

      if (response.data && response.data.length > 0) {
        const { lat, lon } = response.data[0];
        this.location = [parseFloat(lat), parseFloat(lon)];
        this.mapCenter = [parseFloat(lat), parseFloat(lon)];
      }
    },
    initializeForm() {
      if (this.stablishment) {
        this.name = this.stablishment.name || '';
        this.email = this.stablishment.email || '';
        this.limit = this.stablishment.limit || 100;
        this.address = this.stablishment.address || '';
        this.primaryColor = this.stablishment.primaryColor || '#333';
        this.secondaryColor = this.stablishment.secondaryColor || '#d6d6d6';
        this.country = this.stablishment.country || '';
        this.phoneNumber = this.stablishment.phoneNumber || '';
        this.logo = this.stablishment.logo || this.regularImg;
        this.coverImage = this.stablishment.coverImage || this.regularImg;
        this.data.instagram = this.stablishment.instagram;
        this.data.website = this.stablishment.website;

        if (this.stablishment.location) {
          const [lat, lng] = this.stablishment.location.split(',').map(Number);
          this.location = [lat, lng];
          this.mapCenter = [lat, lng];
        }
      }
    }
  },
  mounted() {
    this.initializeForm();
  }
};
</script>

<style lang="scss" scoped>
.md-card img {
  width: auto !important;
  height: 100px;
}
</style>
