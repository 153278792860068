<template>
 <div>


  <PlanningGrid/>

       
  </div>
</template>

<script>
import permissionsMixin from '@/mixins/permissionsMixin';
import {PlanningGrid } from "@/pages/Training";



export default {
  mixins: [permissionsMixin],
  data() {
    return {
    };
  },
  components: {
    PlanningGrid
  },
  async created() {
  
  },
  methods:{
 
  
  },
};
</script>

