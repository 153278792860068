import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";

export const createAction = async (products,menuId,totalPrice,comments,buyerName,buyerEmail,buyerPhone,stablishment,qrId,token) => {
  try {
    const { data } = await wencesApi.post("/api/orders/create", {
      products: products,
      menuId: menuId,
      totalPrice: totalPrice,
      comments: comments,
      buyerName: buyerName,
      buyerEmail: buyerEmail,
      buyerPhone: buyerPhone,
      stablishment:stablishment,
      qr:qrId,
      notificationToken:token
    });

    return {
      ok: true,
      orderId: data.orderId
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const deleteAction = async (orders) => {
  try {
    const { data } = await wencesApi.post("/api/orders/delete", {
      orders
    });

    return {
      ok: true,
      data: data
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Usuario no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const createAccessAction = async (clientId,qrId,stablishment) => {
  try {
    const { data } = await wencesApi.post("/api/access/createaccess", {
      clientId,
      qr:qrId,
      stablishment
    });

    return {
      ok: true,
      data: data
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Usuario no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const updateAction = async (order) => {
  try {
    const { data } = await wencesApi.put(`/api/orders/${order.id}/edit`, order);

    return {
      ok: true,
      orderId: data.orderId
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const createReceiptAction = async (info) => {
  try {
    const { data } = await wencesApi.post("/api/receipts/create", info);

    return {
      ok: true
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getAllReceiptsAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/receipts`);

    return {
      ok: true,
      receipts: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getAllOrdersAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/orders`);

    return {
      ok: true,
      orders: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getAllAccessAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/access/clientaccess`);

    return {
      ok: true,
      orders: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};



export const getClientPaymentsAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/orders/clientpayments`);

    return {
      ok: true,
      orders: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getAllOrdersTodayAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/access/today`);

    return {
      ok: true,
      orders: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getLastOrdersAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/orders/lastorders`);

    return {
      ok: true,
      orders: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getLastAccessAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/access/lastorders`);

    return {
      ok: true,
      orders: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};




export const getCredits = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/orders/credits`);

    return {
      ok: true,
      credits: data.total,
      lastPayment: data.createdAt,
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getAccessAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/access/${id}/details`);

    return {
      ok: true,
      order: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/orders/${id}/details`);

    return {
      ok: true,
      order: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};



export const getAllAction = async (id, statuses = null) => {
  try {
    let url = `/api/stablishments/${id}/orders`;
    if (statuses && statuses.length > 0) {
      const statusParams = statuses.map(status => `status=${status}`).join('&');
      url += `?${statusParams}`;
    }

    const { data } = await wencesApi.get(url);

    return {
      ok: true,
      orders: data.orders,
      stablishmentName: data.stablishmentName,
      result: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};
