<template>
  <FormValidate
    :inputs="formInputs"
    :requiredFields="['name', 'bodyZones']"
    buttonLabel="Save Element"
    :preloadedData="preloadedData"
    @formSubmitted="handleFormSubmit"
    @cancel="cancel()"
  />
</template>

<script>
import FormValidate from '@/components/FormValidate.vue';
import { createElementAction, updateElementAction } from '@/pages/Training/actions';
import Swal from "sweetalert2";

export default {
  name: "CreateElement",
  components: {
    FormValidate
  },
  props: {
    preloadedData: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      formInputs: [
        { name: 'name', label: 'Element Name', type: 'text' },
        { name: 'description', label: 'Description', type: 'textarea' },
        { name: 'image', label: 'Image URL', type: 'image' }
      ],
      title: this.$t('create')
    };
  },
  watch: {
    preloadedData: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.loadPreloadedData(newValue);
        }
      }
    }
  },
  methods: {
    cancel(){
      this.$emit("cancel")
    },
    created(){
    },
    loadPreloadedData(data) {
      // Asigna los valores del elemento a editar al formulario
      this.formInputs = this.formInputs.map(input => {
        if (data[input.name]) {
          input.value = data[input.name];
        }
        return input;
      });
    },
    async handleFormSubmit(formData) {
      try {
        let response;
        if (this.preloadedData && this.preloadedData._id) {
        this.title = this.$t('edit');
          // Si hay preloadedData, estamos editando un elemento existente
          response = await updateElementAction(
            this.preloadedData._id,
            formData
          );
        } else {
          // Si no hay preloadedData, estamos creando un nuevo elemento
          response = await createElementAction(
            formData.name,
            formData.description,
            formData.bodyZone,
            formData.image
          );
        }

        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: this.preloadedData ? 'Element updated successfully' : 'Element created successfully',
            timer: 2000,
            showConfirmButton: false
          });
          this.$emit('formSubmitted');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to save element: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error saving element:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    }
  }
};
</script>
