<template>
  <div>
   <EmailForm/>
  </div>
</template>
<script>
import { EmailForm } from "@/components/Emails";


export default {
  components: {
    EmailForm,
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    submit() {
      alert("Form has been submitted!");
    },
  },
};
</script>
