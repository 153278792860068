<template>
  <div>
 
        <MercadoPago class="mt-2 p-2" v-if="stablishment.businessName.mercadoPago && isClientGym() && item.status == 'pending'" :orderId="item._id"></MercadoPago> 
        
        <div class="w-100 my-2" v-if="stablishment.businessName.paymentLink && stablishment.businessName.mercadoPago">o</div>

        <div v-if="stablishment.businessName.paymentLink"  class="w-100 pb-2">
          <span>Subir comprobante de pago:</span>
        <div class="w-100 p-2">

        <input type="file" v-if="!screenImages" accept=".png, .jpg, .jpeg, .pdf" @change="onImagesChange" />
        </div>
        <button v-if="screenCapture.length && !screenImages" class="btn btn-primary btn-sm" @click="uploadScreenCapture()"  >
         <md-icon class="text-white">upload</md-icon>
          Subir archivo    <md-progress-spinner v-if="isUploading"
                            :md-diameter="20" 
                            md-mode="indeterminate" 
                            class="text-red bg-red md-red">
                          </md-progress-spinner>
        </button>   
      </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import MercadoPago from "@/pages/Dashboard/Components/MercadoPago.vue";
import { uploadImageAction } from "@/pages/Products/actions";

import permissionsMixin from '@/mixins/permissionsMixin';

import { confirmPaymentOrderAction,payClientAction,onePayAction } from "@/pages/MercadoPago/actions";
import { getProfileStablishmentsAction } from "@/pages/Stablishments/actions";


import { getAllOrdersAction, getClientPaymentsAction, deleteAction, createReceiptAction } from "@/pages/Orders/actions"; // Asegúrate de tener la acción deleteAction importada
import { format } from 'date-fns';


export default {
  mixins: [permissionsMixin],
  components:{
    MercadoPago
  },
  props:{
    stablishment: Object,
    item: Object
  },
  created() {

  },
  data() {
    return {
      imageUrl:false,
      selectedImageFiles:[],
      screenCapture:[],
      isUploading:false,
      screenImages:false
    };
  },
  methods: {
    onImagesChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.selectedImageFiles = files; // Convertimos los archivos en un array
      this.createImagePreviews(this.selectedImageFiles);
    },
    createImagePreviews(files) {
       const file = files[0]
        if (file.type === 'application/pdf') {
          // Si es PDF, agregar un marcador de PDF al array de menuImages
          this.screenCapture = 'pdf-icon'; // solo usamos un marcador
        } else {
          // Si no es PDF, mostrar la imagen como de costumbre
          let reader = new FileReader();
          reader.onload = (e) => {
            this.screenCapture = e.target.result; // Agregamos cada imagen al array
          };
          reader.readAsDataURL(file);
        }
      
    },
    async uploadImage(file){
      const response = await uploadImageAction(file);
      if (response.ok) {
        let imageUrl = response.data.data.Location; // URL de la imagen subida
        return imageUrl;
      }else{
        return false;
      }
    },
    async uploadScreenCapture(){
      if(this.isDemo()){
            return;
          }
       this.isUploading = true;
        
        try {
          // Subir las imágenes de forma paralela usando Promise.all
          let newImages = [];
          if (this.selectedImageFiles.length > 0) {
            newImages = await this.uploadImage(this.selectedImageFiles[0]);
            
            
            // Verificar si alguna subida falló
            if (!newImages) {
              throw new Error(this.$t('errorUploadingImage'));
            }
            // Asignar las imágenes subidas al menú
            this.screenImages = newImages;

            const params = {
                upload: true,
                screenCapture: newImages,
                orderId: this.item._id
            };
            await confirmPaymentOrderAction(params);

                  Swal.fire({
                title: this.$t('success'),
                icon: 'success',
                confirmButtonText: 'OK'
              });

              this.$emit('refresh');
    

          }

        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.message || this.$t('errorUploadingImage'),
          });
        } finally {
          this.isUploading = false;
        }
    },
  },
};
</script>

