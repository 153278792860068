<template>
  <div id="app">
    <h1>{{$t('myMap')}}</h1>
    <div class="toolbar">
      <md-button class="md-primary md-sm" @click="confirmSaveAll">{{$t('saveAll')}}</md-button>
      <md-button   
        :class="{ 'md-info': drawingWall }"
        @click="toggleDrawingWall" class="card-icon md-sm">
        <md-icon>edit</md-icon>{{$t('drawMap')}}
      </md-button>
      <md-button  @click="undoLastAction" class="card-icon md-danger md-sm">
        <md-icon>undo</md-icon>
      </md-button>
      <md-button   
        :class="{ 'md-danger': deletingWall }"
        @click="toggleDeletingWall" class="card-icon md-sm">
        <md-icon>delete</md-icon>{{$t('deleteWall')}}
      </md-button>
    </div>
    <div class="canvas-container" ref="canvasContainer">
      <canvas
        ref="canvas"
        width="1000"
        height="600"
        @mousedown="onCanvasMouseDown"
        @mousemove="onCanvasMouseMove"
        @mouseup="onCanvasMouseUp"
        :class="{ drawing: drawingWall }"
      ></canvas>
      <div
        v-for="(element, index) in elements"
        :key="index"
        :class="['element', element.type]"
        class="border-radius"
        :style="{ left: element.x + 'px', top: element.y + 'px' }"
        @mousedown="onElementMouseDown($event, index)"
        @mousemove="onElementMouseMove"
        @mouseup="onElementMouseUp"
        @dragstart.prevent
      >
        <small> 
       <md-icon>qr_code</md-icon>{{ element.name }} </small>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { getAllQrAction, savePositions, saveMap, getMap} from "@/pages/Qrs/actions";

export default {
  name: 'App',
  data() {
    return {
      jsonData: {
        walls: []
      },
      elements: [],
      drawingWall: false,
      deletingWall: false,
      currentWall: null,
      walls: [],
      previousWalls: [],
      draggingElement: null,
      offsetX: 0,
      offsetY: 0,
      errorMessage: ""
    };
  },
  methods: {
    async getAll() {
      try {
        const response = await getAllQrAction();
        this.elements = response.qrs.map((qr, index) => ({
          _id: qr._id,
          name: qr.name,
          x: qr.x, // Posicionarlas inicialmente
          y: qr.y
        }));
      } catch (error) {
        this.errorMessage = error.message;
      }
      try {
        const response = await getMap();
        this.jsonData.walls = response.data[0].lines
        this.walls = response.data[0].lines
          this.drawWalls();
      } catch (error) {
        this.errorMessage = error.message;
      }


    },
    confirmSaveAll() {
      Swal.fire({
        title: 'Are you sure?',
        text: this.$t("doYouWantToSaveAllChanges"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, save it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveAllChanges();
          Swal.fire(
            this.$t('saved'),
            this.$t('yourChangesHaveBeenSaved'),
            'success'
          )
        }
      })
    },
    async saveAllChanges() {

      try {
        await savePositions(this.elements);
        await saveMap(this.jsonData.walls);
      } catch (error) {
        console.log(error.message);
      }
    },
    toggleDrawingWall() {
      this.drawingWall = !this.drawingWall;
      this.deletingWall = false; // Desactivar el modo de eliminación si está activado
    },
    toggleDeletingWall() {
      this.deletingWall = !this.deletingWall;
      this.drawingWall = false; // Desactivar el modo de dibujo si está activado
    },
    undoLastAction() {
      if (this.previousWalls.length > 0) {
        this.walls = this.previousWalls.pop();
        this.jsonData.walls = [...this.walls]; // Actualizar las posiciones de las líneas en jsonData
        this.drawWalls();
      }
    },
    onCanvasMouseDown(event) {
      if (this.drawingWall) {
        const rect = this.$refs.canvas.getBoundingClientRect();
        this.currentWall = { x1: event.clientX - rect.left, y1: event.clientY - rect.top, x2: null, y2: null };
      } else if (this.deletingWall) {
        this.checkWallClick(event);
      }
    },
    onCanvasMouseMove(event) {
      if (this.drawingWall && this.currentWall) {
        const rect = this.$refs.canvas.getBoundingClientRect();
        this.currentWall.x2 = event.clientX - rect.left;
        this.currentWall.y2 = event.clientY - rect.top;
        this.drawWalls();
      }
      if (this.draggingElement !== null) {
        const element = this.elements[this.draggingElement];
        const rect = this.$refs.canvasContainer.getBoundingClientRect();
        element.x = event.clientX - rect.left - this.offsetX;
        element.y = event.clientY - rect.top - this.offsetY;
      }
    },
    onCanvasMouseUp(event) {
      if (this.drawingWall && this.currentWall) {
        const rect = this.$refs.canvas.getBoundingClientRect();
        this.currentWall.x2 = event.clientX - rect.left;
        this.currentWall.y2 = event.clientY - rect.top;
        this.previousWalls.push([...this.walls]); // Guardar el estado actual antes de agregar una nueva pared
        this.walls.push(this.currentWall);
        this.jsonData.walls = [...this.walls]; // Actualizar las posiciones de las líneas en jsonData
        this.currentWall = null;
        this.drawWalls();
      }
      this.draggingElement = null;
    },
    drawWalls() {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.beginPath();
      this.walls.forEach(wall => {
        context.moveTo(wall.x1, wall.y1);
        context.lineTo(wall.x2, wall.y2);
      });
      if (this.currentWall) {
        context.moveTo(this.currentWall.x1, this.currentWall.y1);
        context.lineTo(this.currentWall.x2, this.currentWall.y2);
      }
      context.stroke();
    },
    checkWallClick(event) {
      const rect = this.$refs.canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const tolerance = 5; // Tolerancia para detectar clics en la línea
      this.previousWalls.push([...this.walls]); // Guardar el estado actual antes de eliminar una pared
      this.walls = this.walls.filter(wall => {
        const distToLine = this.pointLineDistance(x, y, wall.x1, wall.y1, wall.x2, wall.y2);
        return distToLine > tolerance;
      });
      this.jsonData.walls = [...this.walls]; // Actualizar las posiciones de las líneas en jsonData
      this.drawWalls();
    },
    pointLineDistance(px, py, x1, y1, x2, y2) {
      const A = px - x1;
      const B = py - y1;
      const C = x2 - x1;
      const D = y2 - y1;

      const dot = A * C + B * D;
      const len_sq = C * C + D * D;
      const param = len_sq !== 0 ? dot / len_sq : -1;

      let xx, yy;

      if (param < 0) {
        xx = x1;
        yy = y1;
      } else if (param > 1) {
        xx = x2;
        yy = y2;
      } else {
        xx = x1 + param * C;
        yy = y1 + param * D;
      }

      const dx = px - xx;
      const dy = py - yy;
      return Math.sqrt(dx * dx + dy * dy);
    },
    onElementMouseDown(event, index) {
      this.draggingElement = index;
      const element = this.elements[index];
      const rect = this.$refs.canvasContainer.getBoundingClientRect();
      this.offsetX = event.clientX - (rect.left + element.x);
      this.offsetY = event.clientY - (rect.top + element.y);
      event.stopPropagation();
    },
    onElementMouseMove(event) {
      if (this.draggingElement !== null) {
        const element = this.elements[this.draggingElement];
        const rect = this.$refs.canvasContainer.getBoundingClientRect();
        element.x = event.clientX - rect.left - this.offsetX;
        element.y = event.clientY - rect.top - this.offsetY;
      }
    },
    onElementMouseUp() {
      this.draggingElement = null;
    }
  },
  async mounted() {
    window.addEventListener('mouseup', this.onCanvasMouseUp);
    await this.getAll();
  },
  beforeDestroy() {
    window.removeEventListener('mouseup', this.onCanvasMouseUp);
  }
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.toolbar {
  margin-bottom: 20px;
}

.toolbar button.active {
  background-color: #007bff;
  color: white;
}

.canvas-container {
  position: relative;
  width: 1000px;
  height: 600px;
  min-width: 1000px;
  margin: 0 auto;
  border: 1px solid #ccc;
}

canvas {
  border: 1px solid #ccc;
}

canvas.drawing {
  cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAALCAYAAACp9V6wAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOElEQVQoz2NgoBAwUqifgZH6D2TQSPkfjNY0SGAcOYJIgjLglgEqR+OdIAgyDqlCKChKQynJpIVkVBAMeREJbMHYWSAAAAAElFTkSuQmCC'), auto;
}

.element {
  position: absolute;
  width: 100px; /* Increased width to fit table names */
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #000;
  cursor: move;
  user-select: none; /* Prevent text selection */
}

.element.square {
  border-radius: 0;
}

.element.round {
  border-radius: 50%;
}

.element.umbrella {
  background-color: #add8e6;
  border-radius: 50%;
}
</style>
