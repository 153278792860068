import en from './en.js';
import es from './es.js';
import fr from './fr.js';
import uk from './uk.js';
import pt from './pt.js';

export default {
  en,
  es,
  uk,
  fr,
  pt
};