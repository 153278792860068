import { wencesApi } from "@/api/wencesApi";

// Crear un nuevo UserPlanning
export const createUserPlanningAction = async (userIds, planningId) => {
  try {
    const { data } = await wencesApi.post("/api/userplanning", {
      userIds,
      planningId
    });

    return {
      ok: true,
      userPlanning: data.userPlanning
    };
  } catch (error) {
    console.error(error);
    return {
      ok: false,
      message: "No se pudo crear el UserPlanning",
    };
  }
};

// Obtener un UserPlanning por ID
export const getUserPlanningAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/userplanning/${id}`);

    return {
      ok: true,
      userPlanning: data
    };
  } catch (error) {
    console.error(error);
    return {
      ok: false,
      message: "No se pudo obtener el UserPlanning",
    };
  }
};


// Obtener un UserPlanning por ID
export const getAllUserPlanningAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/userplanning/all`);

    return {
      ok: true,
      userPlanning: data
    };
  } catch (error) {
    console.error(error);
    return {
      ok: false,
      message: "No se pudo obtener el UserPlanning",
    };
  }
};

// Obtener un UserPlanning por ID
export const getMyPlanningsAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/userplanning/myplannings`);

    return {
      ok: true,
      userPlanning: data
    };
  } catch (error) {
    console.error(error);
    return {
      ok: false,
      message: "No se pudo obtener el UserPlanning",
    };
  }
};


// Actualizar un UserPlanning
export const updateUserPlanningAction = async (id, status=null, curentSet=null, currentTraining=null) => {
  try {
    const { data } = await wencesApi.put(`/api/userplanning/${id}`, {
      status,
      curentSet,
      currentTraining
    });

    return {
      ok: true,
      userPlanning: data.userPlanning
    };
  } catch (error) {
    console.error(error);
    return {
      ok: false,
      message: "No se pudo actualizar el UserPlanning",
    };
  }
};

// Eliminar un UserPlanning por ID
export const deleteUserPlanningAction = async (id) => {
  try {
    await wencesApi.delete(`/api/userplanning/${id}`);

    return {
      ok: true,
      message: "UserPlanning eliminado correctamente"
    };
  } catch (error) {
    console.error(error);
    return {
      ok: false,
      message: "No se pudo eliminar el UserPlanning",
    };
  }
};
 