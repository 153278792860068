import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";


export const getAllCategoriesAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/categories`);

    return {
      ok: true,
      categories: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};