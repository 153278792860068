<template>
  <div class="md-layout">
    
    <div class="mb-4 md-layout-item md-size-100 md-small-size-100 w-100">
          <md-button class="md-primary md-sm" @click="$router.push({ name: 'menuCreate' })">+ Add QR to this menu</md-button>
      </div>
      
      <div  v-for="qr in qrs" :key="qr._id"
        class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 md-size-25">
        <pricing-card icon-color="icon-success">
          <md-icon slot="icon">

              <div :id="'qr-container-'+qr._id"><qrcode-vue :value="'https://'+subdomain+'/menu/'+qr._id" :size="200"></qrcode-vue></div></md-icon>


          <h3 slot="title" class="title">{{qr.name}}</h3>
        
          <md-button slot="footer"  @click="printQR('qr-container-'+qr._id)" class="md-success md-round">Imprimir</md-button>
        </pricing-card>
      </div>
   
  </div>
</template>

<script>
import QrcodeVue from 'qrcode-vue';

import {PricingCard} from "@/components";
import { getQrs } from "@/pages/Menu/actions";


export default {
  components: {
  PricingCard,
  QrcodeVue
  },
  created() {

    this.subdomain = window.location.hostname;
    this.getAllQrs();
  },
  data() {
    return {
    subdomain:false,
      qrs:[],
      product2: "./img/card-3.jpg"
    };
  },
  methods:{

    printQR(id) {
      const printContents = document.getElementById(id).innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload(); // Esto recarga la página para restaurar el contenido original
    },
    async getAllQrs() {
      try {
        const response = await getQrs(this.$route.params.id);
        this.qrs = response.qrs;
      } catch (error) {
        let errorMessage = error.message;
      console.log(errorMessage)
      }
    },
  }
};
</script>
