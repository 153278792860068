<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>receipt_long</md-icon>
          </div>
          <h4 class="title mb-4">{{$t('ticket')}}</h4>
        </md-card-header>

        <md-card-content>
          <div class="loading-container mr-2" v-if="isLoading">
            <md-progress-spinner 
              :md-diameter="80" 
              md-mode="indeterminate" 
              class="text-red bg-red md-red">
            </md-progress-spinner>
          </div>

          <md-table :class="notShowMobile()" v-model="filteredReceipts" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Select">
                <md-checkbox v-model="selectedReceipts" :value="item._id"></md-checkbox>
              </md-table-cell>   
              <md-table-cell md-label="Total">
                  <span class="badge badge-primary cursor-pointer" 
                    @click="getInvoice(item)">
                    <md-icon class="material-icons text-white">receipt_long</md-icon> 
                   
                  </span>
              </md-table-cell>
              <md-table-cell md-label="Total">
                <h5 class="">
                  {{ item.totalPrice }} 
                  <span v-if="item.currency">{{ currencySymbol(item.currency) }} 
                  </span>
                </h5>
                  <span class="badge badge-success " v-if="item.paid">
                    {{$t('yespaid')}} 
                  </span>
                  <span class="badge badge-danger " v-else>
                    {{$t('nopaid')}} 
                  </span>
              </md-table-cell>



              <md-table-cell md-label="Tablename">
                {{ item.tableNames }}
              </md-table-cell>


         
              <md-table-cell md-label="Buyer Name">
               <span class="text-primary cursor-pointer" v-for="order in item.orders"><a target="_blank" :href="'./#/order/'+order">{{order}}</a><br></span>
              </md-table-cell>

              <md-table-cell md-label="Buyer Name">
                {{ item.buyerName }}<br>
                {{ item.buyerEmail }}<br>
                {{ item.buyerPhone }}
              </md-table-cell>

              <md-table-cell md-label="Products">
                <div v-for="product in item.products" :key="product._id">
                  <span class="mr-2">{{ product.name }}</span>
                  <span class="badge-success badge">{{ product.price }} {{ currencySymbol(item.currency) }}</span> x 
                  <span class="badge bg-secondary">{{ product.quantity }}</span>
                </div>
              </md-table-cell>

              <md-table-cell md-label="Date" v-if="item.createdAt">{{ localDate(item.createdAt) }}</md-table-cell>
            </md-table-row>
          </md-table>

          <div :class="showMobile()">
            <div v-for="item in filteredReceipts" class="border-bottom border-1 p-2 mb-3 card">
              <div class="text-left">
                <md-checkbox v-model="selectedReceipts" :value="item._id"></md-checkbox>
                  <strong>{{ item.tableNames }}</strong>
                  <span class="badge badge-primary float-right cursor-pointer" 
                    @click="getInvoice(item)" >
                    <md-icon class="material-icons text-white">receipt_long</md-icon> {{$t('ticket')}}
                  </span>
                <h5>{{ item.buyerName }}</h5>
                <div><strong>{{ item.buyerEmail }}</strong></div>
                <div><small>{{ item.buyerPhone }}</small></div>
              </div>
              <div class="text-left p-2 card">
                <div v-for="product in item.products" :key="product._id">
                  <span>{{ product.name }}</span>
                  <span class="badge-success badge">{{ product.price }} {{ currencySymbol(item.currency) }}</span> x 
                  <span class="badge badge-primary">{{ product.quantity }}</span>
                </div>
                <h4 class="mt-3 ml-2">
                  {{$t('total')}}: {{ item.totalPrice }} {{ currencySymbol(item.currency) }}
                </h4>
                  <span class="badge badge-success" v-if="item.paid">
                    {{$t('yespaid')}} 
                  </span>
                  <span class="badge badge-danger " v-else>
                    {{$t('nopaid')}} 
                  </span>
              </div>         
             <div class="card p-2 mt-2"> {{$t('orders')}}
               <span class="text-primary cursor-pointer" v-for="order in item.orders"><a target="_blank" :href="'./#/order/'+order">{{order}}</a><br></span>
               </div>
              <small class="text-secondary">{{ localDate(item.createdAt) }}</small> 
            </div>

          </div>    <modal v-if="invoiceConfig">
                <template slot="body">
                  <div class="w-100 float-left"> 
                    <md-button
                      class="md-danger cursor-pointer md-just-icon md-round modal-default-button"
                      @click="invoiceConfig=false">
                      <md-icon>clear</md-icon>
                    </md-button>
                    <Invoice v-if="invoiceConfig" 
                    :invoice="invoiceConfig" 
                    :products="receiptSelected.products"
                    :tableNumber="receiptSelected.tableNames"
                    class="float-left"
                    :waiterName="false"
                    :total="receiptSelected.totalPrice"
                    :currency="receiptSelected.currency"
                    :date="receiptSelected.createdAt"

                    :edit="false" >
                    </Invoice>
                  </div>
                </template>

                <template slot="footer">
                  
                </template>
              </modal>
        </md-card-content>
      </md-card>



    </div>
  </div>
</template>

<script>
import { getAllReceiptsAction } from "@/pages/Orders/actions"; // Acción para obtener los Receipts
import { format } from 'date-fns';
import { getInvoiceConfigAction } from "@/pages/Stablishments/actions";

import {
  Invoice,
  Modal
} from "@/components";


export default {
  components:{
  Invoice,
  Modal
  },
  created() {
    this.getAll();
  },
  data() {
    return {
      isLoading: true,
      invoiceConfig:false,
      receiptSelected:false,
      receipts: [], // Lista de Receipts
      selectedReceipts: [], // IDs de los Receipts seleccionados
    };
  },
  computed: {
    // Computed para filtrar los Receipts si es necesario en el futuro
    filteredReceipts() {
      return this.receipts;
    },
  },
  methods: {
    currencySymbol(currency) {
      const selectedCurrency = this.$currencies.find(option => option.value === currency);
      return selectedCurrency ? selectedCurrency.symbol : currency;
    },
    showMobile() {
      return 'd-sm-none';
    },
    notShowMobile() {
      return 'd-none d-sm-block';
    },
    async getInvoice(item){
      this.receiptSelected = item
      this.invoiceConfig = await getInvoiceConfigAction(item.stablishment);
      if(this.invoiceConfig.invoices){
        this.invoiceConfig = this.invoiceConfig.invoices[0]
      }
    },
    localDate(utcDate) {
      const date = new Date(utcDate);
      return format(date, 'PPpp');  // Formato de fecha y hora según la hora local
    },
    async getAll() {
      try {
        const result = await getAllReceiptsAction();
        this.receipts = result.receipts;
        this.isLoading = false;
      } catch (error) {
        this.errorMessage = error.message;
      }
    }
  },
};
</script>