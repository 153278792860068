<template>
  <div class="card m-2">
            <div v-if="info.data 
            && info.data.Users 
            && info.data.Users[info.data.Users.length-1].role == 'gym'"
            class="bg-primary text-white"
            >
              <span v-if="info.data.Users[info.data.Users.length-1] ">Type: {{ info.data.Users[info.data.Users.length-1].role }}</span><br>
              Nombre:{{info.data.Users[info.data.Users.length-1].name}}<br>
              Email:{{info.data.Users[info.data.Users.length-1].email}}<br>
              Creado: {{info.data.Users[info.data.Users.length-1].createdAt}}<br>
            <span :class="info.data.Users.length > 10 ? 'badge badge-primary':''">  Quantity User: {{info.data.Users.length}} </span>
            </div>

            <div v-if="info.data 
            && info.data.Users 
            && info.data.Users[info.data.Users.length-1].role != 'gym' 
            && info.data.Users[info.data.Users.length-1].role != 'restaurant'">
              <span class="badge badge-danger">Cuenta no confirmada</span>
            </div>

              <div 
            class="bg-success text-white" v-if="info.data && info.data.Users && info.data.Users[info.data.Users.length-1].role == 'restaurant'">
                <span v-if="info.data.Users[info.data.Users.length-1] ">Type: {{ info.data.Users[info.data.Users.length-1].role }}</span><br>
                Nombre:{{info.data.Users[info.data.Users.length-1].name}}<br>
                Email:{{info.data.Users[info.data.Users.length-1].email}}<br>
                Creado: {{info.data.Users[info.data.Users.length-1].createdAt}}<br>
              <span :class="info.data.Users.length > 10 ? 'badge badge-primary':''">  Quantity User: {{info.data.Users.length}} </span>
            </div> 

     </div>  

</template>

<script>
import { getSettingsAction, getAdminAction, getDbInfoAction } from "@/pages/Auth/actions";

export default {
  data(){
    return{
        info: []
    }
  },
  props: {
      name: String
  },
  created() {
    this.open()
  },
  methods: {
    async open() {
      console.log(this.name)
      const dbInfo = await getDbInfoAction(this.name);
      this.info = dbInfo
      
    }
  }
};
</script>
