<template>
  <div>

                  <button class="btn btn-primary btn-sm" @click="suscribirse()" :disabled="isLoading">
                    <md-icon class="text-white">payment</md-icon>
                    Pagar con Mercado Pago
                    <div class="loading-container mr-2" v-if="isLoading">
                      <md-progress-spinner 
                        :md-diameter="20" 
                        md-mode="indeterminate" 
                        class="md-secondary mr-2">
                      </md-progress-spinner>
                    </div>
                  </button>
                  <span v-if="url">si no eres redireccionado has click aqui: <a :href="url" class="text-primary">Mercado Pago</a></span>
  </div>
</template>

<script>
import { payAction,payClientAction,onePayAction } from "@/pages/MercadoPago/actions";
import Swal from 'sweetalert2';


export default {
  data() {
    return {
      isLoading: false, // Estado para controlar el loading
      url: false
    };
  },
  props: {
    orderId : Number,
  },
  methods: {
    async suscribirse() {
      this.isLoading = true; // Mostrar el loading
     // Mostrar el popup para solicitar el email
      const { value: email } = await Swal.fire({
        title: 'Mercado Pago',
        text: 'Ingresa el email de tu cuenta de mercado pago para pagar',
        input: 'email',
        inputLabel: '',
        inputPlaceholder: 'Ingresa tu email',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        inputValidator: (value) => {
          if (!value) {
            return 'Debes ingresar un email válido!';
          }
        }
      });
      if(email){
        
        try {
          // Hacer la solicitud a tu API para generar la preapproval de suscripción
          const role = localStorage.getItem('role');
          let response;
          if(role == "client"){
            response = await payClientAction(email,this.orderId);
          }else{
            response = await payAction(email);
          }

          if (response.data.init_point) {
          this.url = response.data.init_point
            // Redirigir al usuario a la página de suscripción
            window.location.href = response.data.init_point;
            //console.log(this.url)
          } else {
            console.error('Error: init_point no recibido');
          }
        } catch (error) {
          console.error('Error al procesar la suscripción:', error);
        } finally {
          this.isLoading = false; // Ocultar el loading
        }
        }else{
             this.isLoading = false;
        }
    },
    async pagar() { 


      this.isLoading = true; // Mostrar el loading

      try {
        // Hacer la solicitud a tu API para generar la preferencia de pago
        const response = await payAction();
        

        if (response.init_point) {
          // Inicializar el SDK de Mercado Pago
          const mp = new window.MercadoPago(process.env.VUE_APP_MP_CLIENT_KEY, {
            locale: 'es-AR', // Cambia según el idioma de tu país
          });

          // Crear el checkout con el init_point proporcionado por la API
          mp.checkout({
            preference: {
              id: response.init_point,
            },
            autoOpen: true, // Abre automáticamente el modal de pago
          });
        } else {
          console.error('Error: init_point no recibido');
        }
      } catch (error) {
        console.error('Error al procesar el pago:', error);
      } finally {
        this.isLoading = false; // Ocultar el loading
      }
    },
  },
};
</script>

<style scoped>
.pay-button {
  background-color: #3483fa;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.pay-button:hover {
  background-color: #2968c8;
}
</style>
