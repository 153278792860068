import { wencesApi } from "@/api/wencesApi";

// Crear un nuevo ejercicio
export const createExerciseAction = async (formData) => {
  try {
    const { data } = await wencesApi.post("/api/exercise", formData);
    return {
      ok: true,
      exercise: data.exercise
    };
  } catch (error) {
    return {
      ok: false,
      message: "No se pudo crear el ejercicio",
    };
  }
};

// Actualizar un ejercicio
export const updateExerciseAction = async (id, updates) => {
  try {
    const { data } = await wencesApi.put(`/api/exercise/${id}`, updates);
    return {
      ok: true,
      exercise: data.exercise
    };
  } catch (error) {
    return {
      ok: false,
      message: "No se pudo actualizar el ejercicio",
    };
  }
};

// Eliminar un ejercicio
export const deleteExerciseAction = async (id) => {
  try {
    await wencesApi.delete(`/api/exercise/${id}`);
    return {
      ok: true,
      message: "Ejercicio eliminado exitosamente"
    };
  } catch (error) {
    return {
      ok: false,
      message: "No se pudo eliminar el ejercicio",
    };
  }
};

export const getAllExercisesAction = async () => {
  try {
    const { data } = await wencesApi.get("/api/exercise");
    return {
      ok: true,
      exercises: data
    };
  } catch (error) {
    return {
      ok: false,
      message: "No se pudo obtener los ejercicios",
    };
  }
};

// Obtener todos los elementos
export const getExerciseElementsAction = async () => {
  try {
    const { data } = await wencesApi.get("/api/exercise/elements");

    return {
      ok: true,
      elements: data
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo obtener los elementos",
    };
  }
};

// Obtener todos los elementos
export const getBodyZonesElementsAction = async () => {
  try {
    const { data } = await wencesApi.get("/api/exercise/bodyzones");

    return {
      ok: true,
      result: data
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo obtener los elementos",
    };
  }
};
