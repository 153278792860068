import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";


export const getAllAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/stablishments`);

    return {
      ok: true,
      stablishments: data.stablishments
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const getProfileStablishmentsAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/stablishments/profile`);

    return {
      ok: true,
      stablishments: data.stablishments
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getStablishmentAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/stablishments/get/${id}`);

    return {
      ok: true,
      stablishment: data.stablishment,
      invoices: data.invoices
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }
    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getInvoiceConfigAction = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/stablishments/${id}/invoice`);

    return {
      ok: true,
      invoices: data.invoices
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }
    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getMenus = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/stablishments/${id}/menus`);

    return {
      ok: true,
      menus: data.menus
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const getActivity = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/activities`);

    return {
      ok: true,
      activities: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const signUpClassAction = async (activity) => {
  try {
    const { data } = await wencesApi.post(`/api/activities/signup`, 
      activity
    );

    return {
      ok: data.ok, message: data.message
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Stalishment no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const deleteClassAction = async (activityId,theUserId) => {
  try {
    const { data } = await wencesApi.post(`/api/activities/removesubscription`, 
      {activityId,theUserId}
    );

    return {
      ok: data.ok
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Stalishment no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getMyClassesAction = async (all,owner) => {
  try {
    const { data } = await wencesApi.post(`/api/activities/myclasses`, {all,owner});

    return {
      data: data
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Stalishment no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const deleteActivity = async (id) => {
  try {
    const { data } = await wencesApi.delete(`/api/activities/${id}/delete`);

    return {
      ok: true,
      message: "Activity deleted successfully",
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 404) {
      return {
        ok: false,
        message: "Activity not found",
      };
    }

    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Unauthorized access",
      };
    }

    console.log(error);
    throw new Error("Could not perform the delete request");
  }
};


export const saveActivity = async (activity) => {
  try {
    const { data } = await wencesApi.post(`/api/activities/create`, 
      activity
    );

    return {
      ok: true
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Stalishment no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const saveAllAction = async (stablishment) => {
  try {
    const { data } = await wencesApi.post(`/api/stablishments/edit/${stablishment.id}`, 
      stablishment
    );

    return {
      ok: true
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Stalishment no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const saveInvoiceAction = async (invoice) => {
  try {
    const { data } = await wencesApi.post(`/api/stablishments/saveinvoice`, 
      invoice
    );

    return {
      ok: true
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Stalishment no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const deleteAction = async (stablishment_id) => {
  try {
    const { data } = await wencesApi.delete(`/api/stablishments/${stablishment_id}/delete`);

    return {
      ok: true
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Stalishment no existe",
      };
    }
    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const createStabAction = async (stablishment) => {
  try {
    const { data } = await wencesApi.post(`/api/stablishments/create`, 
      stablishment
    );

    return {
      ok: true
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Stalishment no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};
