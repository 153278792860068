import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";

// Guards
import isAuthenticatedGuard from "@/pages/Auth/guards/is-authenticated.guard";

// Auth pages
import { authPages } from "@/pages/Auth/routes/index.js";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";
import AdminDashboard from "@/pages/Dashboard/AdminDashboard.vue";
import Orders from "@/pages/Orders/Orders.vue";

import Settings from "@/pages/Auth/Settings.vue";
import Map from "@/pages/Map/Map.vue";
import Stripe from "@/pages/Stripe/Stripe.vue";

import OrdersMap from "@/pages/Map/OrdersMap.vue";
import MyProfile from "@/pages/MyProfile.vue";


import Pay from "@/pages/Pay/Pay.vue";


import PlanningPage from "@/pages/Training/PlanningPage.vue";
import MyPlanningPage from "@/pages/Training/MyPlanningPage.vue";
import PlanningView from "@/pages/Training/PlanningView.vue";
import PlanningDisplay from "@/pages/Training/PlanningDisplay.vue";
import ExercisesPage from "@/pages/Training/ExercisesPage.vue";
import ElementPage from "@/pages/Training/ElementPage.vue";

// QResto pages
import Stablishments from "@/pages/Stablishments/list.vue";
import Categories from "@/pages/Categories/list.vue";
import Menus from "@/pages/Stablishments/Menus.vue";
import OrderList from "@/pages/Orders/list.vue";
import OrderGymList from "@/pages/Orders/gymlist.vue";
import Accesses from "@/pages/Orders/Accesses.vue";
import AccessHistory from "@/pages/Orders/AccessHistory.vue";
import Receipts from "@/pages/Orders/Receipts.vue";
import Developpers from "@/pages/Developpers.vue";

import Users from "@/pages/Users/list.vue";
import MyTeam from "@/pages/Users/myteam.vue";

import CreateStablishment from "@/pages/Stablishments/CreateStablishment.vue";

import Qrs from "@/pages/Menu/Qrs.vue";
import QrsList from "@/pages/Qrs/list.vue";
import MenuList from "@/pages/Menu/list.vue";


// Pages
import User from "@/pages/Dashboard/Pages/UserProfile.vue";
import Pricing from "@/pages/Dashboard/Pages/Pricing.vue";
import Subscribe from "@/pages/Dashboard/Pages/Subscribe.vue";
import TimeLine from "@/pages/Dashboard/Pages/TimeLinePage.vue";
import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";
import Lock from "@/pages/Dashboard/Pages/Lock.vue";


import Products from "@/pages/Products/list.vue";
import Invoices from "@/pages/Invoices/Invoices.vue";


import MercadoPagoOauth from "@/pages/Dashboard/Components/MercadoPagoOauth.vue";

// Components pages
import Buttons from "@/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "@/pages/Dashboard/Components/GridSystem.vue";
import Panels from "@/pages/Dashboard/Components/Panels.vue";
import SweetAlert from "@/pages/Dashboard/Components/SweetAlert.vue";
import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
import Icons from "@/pages/Dashboard/Components/Icons.vue";
import Typography from "@/pages/Dashboard/Components/Typography.vue";

// Forms pages
import RegularForms from "@/pages/Dashboard/Forms/RegularForms.vue";
import ExtendedForms from "@/pages/Dashboard/Forms/ExtendedForms.vue";
import ValidationForms from "@/pages/Dashboard/Forms/ValidationForms.vue";
import Wizard from "@/pages/Dashboard/Forms/Wizard.vue";

// TableList pages
import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";
import ExtendedTables from "@/pages/Dashboard/Tables/ExtendedTables.vue";
import PaginatedTables from "@/pages/Dashboard/Tables/PaginatedTables.vue";

// Maps pages
import GoogleMaps from "@/pages/Dashboard/Maps/GoogleMaps.vue";
import FullScreenMap from "@/pages/Dashboard/Maps/FullScreenMap.vue";
import VectorMaps from "@/pages/Dashboard/Maps/VectorMaps.vue";

// Calendar
import Calendar from "@/pages/Dashboard/Calendar.vue";
// Charts
import Charts from "@/pages/Dashboard/Charts.vue";
import Widgets from "@/pages/Dashboard/Widgets.vue";

import NotFound from '../pages/NotFound.vue'; // Pantalla para "No hay nada aquí"

import Classes from "@/pages/Classes.vue";
import MyClasses from "@/pages/MyClasses.vue";

import { i18n } from "../main"; // Asegúrate de que esta ruta es correcta

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  beforeEnter: isAuthenticatedGuard,
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem },
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels },
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  name: "Forms",
  beforeEnter: isAuthenticatedGuard,
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms },
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms },
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms },
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard },
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  beforeEnter: isAuthenticatedGuard,
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables },
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables },
    },
    {
      path: "paginated",
      name: "Pagianted Tables",
      components: { default: PaginatedTables },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  beforeEnter: isAuthenticatedGuard,
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps },
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  beforeEnter: isAuthenticatedGuard,
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      components: { default: User },
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: TimeLine },
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home",
    beforeEnter: isAuthenticatedGuard
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  authPages,
      {
        path: '*',
        name: 'NotFound',
        component: NotFound
      },
  {
    path: "/",
    component: DashboardLayout,
    beforeEnter: isAuthenticatedGuard,
    name: 'pepe',
    children: [
      {
        path: "inventory",
        name: "Products",
        components: { default: Products },
      },
      {
        path: "pricing",
        name: "Pricing",
        components: { default: Pricing },
      },
      {
        path: "subscribe",
        name: "Subscribe",
        components: { default: Subscribe },
      },
      {
        path: "subscribe/:status",
        name: "Subscribe",
        components: { default: Subscribe },
      },
      {
        path: "pricing/:status",
        name: "Pricing",
        components: { default: Pricing },
      },
      {
        path: "qrs",
        name: "QrsList",
        components: { default: QrsList },
      },
      {
        path: "settings",
        name: "Settings",
        components: { default: Settings },
      },  
      {
        path: "payments",
        name: "MercadoPagoOauth",
        components: { default: MercadoPagoOauth },
      },    
      {
        path: "pay",
        name: "Pay",
        components: { default: Pay },
      },  
      {
        path: "myprofile",
        name: "MyProfile",
        components: { default: MyProfile },
      },  
      {
        path: "menus",
        name: "MenuList",
        components: { default: MenuList },
      },
      {
        path: "myclasses",
        name: "MyClasses",
        components: { default: MyClasses },
      },
      {
        path: "classes",
        name: "Classes",
        components: { default: Classes },
      },
      {
        path: "invoices/:stablishment_id",
        name: "Invoices",
        components: { default: Invoices },
      },
      {
        path: "/stripe",
        name: "Stripe",
        components: { default: Stripe },
      },
      {
        path: "/users",
        name: "Users",
        components: { default: Users },
      },
      {
        path: "/myteam",
        name: "MyTeam",
        components: { default: MyTeam },
      },
      {
        path: "orders",
        name: "OrderList",
        components: { default: OrderList },
      },
      {
        path: "myorders",
        name: "OrderGymList",
        components: { default: OrderGymList },
      },
      {
        path: "receipts",
        name: "Receipts",
        components: { default: Receipts },
      },
      {
        path: "accesses",
        name: "Accesses",
        components: { default: Accesses },
      },
      {
        path: "accesshistory",
        name: "AccessHistory",
        components: { default: AccessHistory },
      },
      {
        path: "categories",
        name: "Categories",
        components: { default: Categories },
      },
      {
        path: "stablishments",
        name: "Stablishments",
        components: { default: Stablishments },
      },
      {
        path: "/mercadopago/confirm",
        name: "Settings",
        components: { default: Settings },
      },
      {
        path: "/developpers",
        name: "Developpers",
        components: { default: Developpers },
      },
      {
        path: "stablishments/:id",
        name: "Stablishments",
        components: { default: Stablishments },
      },
      
      {
        path: "admin",
        name: "AdminDashboard",
        components: { default: AdminDashboard },
      },
      
      {
        path: "notifications",
        name: "Notifications",
        components: { default: Notifications },
      },
      {
        path: "stablishments/create",
        name: "Create",
        components: { default: CreateStablishment },
      },
      {
        path: "stablishments/:id/orders",
        name: "Orders",
        components: { default: Orders },
      },
      {
        path: "stablishments/:id/map",
        name: "Map",
        components: { default: Map },
      },
      {
        path: "stablishments/:id/ordersmap",
        name: "OrdersMap",
        components: { default: OrdersMap },
      },
      {
        path: "stablishments/:id/menus",
        name: "Menus",
        components: { default: Menus },
      },
      {
        path: "menu/create",
        name: "QR codes",
        components: { default: Orders },
      },
      {
        path: "menu/:id/qrs",
        name: "QR codes",
        components: { default: Qrs },
      },
      {
        path: "menu/:id/createqr",
        name: "Create QR",
        components: { default: Orders },
      },
      {
        path: "qr/:id/edit",
        name: "Edit QR",
        components: { default: Orders },
      },
      {
        path: "orders",
        name: "Orders",
        components: { default: Dashboard },
      },
      {
        path: "menus",
        name: "Menus",
        components: { default: Dashboard },
      },
      {
        path: "exercises",
        name: "ExercisesPage",
        components: { default: ExercisesPage },
      },
      {
        path: "planning",
        name: "PlanningPage",
        components: { default: PlanningPage },
      },
      {
        path: "myplannings",
        name: "MyPlanningPage",
        components: { default: MyPlanningPage },
      },
      {
        path: "createdbyme",
        name: "CreatedByMe",
        components: { default: PlanningPage },
      },
      {
        path: "planning/:id",
        name: "PlanningView",
        components: { default: PlanningDisplay },
      },
      {
        path: "elements",
        name: "ElementPage",
        components: { default: ElementPage },
      },
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
        meta:{title: 'Dashboard'}
      },
      {
        path: "calendar",
        name: "Calendar",
        components: { default: Calendar },
      },
      {
        path: "charts",
        name: "Charts",
        components: { default: Charts },
      },
      {
        path: "widgets",
        name: "Widgets",
        components: { default: Widgets },
      },
    ],
  },
];

export default routes;
