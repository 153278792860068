export default {
  addNewMenu: 'Ajouter un Nouveau Menu',
menuName: 'Nom du Menu',
deliveryConfirmation: 'Votre commande a déjà été livrée',
orderDelivered: 'Votre commande a déjà été livrée, vous pouvez passer une autre commande en revenant au menu',
forgotPassword: 'J\'ai oublié mon mot de passe',
subscribe: 'S\'abonner',
contactForHelp: 'Si vous avez des questions sur le fonctionnement du système ou sur la façon de vous abonner, écrivez-nous dans le chat et nous vous aiderons. Ne vous inquiétez pas, vous parlerez à un humain.',
restaurant_role: 'Administrateur',
importantNotifications: 'Ici, vous recevrez des notifications importantes concernant l\'activité des personnes dans cet établissement.',
serviceStart: 'Début du service',
  "paymentMethod": "Mode de paiement",
    "enrolledPeople": "Personnes inscrites",
  "paymentPerAccessOrQuota": "Paiement par nombre d'accès ou de quotas",
  "perMonth": "par mois",
  "select": "Sélectionner",
    "notEnrolledInAnyClass": "Vous n'êtes inscrit à aucun cours",
    "myProfile": "Mon profil",
      "pending": "En attente",
       "clientAccess": "Accès pour les clients",
    'shoulders' : 'Épaules',
'biceps' : 'Biceps',
'triceps' : 'Triceps',
'perderpeso' : 'Perdre du poids',
'ganarmusculo' : 'Gagner du muscle',
'tonificacion' : 'Tonification',
'movilidad' : 'Mobilité',
  "bodyZone": "Zone du corps",
'chest' : 'Poitrine',
'back' : 'Dos',
  "inprogress": "En cours",
'abdomen' : 'Abdomen',
'glutes' : 'Fessiers',
  "done": "Terminé",
'quadriceps' : 'Quadriceps',
'hamstrings' : 'Ischio-jambiers',
'calves' : 'Mollets',
      "download": "Télécharger",
      "stickQRMessage": "Collez le QR sur la porte de votre établissement afin que vos clients puissent le scanner en entrant pour contrôler l'accès",
    "unlimitedMonthlyPayment": "Paiement mensuel avec accès illimité à la salle de sport et quotas de cours illimités",
  "unlimitedMonthlyPaymentWithQuota": "Paiement mensuel avec accès illimité à la salle de sport et {cupos} places pour les cours par mois",
  "limitedMonthlyPayment": "Paiement mensuel avec {access} entrées à la salle de sport par mois et {cupos} places pour les cours par mois",
  "limitedAccessUnlimitedQuota": "Paiement mensuel avec {access} entrées à la salle de sport par mois et places de cours illimitées",
  "oneTimePayment": "Paiement unique pour {access} entrées à la salle de sport et {cupos} places pour les cours",
    "monthlyPayment": "Paiement mensuel",
accessGranted: 'Acesso permitido',
  "noQuotaLeft": "Vous n'avez plus de places disponibles",
  "classLimitPerMonth": "Nombre de cours auxquels ce client peut s'inscrire",
  "quota": "Quotas",
    "noQuotaLeftThisMonth": "Vous n'avez plus de places disponibles pour ce mois",
  "mustLoginToSignUp": "Vous devez vous connecter pour vous inscrire",
  "waitingForEntries": "En attente d'entrées",
  "emailExistsMessage": "Cet email existe déjà.",
    "unlimitedAccess": "Accès illimité",
    "remainingQuota": "Quotas restants",
      "recurringActivitiesMessage": "Les activités récurrentes ne permettent pas l'inscription pour le moment, mais nous améliorerons cela bientôt. Choisissez une date spécifique pour activer les inscriptions.",
  "allowOverQuotaMessage": "En choisissant cette option, tout client, qui n'a plus de places disponibles mais qui a payé sa cotisation mensuelle, pourra également s'inscrire.",
  "anyClientCanSignUp": "Tout client peut s'inscrire",
ticket: 'Addition provisoire',
  "allowedAccessCount": "Nombre d'accès autorisés (Scan du QR lors de l'entrée dans l'établissement)",
  "myCalendar": "Mon calendrier",
    "availableSlots": "Places disponibles",
      "scanQrFirst": "Vous devez d'abord scanner le code QR avant de pouvoir cliquer pour entrer.",
    "myClasses": "Mes cours",
  "noSlotsAvailable": "Il n'y a plus de places pour cette classe",
    "allready": "Vous êtes déjà inscrit à cette classe",
  "defineSpecificDate": "Définir une date spécifique",
  "signUpForClass": "M'inscrire à une classe",
  "signUpForThisClass": "M'inscrire à cette classe",
  "unlimitedQuota": "Quotas illimités",
  "seeDemoBeforeSignUp": "Je veux voir une démo avant de m'inscrire.",
  "uploadFile": "Télécharger le fichier",
    "seeDemo": "Voir la démo",
      "availableEntries": "Entrées disponibles",
        "Monday": "Lundi",
    "Tuesday": "Mardi",
    "Wednesday": "Mercredi",
    "Thursday": "Jeudi",
    "Friday": "Vendredi",
    "Saturday": "Samedi",
    "Sunday": "Dimanche",
    "January": "Janvier",
    "February": "Février",
    "March": "Mars",
    "April": "Avril",
    "May": "Mai",
    "June": "Juin",
    "July": "Juillet",
    "August": "Août",
    "September": "Septembre",
    "October": "Octobre",
    "November": "Novembre",
    "December": "Décembre",
      "temporaryIssue": "Nous rencontrons un problème temporaire, veuillez réessayer plus tard.",
      "enableRegistrations": "Activer les inscriptions pour cette activité",
      "leaveEmptyIfNoQuota": "Laissez vide si vos cours n'ont pas de quotas définis.",
      "quotaMessage": "En définissant les quotas, le système n'autorisera plus les personnes à s'inscrire une fois la limite atteinte. Pour s'inscrire, elles doivent scanner le code QR que vous placerez dans votre salle de sport ou utiliser le lien :",
    "demoMessage": "Ceci est une démo, vous devez créer votre propre compte pour modifier les options. Vous pouvez le faire sur :",
  "uploadMenuMessage": "Vous pouvez télécharger une image pour chaque page du menu ou un PDF avec le menu complet.",
  "imagemenu": "Afficher uniquement la photo du menu, sans option pour le consommateur de passer des commandes.",
  "leaveEmptyForEmail": "Laissez vide si vous souhaitez utiliser l'e-mail comme identifiant.",
  "identifierExistsMessage": "Cet identifiant a déjà été utilisé par un autre utilisateur.",
    "lastEntry": "Dernière entrée",
  "shareScheduleInfo": "Vous pourrez partager les horaires avec vos clients en insérant le lien sur vos réseaux sociaux. Ils pourront également le voir sur leur téléphone en entrant dans la salle de sport en scannant le code QR.",
imageSet1_step1_title: '1- Scanner le QR',
imageSet1_step1_description: 'Ils scannent le QR à une piscine, à la plage ou sur un marché.',
imageSet1_step2_title: '2- Réception de la commande',
imageSet1_step2_description: 'La commande est reçue sur une tablette ou un smartphone.',
imageSet1_step3_title: '3- Alerter le client',
imageSet1_step3_description: 'Le client reçoit une alerte lorsque le plat est prêt et vient le chercher.',
generateReceipt: 'Générer un reçu',
  "identifierMessage": "Insérez un identifiant que vous pouvez facilement vous rappeler. Vous l'utiliserez ici pour accéder à la salle de sport chaque fois que vous scannez le code QR. Exemple : votre numéro de téléphone, votre carte d'identité ou votre e-mail.",
  myClients: "Mes clients",
    "leaveScreenOpenMessage": "Laissez cet écran ouvert dans le navigateur de votre établissement et vous pourrez vérifier chaque fois que quelqu'un entre en scannant le code QR.",
  "gotakeitUsageMessage": "Cela permet de commencer à utiliser Gotakeit sans avoir besoin de charger les clients. Ils créeront leurs profils, et vous devrez ensuite insérer la date de paiement.",
    "selfRegistrationMessage": "Permettre aux personnes de s'inscrire elles-mêmes en scannant le code QR si elles n'ont pas encore de profil créé.",
    "uploadImageMessage": "Téléchargez une image montrant votre visage pour vous identifier lors de l'entrée dans la salle de sport.",
    "activityLabel": "Activité",
  "activityPlaceholder": "Nom de l'activité",
  "selectDateLabel": "Sélectionner une date",
  "startTimeLabel": "Heure de début",
  "endTimeLabel": "Heure de fin",
  "timePlaceholder": "HH:MM",
  "removeButton": "Supprimer",
  "addButton": "Ajouter une activité",
  "saveGridButton": "Enregistrer la grille",
    "addDailyActivityTitle": "Ajouter une Activité Quotidienne",
  "activityLabel": "Activité",
  "activityPlaceholder": "Ex: Yoga",
  "dayOfWeekLabel": "Jour de la semaine",
  "startTimeLabel": "Heure de début",
  "endTimeLabel": "Heure de fin",
  "addButton": "Ajouter l'Activité",
  "Lunes": "Lundi",
  "Martes": "Mardi",
  "Miércoles": "Mercredi",
  "Jueves": "Jeudi",
  "Viernes": "Vendredi",
  "Sábado": "Samedi",
  "Domingo": "Dimanche",
paymentsAndReceipts: 'Paiements et reçus',
  waitingPeriod: "Période d'attente", // Francés
  waitingPeriodExplanation: "Choisissez le nombre de jours que vous souhaitez attendre avant d'afficher une bannière rouge à un client qui n'a pas payé.",
imageSet2_step1_title: '1- Scanner le QR',
imageSet2_step1_description: 'Scannez le QR et commandez les produits souhaités.',
imageSet2_step2_title: '2- Réception de la commande',
imageSet2_step2_description: 'Le restaurant reçoit la commande en cuisine.',
imageSet2_step3_title: '3- Le serveur sert le plat',
imageSet2_step3_description: 'Le serveur apporte la commande à la table.',
   "importUsers": "Importer des utilisateurs depuis un fichier Excel (.xls ou .xlsx)",
    "matchColumns": "Associer les colonnes",
    "selectDateFormat": "Sélectionner le format de date",
    "previewImport": "Aperçu de l'importation",
    "previewUsers": "Aperçu des utilisateurs à importer",
    "noDataAvailable": "Aucune donnée disponible pour l'aperçu.",
    "confirmAndImport": "Confirmer et importer",
    "resume": "Reprendre",
    "pause": "Pause",
    "error": "Erreur",
    "emptyFile": "Le fichier est vide ou ne contient pas de données.",
    "missingHeaders": "Le fichier doit contenir des en-têtes dans la première ligne.",
    "limitExceeded": "Limite dépassée",
    "importLimit": "Impossible d'importer plus de 3000 contacts en une seule exécution.",
    "missingHeadersCSV": "Le fichier CSV doit contenir des en-têtes dans la première ligne.",
    "importCancelled": "Importation annulée",
    "errorCreatingUser": "Erreur lors de la création de l'utilisateur {name} :",
    "importedUsers": "{createdUsers} utilisateurs ont été importés ou modifiés avec succès.",
imageSet3_step1_title: '1- Les clients consultent le menu',
imageSet3_step1_description: 'Ils consultent le menu mais ne peuvent pas passer de commandes.',
imageSet3_step2_title: '2- Le serveur prend la commande',
imageSet3_step2_description: 'Le serveur accède au QR de la table et génère la commande.',
imageSet3_step3_title: '3- Réception de la commande',
imageSet3_step3_description: 'Le restaurant reçoit la commande en cuisine.',
imageSet3_step4_title: '4- Le serveur sert le plat',
imageSet3_step4_description: 'Le serveur apporte la commande à la table.',
imageSet3_step5_title: '5- Les clients paient',
imageSet3_step5_description: 'Les clients paient de manière classique ou scannent le QR pour payer.',
selectAll: 'Tout sélectionner',
differentWaysToUse: 'Différentes façons d\'utiliser Go Take It',
completePayment: 'Complétez votre paiement',
remainingAccesses: 'Accès restants',
unsubscribe: 'Se désabonner',
pay: 'Payer',
maxOrders: 'Nombre maximal de commandes',
kitchenStatusOrders: 'Commandes avec le statut "cuisine"',
maxOrdersLimit: 'Nombre maximal de commandes que cet établissement peut prendre en même temps. Si le nombre est dépassé, le QR affichera le menu mais n\'autorisera pas de nouvelles commandes.',
sendMessageChat: 'Envoyez-nous un message dans le chat',
perMonth: 'par mois',
filter: 'Filtrer',
remainingOrders: 'Commandes restantes',
logout: 'Déconnexion',
noCreditsQRInactive: 'Sans crédits, vos QR ne fonctionneront plus',
gym_role: 'Administrateur',
alreadySubscribedUnlimited: 'Vous êtes déjà abonné au plan illimité',
unlimitedPlan: 'Plan illimité',
cancelableSubscription: 'Abonnement annulable à tout moment, sans engagement.',
subscriptionActivated: 'Votre abonnement a été activé, profitez de GoTakeIt sans limites!',
inviteToSubscribe: 'Nous vous invitons à vous abonner au plan illimité',
monthlySubscription: 'Abonnement mensuel de 10 000 pesos argentins par mois',
monthlySubscriptionEur: 'Abonnement mensuel de 12,00 € par mois',
cancelSubscription: 'Vous pouvez annuler l\'abonnement à tout moment via MercadoPago',
unlimitedCredits: 'Crédits illimités, accès illimité à toutes les fonctionnalités',
business: 'Entreprise',
employee_role: 'Serveur',
accesses: 'Accès',
delete: 'Supprimer',
client_role: 'Client',
rejected: 'Rejeté',
  "access_reminder": "Il vous reste {credits} crédits",
  "subscription_invite": "Nous vous invitons à vous abonner en choisissant l'option 'abonnement' dans le menu",
accessBlocked: 'Accès bloqué',
markAsPaid: 'Marquer comme payé',
paymentDate: 'Date de paiement',
accepted: 'Accepté',
paymentDateInfo: 'Insérez vos clients avec la date de paiement pour que le système sache quand il doit refuser l\'accès. Par défaut, le système autorise l\'accès si la date n\'a pas été saisie.',
sendEmail: 'Envoyer un email',
loadClients: 'Charger les clients',
clientsStartUsing: 'Vos clients commencent à l\'utiliser',
qrScanNotification: 'Vos clients scannent le QR chaque fois qu\'ils entrent dans l\'établissement et vous recevez une notification indiquant s\'ils sont autorisés à entrer ou non',
accessControl: 'Contrôle d\'accès pour les gymnases, clubs et établissements privés',
digitalQrMenu: 'Menu digital QR pour restaurants, parasol à la plage, piscines ou hôtels',
nameAlreadyTaken: 'Ce nom a déjà été choisi par un autre utilisateur',
menuTypesDescription: 'Il existe 4 types de menus que vous pouvez choisir en fonction des besoins de votre entreprise',
qrSettings: 'Paramètres pour QR',
  "payment_due": "Il reste {days} jours avant l'échéance de votre paiement mensuel",
    "access_denied": "Accès refusé",
  "payment_overdue": "Votre paiement est en retard de {days} jours.",
  identifierNotFound: 'L\'identifiant n\'existe pas',
qrConfigurationInfo: 'Définissez des configurations pour vos différents QR et associez cette configuration au QR de votre choix. Vous pouvez définir les couleurs, les langues et les phrases à utiliser.',
tokenExpired: 'Le jeton a expiré',
insertClients: 'Insérez vos clients avec un nom et un identifiant',
deactivateQR: 'Désactiver QR',
activateQR: 'Activer QR',
popupNotification: 'Nous vous informerons avec une popup sur cet écran lorsque votre commande sera prête',
tryAgain: 'Essayez à nouveau avec un nouvel email',
emailSent: 'Email envoyé',
toPay: 'À payer',
consentNotice: 'En utilisant l\'application Gotakeit, vous consentez à nos conditions d\'utilisation et à notre politique de confidentialité. Vous comprenez que vos informations sont traitées au nom du Établissement et seront partagées avec eux.',
businessName: 'Le nom de votre entreprise',
users: 'Utilisateurs',
userRolesDescription: 'Vous pouvez créer un autre utilisateur admin ou un utilisateur avec un rôle d\'employé. Le rôle d\'employé permet à l\'utilisateur d\'utiliser GO Take It sur son téléphone pour prendre les commandes et générer les ordres lui-même pour les cas où le consommateur ne peut que consulter le menu mais ne peut pas générer de commandes lui-même.',
goToMyApp: 'Aller à mon application',
paymentPending: 'Paiement en attente',
addUser: 'Ajouter un utilisateur',
orderSummaryEmail: 'Nous vous avons envoyé un email avec le récapitulatif de votre commande, n\'oubliez pas de vérifier les spams et de retirer l\'email de ce dossier pour pouvoir recevoir correctement l\'alerte lorsque votre commande sera prête.',
    "new_password": "Nouveau mot de passe",
    "repeat_password": "Répéter le mot de passe",
    "change_password": "Changer le mot de passe",
    "password_too_short": "Le mot de passe doit comporter au moins 6 caractères.",
    "password_needs_symbol": "Le mot de passe doit contenir au moins un symbole.",
    "passwords_do_not_match": "Les mots de passe ne correspondent pas.",
    "password_changed": "Mot de passe changé avec succès.",
    "error_changing_password": "Erreur lors du changement de mot de passe.",
correctEmail: 'Si l\'adresse email est correcte, vous recevrez un lien pour réinitialiser le mot de passe',
passwordResetEmail: 'Nous vous enverrons un email avec un lien pour réinitialiser le mot de passe',
onlymenu: 'Afficher uniquement le menu, sans option de passer des commandes',
sendNotifications: 'Enviaremos notificações para este email quando você receber pedidos',
activatePaymentSystem: 'Activer le système de paiement pour les commandes',
customersMustPay: 'Les clients doivent payer au moment de passer la commande.',
syncRequired: 'Vous devez synchroniser avec notre système de paiement pour activer cette option',
  syncpayments:'Synchroniser avec la plateforme de paiement',
  ifyouunsync:'Si vous désynchronisez, vous ne pourrez plus recevoir de paiements.',
  stripeAccountSync:'Votre compte a été synchronisé avec succès.',
  nowYouCanAccess:'Vous recevrez désormais les paiements directement sur votre compte, nous ne sommes pas des intermédiaires. Vous pouvez maintenant activer l\'option dans votre menu pour que les clients paient leurs commandes.',
  connectStripeAccount: 'Connecter le compte Stripe',
  syncStripeInfo: 'Synchroniser Stripe permettra les paiements afin que les clients doivent payer leurs commandes avant de les passer.',
  noStripeAccount: 'Si vous n’avez pas de compte Stripe, vous pouvez en créer un et revenir à cet écran pour le synchroniser.',
  alreadyHaveStripeAccount: 'Si vous avez déjà un compte Stripe connecté, vous pouvez le synchroniser en cliquant ici.',
  syncMyStripeAccount: 'Synchroniser mon compte Stripe',
  createOrSyncStripeAccount: 'Créer ou Synchroniser le compte Stripe',
  error: 'Erreur',
  stripeConnectError: 'Impossible de se connecter à Stripe. Veuillez réessayer.',
  haveStripeAccount: 'Avez-vous un compte Stripe ?',
  redirectSync: 'Si vous avez déjà un compte, nous vous redirigerons pour le synchroniser.',
  yesSyncAccount: 'Oui, synchroniser le compte',
  noCreateAccount: 'Non, créer un nouveau compte',
  stripeAccountCreated: 'Compte Stripe créé',
  syncStripeInfoAfterCreation: 'Vous devez maintenant le synchroniser avec nous pour commencer à recevoir des paiements.',
  stripeCreateError: 'Impossible de créer un compte Stripe. Veuillez réessayer.',
  genericError: 'Une erreur s’est produite. Veuillez réessayer.',
  qrhelp: 'Vous pouvez créer des codes QR pour les lier à un menu. Ensuite, imprimez ce QR et placez-le sur les tables du restaurant, les parasols de la plage ou les lits au bord de la piscine d\'un hôtel.',
    dashboard: 'Dashboard',
    total: "Total", 
    myOrder: "Ma commande",
    orderNotification: "Nous vous enverrons un email ou un SMS lorsque votre commande sera prête.",
    name: "Nom",
    enterName: "Entrez votre nom",
    email: "Email",
    enterEmail: "Entrez votre email",
    comments: "Commentaires",
    enterComments: "Entrez des commentaires",
    code: "Code",
    phone: "Téléphone",
    yespaid: "Déjà payé",
    accessIdentifier: 'Identifiant d\'accès',
    accessIdentifierInfo: 'Cet identifiant sera celui que le client devra saisir lors du scan du QR, cela peut être son numéro de document d\'identité national, passeport ou numéro de client. Quelque chose qu\'il puisse se rappeler facilement.',
    nopaid: "Pas encore payé",
    enter: 'Entrer',
    paid: "Payé",
    enterPhone: "Entrez le numéro de téléphone",
    confirmOrder: "Confirmer la commande",
    yourOrder: "Votre commande",
    orderDetails: "Votre commande sera envoyée à la cuisine une fois que vous aurez cliqué sur envoyer. Nous vous informerons lorsqu'elle sera prête à être retirée.",
    sendOrder: "Envoyer la commande",
    cancel: "Annuler",
  producthelp:'N\'oubliez pas que si vous créez un nouveau produit, vous devez ensuite le lier au menu où vous souhaitez qu\'il apparaisse',
  scanphone: "Scannez le QR avec votre téléphone ou cliquez",
    orderNumber: "Numéro de commande",
    preparingOrder: "Nous préparons votre commande",
dontClose: 'Nous vous informerons avec une popup sur cet écran lorsque votre commande sera prête et nous vous enverrons également un email à l\'adresse que vous avez insérée précédemment',
    orderReadyTitle: "Votre commande est prête!",
    orderReadyText: "Vous pouvez récupérer votre commande maintenant.",
    welcome: "Bienvenue",
    instructions: "Veuillez suivre les instructions.",
    settings: "Paramètres",
    currency: "Devise",
    position_currency: "Position de la devise",
    primary_color: "Couleur primaire",
    secondary_color: "Couleur secondaire",
    menu_languages: "Langues du menu",
    request_location: "Demander l'emplacement",
    request_additional_information: "Demander des informations supplémentaires",
    menu_terms: "Conditions du menu",
    save: "Sauvegarder",
    success: "Succès!",
    business_info_updated: "Informations commerciales mises à jour avec succès",
    error: "Erreur!",
    error_updating_business_info: "Il y a eu une erreur lors de la mise à jour des informations commerciales",
    english: "Anglais",
    spanish: "Espagnol",
    french: "Français",
    german: "Allemand",
    italian: "Italien",
    takeaway: "Commandes à emporter lorsque le consommateur est alerté",
    onsite: "La commande est reçue à l'endroit où le code QR est scanné",
    menu_type: "Type de menu",
    myMap: "Ma carte",
    drawMap: "Dessiner la carte",
    deleteWall: "Supprimer le mur",
    addProduct: "Ajouter un produit",
    addCategory: "Ajouter une catégorie",
    editCategory: "Modifier la catégorie",
    addNewCategory: "Ajouter une nouvelle catégorie",
    categoryName: "Nom de la catégorie",
    identifier: "Identifiant",
    editProduct: "Modifier le produit",
    addNewProduct: "Ajouter un nouveau produit",
    productName: "Nom du produit",
    description: "Description",
    productPrice: "Prix du produit",
    category: "Catégorie",
    selectImage: "Sélectionner une image",
    change: "Changer",
    remove: "Supprimer",
    inventory: "Inventaire",
    selectCategoryToAssign: "Sélectionner la catégorie à attribuer",
    assignCategory: "Attribuer une catégorie",
    noCategory: "Aucune catégorie",
    pleaseSelectCategoryAndProduct: "Veuillez sélectionner une catégorie et au moins un produit!",
    productNotFound: "Produit non trouvé!",
    pleaseFillOutAllFields: "Veuillez remplir tous les champs!",
    errorUploadingImage: "Erreur lors du téléchargement de l'image",
    categoryNotFound: "Catégorie non trouvée!",
    areYouSure: "Êtes-vous sûr?",
    doYouWantToSaveAllChanges: "Voulez-vous enregistrer tous les changements?",
    yesSaveIt: "Oui, enregistrez-le!",
    saved: "Enregistré!",
    yourChangesHaveBeenSaved: "Vos modifications ont été enregistrées.",
    doYouWantToDeleteThisProduct: "Voulez-vous supprimer ce produit?",
    yesDeleteIt: "Oui, supprimez-le!",
    deleted: "Supprimé!",
    yourProductHasBeenDeleted: "Votre produit a été supprimé.",
    doYouWantToDeleteThisCategory: "Voulez-vous supprimer cette catégorie?",
    yourCategoryHasBeenDeleted: "Votre catégorie a été supprimée.",
    stablishments: "Établissements",
    menus: "Menus",
    qrCodes: "Codes QR",
    addQr: "Ajouter un QR",
    saveAll: "Tout enregistrer",
    addNewQr: "Ajouter un nouveau QR",
    qrName: "Nom du QR",
    viewMenu: "Voir le menu",
    youWontBeAbleToRevertThis: "Vous ne pourrez pas annuler cela!",
    yourQrHasBeenDeleted: "Votre QR a été supprimé.",
    emailPlaceholder: "Email...",
    passwordPlaceholder: "Mot de passe...",
    login: "Connexion",
    incorrectUserOrPassword: "Utilisateur ou mot de passe incorrect",
    loginError: "Erreur de connexion",
    addNewStablishment: "Ajouter un nouvel établissement",
    map: "Carte",
    edit: "Modifier",
    remove: "Supprimer",
    viewOrders: "Voir les commandes",
    viewOrdersMap: "Voir la carte des commandes",
    sendToKitchen: "Envoyer à la cuisine",
    sendAlert: "Envoyer une alerte",
    finish: "Terminer",
    createdAgo: "Créé il y a",
    inKitchenFor: "En cuisine depuis",
    qrCode: "Code QR",
    email: "Email",
    phone: "Téléphone",
    comments: "Commentaires",
    alertSentAgo: "Alerte envoyée il y a",
    alerting: "Alerte",
    details: "Détails",
    backToPending: "Retour en attente",
    backToOngoing: "Retour en cours",
    cancelOrder: "Annuler la commande",
    enableSoundAlerts: "Activer les alertes sonores",
    newOrders: "Nouvelles Commandes",
    inKitchen: "En Cuisine",
    alertingCustomer: "Alerter le Client",
    closedOrders: "Commandes Closes",
    cancelledOrders: "Commandes Annulées",
    orders: "Commandes",
    translation: "Traduction",
    products: "Produits",
      address: "Adresse",
  enterAddress: "Entrez votre adresse",
  logo: "Logo",
    termsRequired: 'Vous devez accepter les termes et conditions.',
  accountconfirm:'Votre compte a été confirmé, vous pouvez maintenant vous connecter.',
    restaurantName: 'Nom du restaurant...',
  logoTitle: 'Logo',
  confirmEmail: 'Confirmez votre e-mail',
  confirmEmailText: 'Veuillez cliquer sur le lien que nous avons envoyé à votre e-mail pour confirmer votre compte.',
  register: 'S’inscrire',
  coverImage: "Image de couverture",
  avgOrderValue: 'Valeur moyenne de commande',
  sales: 'Ventes',
  topCustomers: 'Meilleurs Clients',
  topOrderedProducts: 'Produits les plus commandés',
  password: 'Mot de passe...',
  termsAndConditions: 'J’accepte les',
  termsLinkText: 'termes et conditions',
  getStarted: 'Commencer',
  createApp: 'Nous créons l\'application',
  createAppDesc: 'Nous créons l\'application pour votre restaurant.',
  addProducts: 'Vous ajoutez des produits',
  addProductsDesc: 'Vous ajoutez les produits au menu et configurez pour générer les codes QR.',
  customersEnjoy: 'Vos clients en profitent',
  customersEnjoyDesc: 'Vos clients scannent le code QR et passent leurs commandes.',
  restaurantNameExists: 'Le nom du restaurant existe déjà.',
  errorCheckingRestaurantName: 'Erreur lors de la vérification du nom du restaurant.',
  nameRequired: 'requis.',
  restaurantNameRequired: 'Le nom du restaurant est requis.',
  emailRequired: 'L\'e-mail est requis.',
  emailInvalid: 'Le format de l\'e-mail n\'est pas valide.',
  passwordRequired: 'Le mot de passe est requis.',
  userExists: 'L\'utilisateur existe déjà.',
      "nifLabel": "N.I.F",
    "invoiceTitle": "Facture Simplifiée",
    "dateLabel": "Date",
    "invoiceNumberLabel": "No",
    "tableLabel": "Table",
    "guestLabel": "Convives",
    "waiterLabel": "Serveur",
    "totalWithoutTaxLabel": "Total hors TVA",
    "taxLabel": "TVA",
    "totalLabel": "Total",
    "tipLabel": "POURBOIRE",
    "thankYouMessage": "TVA Incluse - Merci de votre visite",
    "description": "Description",
    "quantity": "Qté",
    "price": "Prix",
    "total": "Total"
}