<template>
  <div class="md-layout">
      <div class="md-layout-item md-size-100">
    
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>sensor_door</md-icon>
          </div>

            <h4 class="title">Top 10 accesos</h4>
                <div>
                  <h6 class="title">Los clientes que más accesos registraron este mes</h6>
                </div>
            </md-card-header>
            <md-card-content>
                <div v-for="(item,index) in users" class="w-100 float-left customborder my-2 p-2">
                  <h6 class="d-inline float-left pt-2 text-primary">{{index+1}}.</h6>
                  <span class="d-inline float-left"><avatar :logo="item.avatar"  :small="true"></avatar></span>
                  <div class="pt-2"><span>{{item.name}}</span> - <strong>{{item.count}} accesos</strong></div>

                </div>
            </md-card-content>
          </md-card>

      
      </div>
  </div>
</template>

<script>
import { ingressByUserAction,ingressByDayAction } from "@/pages/Statistics/actions";


import { ChartCard,Avatar } from "@/components";
export default {
  components: {
    ChartCard,
    Avatar
  },
  name: "IngressByUser",

  data() {
    return {  
      users: []
    };
  },


  created() {
    this.fetchBusinessData();
  },

  methods: {
    async fetchBusinessData() {
      try {
        const response = await ingressByUserAction();
        this.users = response.ingressByUser;

        this.ready = true
        
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    },
  },

};
</script>
