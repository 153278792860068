<template>
  <div class="md-layout  mt-4" v-if="stablishment && stablishment.businessName">


    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-rose">
        <div class="card-icon">
          <md-icon>payment</md-icon>
        </div>
          <h4 class="title">{{ $t('paymentMethod') }}</h4>
      </md-card-header>
      <md-card-content>


      <div v-if="stablishment.businessName.mercadoPago" class="my-4">
        <h5>1- Mercado Pago</h5>
  
      </div>

      <div v-if="stablishment.businessName.paymentLink">
        <h5>2- Transferencia</h5>
    {{stablishment.businessName.paymentInfo}}
      </div>

      <div v-if="stablishment.businessName.uploadRecipt">
        <h5>Sube el comprobante</h5>
          Sube captura de pantalla del comprobante y el sistema lo validará para marcar tu pago como realizado
      </div>


      </md-card-content>
    </md-card>
  
  </div>
</template>

<script>
import Swal from "sweetalert2";
import permissionsMixin from '@/mixins/permissionsMixin';
import MercadoPago from "@/pages/Dashboard/Components/MercadoPago.vue";
import { getProfileStablishmentsAction } from "@/pages/Stablishments/actions";
import OrderGymList from "@/pages/Orders/gymlist.vue";

export default {
  mixins: [permissionsMixin],
  components: {
    OrderGymList,
    MercadoPago
  },
  data() {
    return {
      isLoading: false,
      stablishment:false,
      allStablishments: [],
    };
  },
  mounted() {

  },
  created(){
    this.getStablishments();
  },
  methods: {
    async getStablishments(){
      this.isLoading = true;

      const stablishmentsResponse = await getProfileStablishmentsAction();
      this.allStablishments = stablishmentsResponse.stablishments
      if(this.allStablishments){
       this.stablishment = this.allStablishments[0];
      }
    
      this.isLoading = false;
    },
  }
};
</script>
