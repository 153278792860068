<template> 
  <div>
    <Gym v-if="isGym()" 
      :users="users" 
      :role="['gym','teacher']" 
      :mobile="isMobile" 
      :showFilters="false"
      @refresh="getAll()"/>
  </div>
</template>

<script>
import { getAllAdminAction } from "@/pages/Users/actions";


import { Restaurant, Gym, GymMobile } from "@/pages/Users";
import permissionsMixin from '@/mixins/permissionsMixin';

export default {
  mixins: [permissionsMixin],
  components: {
    Restaurant,
    GymMobile,
    Gym
  },
  data() {
    return {
      isMobile: false,
      users:[]
    };
  },
  created(){
    this.getAll();
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768; // Detecta si es un dispositivo móvil (puedes ajustar el ancho según tus necesidades)
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    async getAll() {
      try {
        const response = await getAllAdminAction();
        this.users = response.users;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    }
  }
};
</script>


