import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";

// Función genérica para manejar las peticiones
const handleRequest = async (url) => {
  try {
    const { data } = await wencesApi.get(url);

    return {
      ok: true,
      data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Acceso no autorizado",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getMostOrderedProducts = async (period) => {
  return await handleRequest(`/api/stats/products/${period}`);
};

export const getTopCustomers = async (period) => {
  return await handleRequest(`/api/stats/customers/${period}`);
};

export const getTotalSales = async (period) => {
  return await handleRequest(`/api/stats/sales/${period}`);
};

export const getAverageOrderValue = async (period) => {
  return await handleRequest(`/api/stats/average-order-value/${period}`);
};

export const getOrderStatusCounts = async () => {
  return await handleRequest(`/api/stats/order-status`);
};
