<template>
  <div class="card p-4 w-100">
    <h5 class="mb-4" v-if="!subscription" >Secure payment with Stripe</h5>

    <!-- Botón para suscribirse -->
    <md-button class="md-raised md-primary" @click="suscribirse()" :disabled="isLoading"  v-if="!subscription" >
      {{$t('subscribe')}}
      <div class="loading-container mr-2" v-if="isLoading">
        <md-progress-spinner 
          :md-diameter="20" 
          md-mode="indeterminate" 
          class="md-secondary mr-2">
        </md-progress-spinner>
      </div>
    </md-button>
    <md-button class="md-raised md-danger p-1" @click="unsuscribirse()" :disabled="isLoading"  v-else >
      {{$t('unsubscribe')}}
    </md-button>

    <span v-if="errorMessage" class="error">{{ errorMessage }}</span>
  </div>
</template>

<script>
import { wencesApi } from "@/api/wencesApi"; // Importa tu API para hacer peticiones al backend

import { createSubscription,cancelSubscription } from "@/pages/Stripe/actions"; // Importa la función de suscripción de Stripe
import { loadStripe } from "@stripe/stripe-js";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isLoading: false, // Estado para controlar el loading
      errorMessage: "", // Para manejar errores
    };
  },
  props: {
    subscription:Boolean,
    plan:String
  },
  created(){

  },
  methods: {
    unsuscribirse() {
        Swal.fire({
          title: this.$t('areYouSure'),
          text: this.$t('youWontBeAbleToRevertThis'),
          type: "warning",
          position: 'top',
          confirmButtonText: this.$t('unsubscribe')
        }).then((result) => {
              this.cancel();
          });
    },
    async cancel(){
      const response = await cancelSubscription();
      window.location.reload();
    },
    async suscribirse() {
      this.isLoading = true; // Mostrar el loading
      this.errorMessage = ""; // Resetear el mensaje de error

      try {
        const response = await createSubscription(this.plan); // Reemplaza con el correo del cliente

        if (response.data.url) {
          // Redirigir al usuario a la página de Stripe Checkout
          window.location.href = response.data.url;
        } else {
          this.errorMessage = "Error al crear la sesión de Checkout";
        }
      } catch (error) {
        console.error("Error al crear la sesión de Checkout:", error);
        this.errorMessage = "Error inesperado al crear la sesión de Checkout";
      } finally {
        this.isLoading = false; // Ocultar el loading
      }
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
