<template>
  <div>
  <div class="">
     <div class="" v-if="edit">
          <md-button  class="md-primary md-sm" @click="saveInvoice()">{{$t('save')}}</md-button>
      </div>
    <div class="w-100">
      <md-button class="md-primary md-sm" @click="downloadPDF()">Download PDF</md-button>
    </div>
    </div>
   <div class="receipt" ref="receiptContent">
    <!-- Editable Business Name -->
    <h3 v-if="edit" @click="editField('businessName')" class="editable">
      <span v-if="!isEditing('businessName')" :class="{ 'editable-field': edit }">{{ businessName }}<md-icon class="text-primary">edit</md-icon></span>
      <input v-else v-model="businessName" @blur="saveField" />
    </h3>
    <h3 v-else>{{ businessName }}</h3>

    <!-- Editable NIF -->
    <p>
      <span v-if="edit" @click="editField('nifLabel')" class="editable">
        <span v-if="!isEditing('nifLabel')" :class="{ 'editable-field': edit }">{{ nifLabel }}:<md-icon class="text-primary">edit</md-icon></span>
        <input v-else v-model="nifLabel" @blur="saveField" />
      </span>
      <span v-else>{{ nifLabel }}:</span>

      <!-- Editable NIF Number -->
      <span v-if="edit" @click="editField('nif')" class="editable">
        <span v-if="!isEditing('nif')" :class="{ 'editable-field': edit }">{{ nif }}<md-icon class="text-primary">edit</md-icon></span>
        <input v-else v-model="nif" @blur="saveField" />
      </span>
      <span v-else>{{ nif }}</span>
    </p>

    <!-- Editable Address -->
    <p v-if="edit" @click="editField('address')" class="editable">
      <span v-if="!isEditing('address')" :class="{ 'editable-field': edit }">{{ address }}<md-icon class="text-primary">edit</md-icon></span>
      <input v-else v-model="address" @blur="saveField" />
    </p>
    <p v-else>{{ address }}</p>

    <!-- Editable Invoice Title -->
    <h4 v-if="edit" @click="editField('invoiceTitle')" class="editable">
      <span v-if="!isEditing('invoiceTitle')" :class="{ 'editable-field': edit }">{{ invoiceTitle }}<md-icon class="text-primary">edit</md-icon></span>
      <input v-else v-model="invoiceTitle" @blur="saveField" />
    </h4>
    <h4 v-else>{{ invoiceTitle }}</h4>

    <!-- Date and Invoice Number -->
    <p>
      {{ $t('dateLabel') }}: {{ dateInfo }}
    </p>

    <!-- Editable Invoice Number -->
    <p>
      <span v-if="edit" @click="editField('invoiceNumber')" class="editable">
        <span v-if="!isEditing('invoiceNumber')" :class="{ 'editable-field': edit }">{{ invoiceNumber }}:<md-icon class="text-primary">edit</md-icon></span>
        <input v-else v-model="invoiceNumber" @blur="saveField" />
      </span>
      <span v-else>{{ invoiceNumber }}:</span>

      <span> #{{ ticketNumber }}</span>
    </p>

    <div class="table-info">
      <!-- Editable Table Label -->
      <p v-if="edit" @click="editField('tableLabel')" class="editable">
        <span v-if="!isEditing('tableLabel')" :class="{ 'editable-field': edit }">{{ tableLabel }} <md-icon class="text-primary">edit</md-icon></span>
        <input v-else v-model="tableLabel" @blur="saveField" />: {{ tableNumber }}
      </p>
      <p v-else>{{ tableLabel }}: {{ tableNumber }}</p>

      <!-- Editable "C:" Label -->
      <p v-if="edit" @click="editField('guestLabel')" class="editable">
        <span v-if="!isEditing('guestLabel')" :class="{ 'editable-field': edit }">{{ guestLabel }}<md-icon class="text-primary">edit</md-icon></span>
        <input v-else v-model="guestLabel" @blur="saveField" />: {{ guests }}
      </p>
      <p v-else>{{ guestLabel }}: {{ guests }}</p>

      <!-- Editable Waiter Label -->
      <p v-if="edit" @click="editField('waiterLabel')" class="editable">
        <span v-if="!isEditing('waiterLabel')" :class="{ 'editable-field': edit }">{{ waiterLabel }}<md-icon class="text-primary">edit</md-icon></span>
        <input v-else v-model="waiterLabel" @blur="saveField" />: {{ waiterId }} - {{ waiterName }}
      </p>
      <p v-if="!edit && waiterName">{{ waiterLabel }}: {{ waiterId }} - {{ waiterName }}</p>
    </div>
    <!-- Table of Items -->
    <table>
      <tbody>
        <tr>
          <th>{{ $t('description') }}</th>
          <th>{{ $t('quantity') }}</th>
          <th>{{ $t('price') }}</th>
          <th>{{ $t('total') }}</th>
        </tr>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ item.quantity }}</td>
          <td>{{ item.price }} {{currencySymbol}}</td>
          <td>{{ (item.price*item.quantity) }} {{currencySymbol}}</td>
        </tr>
      </tbody>
    </table>

    <!-- Editable Total Without Tax Label -->
    <p v-if="edit" @click="editField('totalWithoutTaxLabel')" class="editable">
      <span v-if="!isEditing('totalWithoutTaxLabel')" :class="{ 'editable-field': edit }">{{ totalWithoutTaxLabel }}<md-icon class="text-primary">edit</md-icon></span>
      <input v-else v-model="totalWithoutTaxLabel" @blur="saveField" />: {{ totalWithoutTax }}
    </p>
    <p v-else>{{ totalWithoutTaxLabel }}: {{ totalWithoutTax.toFixed(2) }} {{currencySymbol}}</p>

    <!-- Editable Tax Label -->
    <p v-if="edit" @click="editField('taxLabel')" class="editable">
      <span v-if="!isEditing('taxLabel')" :class="{ 'editable-field': edit }">{{ taxLabel }}<md-icon class="text-primary">edit</md-icon></span>
      <input v-else v-model="taxLabel" @blur="saveField" />: {{ taxAmount }} 
    </p>
    <p v-else>{{ taxLabel }}: {{ taxAmount.toFixed(2) }} {{currencySymbol}}</p>

    <!-- Total -->
    <h3>{{ totalLabel }}: {{ total.toFixed(2) }} {{currencySymbol}}</h3>

    <!-- Editable Tip Label -->
    <p v-if="edit" @click="editField('tipLabel')" class="editable">
      <span v-if="!isEditing('tipLabel')" :class="{ 'editable-field': edit }">{{ tipLabel }}<md-icon class="text-primary">edit</md-icon>: {{ tip }}</span>
      <input v-else v-model="tipLabel" @blur="saveField" />
    </p>
    <p v-else>{{ tipLabel }}: {{ tip }}</p>

    <!-- Editable Thank You Message -->
    <p v-if="edit" @click="editField('thankYouMessage')" class="editable">
      <span v-if="!isEditing('thankYouMessage')" :class="{ 'editable-field': edit }">{{ thankYouMessage }}<md-icon class="text-primary">edit</md-icon></span>
      <input v-else v-model="thankYouMessage" @blur="saveField" />
    </p>
    <p v-else>{{ thankYouMessage }}</p>
  </div>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js';


export default {
  props:{
    edit: {
      type: Boolean,
      default: false
    },
    invoice: {
      type: Object
    },
    products: {
      type: Array
    },
    total: {
      type: Number,
      default: 120,
    },
    currency: {
      type: String,
      default: "€",
    },
    tableNumber: {
      type: String,
      default: "18",
    },
    waiterName: {
      type: String,
      default: false,
    },
    date: {
      type: String,
      default: false,
    },
    tax: {
      type: Number,
      default: 10,
    },
    guests: {
      type: Number,
      default: 1,
    },
    defaultBusinessName: {
      type: String
    },
  },
  data() {
    return {
      // Labels (Modificables)
      invoiceTitle: this.$t('invoiceTitle'),
      dateLabel: this.$t('dateLabel'),
      invoiceNumber: this.$t('invoiceNumberLabel'),
      tableLabel: this.$t('tableLabel'),
      guestLabel: this.$t('guestLabel'),
      waiterLabel: this.$t('waiterLabel'),
      totalWithoutTaxLabel: this.$t('totalWithoutTaxLabel'),
      taxLabel: this.$t('taxLabel'),
      totalLabel: this.$t('totalLabel'),
      tipLabel: this.$t('tipLabel'),
      thankYouMessage: this.$t('thankYouMessage'),
      nifLabel: this.$t('nifLabel'),

      // Datos del negocio (Modificables)
      businessName: "NUN IBIZA S.L",
      nif: "B44674802",  // Editable NIF number
      address: "C/ALGARB 8 - 07800 IBIZA",
      section: "Terraza Interior",
      dateInfo:false,



      ticketNumber: "Ticket",  // Editable Invoice number

      // Productos
      items: [
        {quantity: 1, name: "PAN", quantity: 1, price: 6.0, total: 6.0 },
        {quantity: 1,name: "PULPO", quantity: 1, price: 38.0, total: 38.0 },
        {quantity: 1, name: "TOMATE BURRATA", quantity: 1, price: 26.0, total: 26.0 },
        {quantity: 1, name: "TACOS CORVINA", quantity: 1, price: 28.0, total: 28.0 },
        {quantity: 1, name: "APPLE CRUMBLE", quantity: 1, price: 14.0, total: 14.0 },
        {quantity: 1, name: "SANT ANIOL", quantity: 2, price: 4.0, total: 8.0 },
        {quantity: 1, name: "Sale Como Sale", quantity: 1, price: 0.0, total: 0.0 }
      ],
      totalWithoutTax: 109.09,
      taxAmount: 10.91,
      tip: "",

      // Estado para edición
      editingField: null,
    };
  },
  computed: {
    currencySymbol() {
      const selectedCurrency = this.$currencies.find(option => option.value === this.currency);
      return selectedCurrency ? selectedCurrency.symbol : this.currency;
    },
  },
  created(){

      let fechaActual = new Date();
      if(this.date){
           fechaActual = new Date(this.date);
      }
      this.ticketNumber = this.generarIDUnicoConPrefijo('');

      let dia = fechaActual.getDate();
      let mes = fechaActual.getMonth() + 1; // Los meses comienzan desde 0
      let año = fechaActual.getFullYear();
      let horas = fechaActual.getHours();
      let minutos = fechaActual.getMinutes();
      let segundos = fechaActual.getSeconds();

      // Agregar ceros si el número es menor que 10
      if (dia < 10) dia = '0' + dia;
      if (mes < 10) mes = '0' + mes;
      if (horas < 10) horas = '0' + horas;
      if (minutos < 10) minutos = '0' + minutos;
      if (segundos < 10) segundos = '0' + segundos;

      this.dateInfo = `${dia}/${mes}/${año} ${horas}:${minutos}`;
    

    this.taxAmount = parseFloat(this.total * this.tax / 100);
    this.totalWithoutTax = this.total - this.taxAmount
    if(this.products){
      this.items = this.products
    }
  
    if(this.defaultBusinessName){
      this.businessName = this.defaultBusinessName
    }
   // Si la prop invoice tiene datos, sobrescribir los campos con la información de invoice
    if (this.invoice) {
      this.businessName = this.invoice.businessName || this.businessName;
      this.nif = this.invoice.nif || this.nif;
      this.address = this.invoice.address || this.address;
      this.invoiceTitle = this.invoice.invoiceTitle || this.invoiceTitle;
      this.invoiceNumber = this.invoice.invoiceNumber || this.invoiceNumber;
      this.tableLabel = this.invoice.tableLabel || this.tableLabel;
      this.guestLabel = this.invoice.guestLabel || this.guestLabel;
      this.waiterLabel = this.invoice.waiterLabel || this.waiterLabel;
      this.totalWithoutTaxLabel = this.invoice.totalWithoutTaxLabel || this.totalWithoutTaxLabel;
      this.taxLabel = this.invoice.taxLabel || this.taxLabel;
      this.tipLabel = this.invoice.tipLabel || this.tipLabel;
      this.thankYouMessage = this.invoice.thankYouMessage || this.thankYouMessage;
      this.total = this.invoice.total || this.total;
      this.taxAmount = this.invoice.taxAmount || this.taxAmount;
    }
  },
  methods: {
    downloadPDF() {
      const element = this.$refs.receiptContent;
      const opt = {
        margin: 1,
        filename: 'invoice.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };
      html2pdf().from(element).set(opt).save();
    },
    generarIDUnicoConPrefijo(prefijo) {
        let fecha = new Date();

        // Crear un ID concatenando año, mes, día, horas, minutos y segundos
        let id = `${fecha.getFullYear()}${(fecha.getMonth() + 1).toString().padStart(2, '0')}${fecha.getDate().toString().padStart(2, '0')}${fecha.getHours().toString().padStart(2, '0')}${fecha.getMinutes().toString().padStart(2, '0')}${fecha.getSeconds().toString().padStart(2, '0')}`;
        
        return parseInt(id); // Convertirlo a número entero
    },
    // Activar edición de campo
    editField(fieldName) {
      this.editingField = fieldName;
    },
    // Guardar el campo al salir del input
    saveField() {
      this.editingField = null;
    },
    // Verifica si un campo está siendo editado
    isEditing(fieldName) {
      return this.editingField === fieldName;
    },
    // Emitir el objeto de factura con los campos modificables
    saveInvoice() {
      const invoice = {
        businessName: this.businessName,
        nif: this.nif,
        address: this.address,
        invoiceTitle: this.invoiceTitle,
        invoiceNumber: this.invoiceNumber,
        tableLabel: this.tableLabel,
        guestLabel: this.guestLabel,
        waiterLabel: this.waiterLabel,
        totalWithoutTaxLabel: this.totalWithoutTaxLabel,
        taxLabel: this.taxLabel,
        tipLabel: this.tipLabel,
        thankYouMessage: this.thankYouMessage
      };

      this.$emit('save-invoice', invoice);
    }
  }
};
</script>


<style scoped>
.editable-field {
  border: 1px dashed #ccc;
  cursor: pointer;
}

.receipt {
  background: white;
  font-family: Arial, sans-serif;
  width: 10cm;
  margin: 0 auto;
  text-align: left;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
h3, h4 {
  margin: 0;
  text-align: center;
}
p, table {
  margin: 5px 0;
}
table {
  width: 100%;
  border-collapse: collapse;
}
table, th, td {
  border: 1px solid black;
}
th, td {
  text-align: left;
  padding: 5px;
}
.table-info {
  background: white;
  margin: 10px 0;
}
input {
  width: 100%;
  border: 1px solid #ddd;
  padding: 2px;
}
.editable-field {
  border: 1px dashed #ccc;
  padding: 2px;
  cursor: pointer;
}
</style>

