<template>
    <div class="md-layout-item md-size-25 md-small-size-100">
      <md-card class="bg-transparent border-0">
        <md-card-header class="md-card-header-icon md-card-header-white">
          <div class="card-icon bg-white">
            <md-icon><img class="img cooking" :src="img" /></md-icon>
          </div>
          <h4 class="title">{{title}}</h4>
        </md-card-header>
        <md-card-content class="p-0">
          <div class="md-layout" v-if="orders.length > 0">
            <div class="p-1 md-layout-item md-size-100" 
              v-for="order in orders" :key="order._id">
              <order-card @change="changeOrderStatus" :data="order" :currency="currency" class="card-order"></order-card>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
</template>

<script>
import { OrderCard } from "@/components";
import { getAllAction } from "@/pages/Orders/actions";

export default {
  name: "order-list",
  components: {
    OrderCard
  },
  props: {
    title: {
      type: String,
      default: () => {
        return '';
      }
    },
    img: {
      type: String,
      default: () => {
        return '';
      }
    },
    color: {
      type: String,
      default: () => {
        return '';
      }
    },
    orders: {
      default: () => {
        return [];
      }
    },
    currency: {
      type: String,
      default: '$'
    },
  },
  methods: {
    changeOrderStatus(order, status) {
      this.$emit("change", order, status);
    }
  },
};
</script>


<style scoped>
.table-transparent {
  background-color: transparent !important;
}



.mt-0 {
  margin-top: 0 !important;
}
</style>