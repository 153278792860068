<template>
  <div>
    <div class="p-3 card stripe-payment" v-if="status !== 'succeeded'">
      <h4>{{ $t('completePayment') }}</h4>
      <!-- Contenedor para el Payment Request Button (Apple Pay, Google Pay, etc.) -->
      <div v-if="paymentRequestAvailable" ref="paymentRequestButton"></div>

      <!-- Si Payment Request no está disponible, muestra el Card Element -->
      <form v-if="!paymentRequestAvailable" @submit.prevent="handlePayment">
        <!-- Stripe Card Element -->
        <CardElement ref="cardElement"></CardElement>

        <!-- Botón para confirmar el pago -->
        <button type="submit" :disabled="processing || !cardComplete">
          {{ processing ? 'Processing...' : $t('pay') }}
        </button>
      </form>

      <!-- Mensajes de error y éxito -->
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { CardElement } from 'vue-stripe-elements-plus';
import Swal from 'sweetalert2';

export default {
  name: 'StripePayment',
  components: {
    CardElement,
  },
  props: {
    clientSecret: String,
    amount: Number,
    country: String,
    currency: String,
    name: String,
  },
  data() {
    return {
      status: false,
      stripe: null,
      elements: null,
      cardElement: null,
      paymentRequest: null,
      paymentRequestAvailable: false,
      processing: false,
      errorMessage: null,
      successMessage: null,
      cardComplete: false, // Nuevo estado para monitorear la validez de la tarjeta
    };
  },
  async mounted() {
    // Cargar Stripe.js con tu clave pública
    const stripeKey = 'pk_live_51PJcI5AYqZCdtgzggWmveaJyajHfc3eG2QCTT0koIn3B3ZcjPRjvHwyAuvu7aue44nfh8WTUUfikPP8ILGjerPew00OynXBy0i';
    //'pk_test_51PJcI5AYqZCdtgzggj6v5KTBxrjp8DpkisUhp6DtxarWvFiVRdRecGEhv9VaOMJ5uPEhFoPa0HCt2F2SM0t5Z9iV00Ho4HbVaK';

    this.stripe = await loadStripe(stripeKey);

    // Crear un conjunto de elementos de Stripe
    this.elements = this.stripe.elements();

    // Crear el Payment Request para Apple Pay/Google Pay
    this.paymentRequest = this.stripe.paymentRequest({
      country: this.country,
      currency: this.currency,
      total: {
        label: 'Total',
        amount: this.amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    // Verificar si el Payment Request es compatible
    const result = await this.paymentRequest.canMakePayment();
    
    if (result) {
      this.paymentRequestAvailable = true;
      this.$nextTick(() => {
        const prButton = this.elements.create('paymentRequestButton', {
          paymentRequest: this.paymentRequest,
          style: {
            paymentRequestButton: {
              type: 'default',
              theme: 'dark',
              height: '40px',
            },
          },
        });
        prButton.mount(this.$refs.paymentRequestButton);
      });

      // Manejar el pago cuando se hace con Payment Request (Apple Pay/Google Pay)
      this.paymentRequest.on('paymentmethod', async (ev) => {
        const { error, paymentIntent } = await this.stripe.confirmCardPayment(this.clientSecret, {
          payment_method: ev.paymentMethod.id,
        });

        if (error) {
          ev.complete('fail');
          this.errorMessage = error.message;
        } else {
          ev.complete('success');
          if (paymentIntent.status === 'succeeded') {
            this.status = paymentIntent.status;
            this.successMessage = 'Pago completado con éxito.';
            Swal.fire({
              title: this.$t('success'),
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              window.location.reload();
            });
          }
        }
      });
    } else {
      // Si Payment Request no está disponible, inicializamos el Card Element
      this.$nextTick(() => {
        this.cardElement = this.elements.create('card');
        this.cardElement.mount(this.$refs.cardElement);

        // Monitorea cambios en el estado del Card Element
        this.cardElement.on('change', (event) => {
          this.cardComplete = event.complete;
          if (event.error) {
            this.errorMessage = event.error.message;
          } else {
            this.errorMessage = null;
          }
        });
      });
    }

    if (!this.clientSecret) {
      this.errorMessage = 'Error: clientSecret no proporcionado.';
    }
  },
  methods: {
    async handlePayment() {
      this.processing = true;
      this.errorMessage = null;
      this.successMessage = null;

      const { error, paymentIntent } = await this.stripe.confirmCardPayment(this.clientSecret, {
        payment_method: {
          card: this.cardElement,
          billing_details: {
            name: this.name,
          },
        },
      });

      if (error) {
        this.errorMessage = error.message;
      } else if (paymentIntent.status === 'succeeded') {
        this.successMessage = 'Pago completado con éxito.';
        this.status = paymentIntent.status;
        Swal.fire({
          title: this.$t('success'),
          icon: 'success',
          confirmButtonText: 'OK',
        });
        // Aquí puedes redirigir al usuario o realizar otras acciones después del pago
      }

      this.processing = false;
    },
  },
};
</script>


<style scoped>
.stripe-payment {
  max-width: 400px;
  margin: 0 auto;
}
button {
  margin-top: 20px;
  padding: 10px;
  background-color: #6772e5;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button:disabled {
  background-color: #bbb;
}
.error-message {
  color: red;
  margin-top: 10px;
}
.success-message {
  color: green;
  margin-top: 10px;
}
</style>
