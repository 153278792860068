<template>
  <div class="md-layout text-center mt-4">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <form @submit.prevent="handleSubmit">
        <login-card header-color="white">
          <div slot="title" class="image-container logo">
            <img :src="logo" title="" />
          </div>
          <md-field class="md-form-group" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>{{ $t('new_password') }}</label>
            <md-input v-model="password" type="password"></md-input>
          </md-field>
          <md-field class="md-form-group" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>{{ $t('repeat_password') }}</label>
            <md-input v-model="confirmPassword" type="password"></md-input>
          </md-field>

          <div slot="footer">
            <div v-if="errorMessage" class="error-message">
              {{ errorMessage }}
            </div>
            <md-button @click="changePassword" class="md-primary md-lg">
              {{ $t('change_password') }}
            </md-button>

            <div class="my-3"></div>
            <router-link class="md-info md-simple mb-2" :to="{ path: '/login' }">  
            {{ $t('login') }}
           </router-link> 
          </div>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
import { LoginCard } from "@/components";
import { changePassword } from "@/pages/Auth/actions";
import Swal from "sweetalert2";

export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      password: null,
      confirmPassword: null,
      logo: './img/gotakeit.png',
      errorMessage: null,
    };
  },
  methods: {
    validatePassword(password) {
      const minLength = 6;
      const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/g;

      if (password.length < minLength) {
        return this.$t('password_too_short');
      } else if (!hasSymbol.test(password)) {
        return this.$t('password_needs_symbol');
      }

      return null;
    },
    changePassword() {
      const passwordError = this.validatePassword(this.password);

      if (passwordError) {
        this.errorMessage = passwordError;
        return;
      }

      if (this.password !== this.confirmPassword) {
        this.errorMessage = this.$t('passwords_do_not_match');
        return;
      }

      this.errorMessage = null;

      changePassword(this.password,this.$router.currentRoute.params.token)
        .then((response) => {
          if(response.ok){
            Swal.fire(this.$t('password_changed'), '', 'success');
            this.$router.push('/login');
          }else{
            Swal.fire(this.$t('tokenExpired'), this.$t('tryAgain'), 'error');
            this.$router.push('/login');
          }
        })
        .catch(() => {
          this.errorMessage = this.$t('error_changing_password');
        });
    },
  },
};
</script>

<style scoped>
.image-container {
  max-width: 400px;
}

.error-message {
  color: red;
  margin-top: 10px;
}
</style>
