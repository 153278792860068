<template>
  <div class="md-layout px-2">
    <div class="mb-4 md-layout-item md-size-100 md-small-size-100 w-100">
      <md-button class="md-primary md-sm" @click="showNewProductForm = true;showNewCategoryForm = false; resetProduct()">+ {{ $t('addProduct') }}</md-button>
      <md-button class="md-primary md-sm" @click="showNewCategoryForm = true;showNewProductForm = false">+ {{ $t('addCategory') }}</md-button>
    </div>

    <div class="md-layout-item md-size-50 md-xsmall-size-100  mt-4 px-2">
     

      <!-- Formulario para añadir nuevo producto -->
      <div v-if="showNewProductForm" class="md-layout-item md-size-100 mt-4" ref="newProductForm">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-rose">
            <div class="card-icon">
              <md-icon>add_circle</md-icon>
            </div>
            <h4 class="title">{{ isEditingProduct ? $t('editProduct') : $t('addNewProduct') }}</h4>
          </md-card-header>
          <md-card-content>
            <p class="p-2 md-text text-secondary"><md-icon>chat</md-icon> "{{$t('producthelp')}}."  <router-link class="md-primary" :to="{ path: '/menus' }">  
                     {{$t('menus')}} <md-icon class="md-primary">reply</md-icon>
                    </router-link></p>
                   

            <div class="md-layout mt-3">
              <div class="md-layout-item md-size-100 md-xsmall-size-100  mr-2">
              <label class="text-secondary">{{ $t('productName') }}</label>
              <div class="card p-2">
                <md-field>
                  <md-input v-model="newProduct.name" :placeholder="$t('productName')"></md-input>
                </md-field>
                <div v-for="(translation, index) in newProduct.nameTranslations" :key="index" class="name-input">
                    <md-field>
                        <label>{{ getCountryName(translation.language) }}</label>
                        <md-input v-model="translation.value" :placeholder="translation.language"></md-input>
                    </md-field>
                    <md-field>
                        <md-select v-model="translation.language">
                        <md-option 
                          v-for="country in languages" :key="country.code" :value="country.code">
                          {{ country.name }}
                        </md-option>
                        </md-select>

                        <md-button v-if="translation.value && index > 0" class="md-icon-button md-primary py-1" @click="removeInput(newProduct.nameTranslations, index)">-</md-button>
                        <md-button class="md-icon-button md-primary py-1" @click="addInput(newProduct.nameTranslations)">+</md-button>
                    </md-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="md-layout my-3">
              <div class="md-layout-item md-size-100 md-xsmall-size-100  mr-2">
              <label class="text-secondary">{{ $t('description') }}</label>
              <div class="card p-2">
                <md-field>
                  <md-input v-model="newProduct.description" :placeholder="$t('description')"></md-input>
                </md-field>
                <div v-for="(translation, index) in newProduct.descriptionTranslations" :key="index" class="name-input">
                    <md-field>
                        <label>{{ getCountryName(translation.language) }}</label>
                        <md-input v-model="translation.value" :placeholder="translation.language"></md-input>
                    </md-field>
                    <md-field>
                        <md-select v-model="translation.language">
                        <md-option 
                          v-for="country in languages" :key="country.code" :value="country.code">
                          {{ country.name }}
                        </md-option>
                        </md-select>

                        <md-button v-if="translation.value && index > 0" class="md-icon-button md-primary py-1" @click="removeInput(newProduct.descriptionTranslations, index)">-</md-button>
                        <md-button class="md-icon-button md-primary py-1" @click="addInput(newProduct.descriptionTranslations)">+</md-button>
                    </md-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="card p-3">
            <md-field>
              <label>{{ $t('price') }}</label>
              <md-input v-model="newProduct.price" :placeholder="$t('productPrice')"></md-input>
            </md-field>
            <md-field>
              <label>{{ $t('category') }}</label>
              <md-select v-model="newProduct.categoryId">
                <md-option v-for="category in categoriesData" :key="category._id" :value="category._id">
                  {{ category.name }}
                </md-option>
              </md-select>
            </md-field>
            </div>

            <div class="card p-3 my-4">
            <label class="text-secondary">{{ $t('selectImage') }}</label>
            <div class="md-layout-item">
              <div class="file-input">
                <div class="button-container">
                  <md-button class="md-danger md-sm md-round" @click="removeImage" v-if="imageRegular">
                    <i class="fa fa-times"></i>{{ $t('remove') }}
                  </md-button>
                  <md-button class="md-success md-sm md-round md-fileinput">
                    <template v-if="!imageRegular">{{ $t('selectImage') }}</template>
                    <template v-else>{{ $t('change') }}</template>
                    <input type="file" accept=".png, .jpg, .jpeg" @change="onFileChange" />
                  </md-button>
                </div>
                <div v-if="!imageRegular">
                  <div>
                    <img :src="regularImg" title="" />
                  </div>
                </div>
                <div v-else>
                  <img :src="imageRegular" />
                </div>
              </div>
            </div>

            </div>
            <md-button class="md-primary md-sm" @click="saveProduct">{{ isEditingProduct ? $t('save') : $t('add') }}</md-button>
            <md-button class="md-danger md-sm" @click="cancelNewProduct">{{ $t('cancel') }}</md-button>
          </md-card-content>
        </md-card>
      </div>

      <div class="md-layout-item md-size-100 mt-4" v-if="!editingProductId">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-rose">
            <div class="card-icon">
              <md-icon>lunch_dining</md-icon>
            </div>
            <h4 class="title">{{ $t('inventory') }}</h4>
          </md-card-header>
          <md-card-content>
            <div class="mt-4">
              <md-field>
                <label>{{ $t('selectCategoryToAssign') }}</label>
                <md-select v-model="selectedCategoryId">
                  <md-option v-for="category in categoriesData" :key="category._id" :value="category._id">
                    {{ category.name }}
                  </md-option>
                </md-select>
              </md-field>
              <md-button class="md-primary md-sm" @click="assignCategory">{{ $t('assignCategory') }}</md-button>
            </div>
            <md-table v-model="jsonData" class="md-table-global-sales d-none d-sm-block">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="">
                  <md-checkbox v-model="selectedProducts" :value="item._id"></md-checkbox>
                </md-table-cell>
                <md-table-cell><img :src="item.images[0]" title="" /> 
                  <span>{{ item.name }}</span>
                </md-table-cell>
                <md-table-cell>{{ item.price }} {{ currency }}</md-table-cell>
                <md-table-cell>{{ item.description }}</md-table-cell>
                <md-table-cell>{{ item.category ? item.category.name : $t('noCategory') }}</md-table-cell>
                <md-table-cell>
                  <md-button class="md-just-icon md-simple md-primary mb-3" @click="editProduct(item._id)">
                    <md-icon>edit</md-icon>
                  </md-button>
                  <md-button class="md-just-icon md-simple md-danger" @click="confirmDeleteProduct(item._id)">
                    <md-icon>delete</md-icon>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>

            <div v-for="item in jsonData" :key="item._id" class="d-block d-sm-none card p-2 my-2">
                <div>
                  <md-checkbox v-model="selectedProducts" :value="item._id"></md-checkbox>
                  <md-button class="md-just-icon  md-primary mb-3" @click="editProduct(item._id)">
                    <md-icon>edit</md-icon>
                  </md-button>
                  <md-button class="md-just-icon  md-danger" @click="confirmDeleteProduct(item._id)">
                    <md-icon>delete</md-icon>
                  </md-button>
                </div>
                <div v-if="item.images[0]"><img :src="item.images[0]" title="" /></div>
                <div> <h5>{{ item.name }}</h5></div>
                <div><span class="badge badge-success">{{ item.price }} {{ currency }}</span></div>
                <div>{{ item.description }}</div>
                <div><strong class="text-primary">{{ item.category ? item.category.name : $t('noCategory') }}</strong></div>
            </div>

          </md-card-content>
        </md-card>
      </div>
    </div>

    <div class="md-layout-item md-size-50 md-xsmall-size-100   mt-4"> 

    <!-- Formulario para añadir nueva categoría -->
      <div v-if="showNewCategoryForm" class="md-layout-item md-size-100 mt-4" ref="editCategoryForm">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-rose">
            <div class="card-icon">
              <md-icon>add_circle</md-icon>
            </div>
            <h4 class="title">{{ isEditingCategory ? $t('editCategory') : $t('addNewCategory') }}</h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-xsmall-size-100  mr-2">
                <md-field>
                  <label>{{ $t('categoryName') }}</label>
                  <md-input v-model="newCategory.name" :placeholder="$t('categoryName')"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50 md-xsmall-size-100 ">
                <div v-for="(translation, index) in newCategory.nameTranslations" :key="index" class="name-input">
                  <md-field>
                    <label>{{ getCountryName(translation.language) }}</label>
                    <md-input v-model="translation.value" :placeholder="$t('translation')+' ('+translation.language+')'"></md-input>
                  </md-field>
                  

                    <md-field>
                    <md-select v-model="translation.language">
                    <md-option 
                      v-for="country in languages" :key="country.code" :value="country.code">
                      {{ country.name }}
                    </md-option>
                    </md-select>  
                    </md-field>


                  <md-button v-if="translation.value && index > 0" class="md-icon-button md-primary py-1" @click="removeInput(newCategory.nameTranslations, index)">-</md-button>
                  <md-button class="md-icon-button md-primary py-1" @click="addInput(newCategory.nameTranslations)">+</md-button>
                </div>
              </div>
                <div class="float-left md-layout-item md-size-25 md-small-size-50 icon-food-select">
                  <md-field>
                    <label>Icon</label>
                    <md-select v-model="newCategory.icon" >
                      <md-option v-for="icon in icons" :key="icon" :value="icon">
                        <div class="icon-option">
                          <img :src="icon" width="40px" height="40px" alt="Icon Image" />
                        </div>
                      </md-option>
                    </md-select>
                    <div class="icon-food">
                      <img v-if="newCategory.icon" :src="newCategory.icon" alt="Selected Icon" />
                    </div>
                  </md-field>
                </div>

            </div>

            <md-button class="md-primary md-sm" @click="saveCategory">{{ isEditingCategory ? $t('save') : $t('add') }}</md-button>
            <md-button class="md-danger md-sm" @click="cancelNewCategory">{{ $t('cancel') }}</md-button>
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item md-size-100 mt-4" v-if="!showNewCategoryForm">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-rose">
            <div class="card-icon">
              <md-icon>category</md-icon>
            </div>
            <h4 class="title">{{ $t('categories') }}</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="categoriesData" class="md-table-global-sales">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell><img style="width:30px !important;height:30px !important" :src="item.icon" title="" /> {{ item.name }}</md-table-cell>
                <md-table-cell>{{ item.identifier }}</md-table-cell>
                <md-table-cell>
                  <md-button class="md-just-icon md-simple md-primary" @click="editCategory(item._id)">
                    <md-icon>edit</md-icon>
                  </md-button>
                  <md-button class="md-just-icon md-simple md-danger" @click="confirmDeleteCategory(item._id)">
                    <md-icon>delete</md-icon>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { getAllProductAction, saveAllAction, uploadImageAction } from "@/pages/Products/actions";
import { getAllCategoriesAction } from "@/pages/Categories/actions";
import { getSettingsAction } from "@/pages/Auth/actions";
import permissionsMixin from '@/mixins/permissionsMixin';

export default {  
  mixins: [permissionsMixin],
  created() {
    this.getAll();

    const requireImages = require.context('/public/img/food_icons', false, /\.png$/);
    this.icons = requireImages.keys().map(requireImages);


  },
  data() {
    return {
      icons:[],
      imageRegular: "",
      regularImg:"",
      jsonData: [],
      currency:"",
      translationsCat:'',
      description: '',
      translations: '',
      categoriesData: [],
      editingProductId: null,
      editingCategoryId: null,
      showNewProductForm: false,
      showNewCategoryForm: false,
      showSaveButton: false,
      newProduct: {
        _id: null,
        name: '',
        description: '',
        nameTranslations: [{ language: 'ES', value: '' }],
        descriptionTranslations: [{ language: 'ES', value: '' }],
        price: '',
        categoryId: ''
      },
      newCategory: {
        name: '',
        nameTranslations: [{ language: 'ES', value: '' }],
        identifier: '',
        icon: ''
      },
      selectedProducts: [],
      selectedCategoryId: ''
    };
  },
  watch: {
    jsonData: {
      handler() {
        this.showSaveButton = true;
      },
      deep: true
    }
  },
  computed: {

    languages() {
      return this.$languages;
    },
    categories() {
      return this.categoriesData.map(category => ({
        ...category,
        products: this.jsonData.filter(product => {
          return product.category && product.category._id && category._id && product.category._id === category._id;
        })
      }));
    },
    isEditingProduct() {
      return this.editingProductId !== null;
    },
    isEditingCategory() {
      return this.editingCategoryId !== null;
    }
  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.selectedFile = files[0];
      this.createImagePreview(this.selectedFile); // Método para mostrar la vista previa de la imagen
    },
    createImagePreview(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageRegular = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async getAll() {
      try {
        const response = await getAllProductAction();
        this.jsonData = response.products;
        const response2 = await getAllCategoriesAction();
        this.categoriesData = response2.categories;

        const response3 = await getSettingsAction();
        const data = response3.result;
        this.currency = data.currency;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    async assignCategory() {      

      if(this.isDemo()){
        return;
      }

      if (!this.selectedCategoryId || this.selectedProducts.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: this.$t('pleaseSelectCategoryAndProduct'),
        });
        return;
      }

      this.selectedProducts.forEach(productId => {
        const product = this.jsonData.find(p => p._id === productId);
        if (product) {
          const category = this.categoriesData.find(c => c._id === this.selectedCategoryId);
          if (category) {
            product.category = {
              _id: category._id,
              name: category.name
            };
          }
        }
      });

      this.updateJson();
      this.selectedProducts = [];
      this.selectedCategoryId = '';

      // Guardar todos los productos y categorías antes de refrescar
      await this.saveAllChanges();
      // Refrescar los productos después de guardar
      await this.refreshProducts();
    },
    removeImage() {
      if(this.isDemo()){
        return;
      }
      this.newProduct.images = []
      this.imageRegular = false
    },
    editProduct(productId) {
      this.editingCategoryId = null;
      this.editingProductId = productId;
      const product = this.jsonData.find(p => p._id === productId);
      if (product) {
        if (product.nameTranslations == undefined || product.nameTranslations.length == 0) {
          this.translations = [{ language: 'ES', value: '' }];
        } else {
          this.translations = product.nameTranslations;
        }

        if (product.descriptionTranslations == undefined || product.descriptionTranslations.length == 0) {
          this.description = [{ language: 'ES', value: '' }];
        } else {
          this.description = product.descriptionTranslations;
        }
        this.imageRegular = product.images[0]
        this.newProduct = {
          _id: product._id,
          nameTranslations: this.translations,
          descriptionTranslations: this.description,
          name: product.name,
          price: product.price,
          images: product.images,
          description: product.description,
          categoryId: product.category ? product.category._id : ''
        };

        this.showNewProductForm = true;
        this.showNewCategoryForm = false;

        this.$nextTick(() => {
          this.$refs.newProductForm.scrollIntoView({ behavior: 'smooth' });
        });

      } else {
        alert(this.$t('productNotFound'));
      }
    },
    async saveProduct() {
      if(this.isDemo()){
        return;
      }
      if (!this.newProduct.name || !this.newProduct.price || !this.newProduct.description || !this.newProduct.categoryId) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: this.$t('pleaseFillOutAllFields'),
        });
        return;
      }

      let imageUrl = '';
      if (this.selectedFile) {
        const result = await uploadImageAction(this.selectedFile);
        if (result.ok) {
          imageUrl = result.data.data.Location; // URL de la imagen subida
          if (!this.newProduct.images) {
            this.newProduct.images = []
          }
          this.newProduct.images.push(imageUrl)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: result.message || this.$t('errorUploadingImage'),
          });
          return;
        }
      }

      if (this.isEditingProduct) {
        // Editando un producto existente
        const productIndex = this.jsonData.findIndex(p => p._id === this.newProduct._id);
        if (productIndex !== -1) {
          this.jsonData.splice(productIndex, 1, {
            ...this.newProduct,
            category: {
              _id: this.newProduct.categoryId,
              name: this.categoriesData.find(category => category._id === this.newProduct.categoryId).name
            }
          });
        }
      } else {
        // Añadiendo un nuevo producto
        const newProduct = {
          _id: Date.now().toString(),
          name: this.newProduct.name,
          price: this.newProduct.price,
          description: this.newProduct.description,
          images: this.newProduct.images,
          category: {
            _id: this.newProduct.categoryId,
            name: this.categoriesData.find(category => category._id === this.newProduct.categoryId).name
          }
        };
        this.jsonData.push(newProduct);
      }

      // Guardar todos los productos y categorías antes de refrescar
      await this.saveAllChanges();
      // Refrescar los productos después de guardar
      await this.refreshProducts();

      this.resetNewProductForm();
    },
    cancelNewProduct() {
      this.resetNewProductForm();
    },
    resetProduct() {
      this.newProduct = {
        _id: null,
        name: '',
        price: '',
        description: '',
        categoryId: '',
        images:[]
      };
      this.regularImg = false
      this.imageRegular = false
    },
    resetNewProductForm() {
      this.editingProductId = null;
      this.showNewProductForm = false;
    },
    async addNewCategory() {
      if (!this.newCategory.name || !this.newCategory.identifier) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: this.$t('pleaseFillOutAllFields'),
        });
        return;
      }

      const newCategory = {
        _id: Date.now().toString(),
        name: this.newCategory.name,
        identifier: this.newCategory.identifier,
        parentCategoryId: null,
        businessName: "6689769a22c3538df38ba1bb"
      };
      this.categoriesData.push(newCategory);

      // Guardar todas las categorías y productos antes de refrescar
      await this.saveAllChanges();
      // Refrescar las categorías después de guardar
      await this.refreshCategories();

      this.resetNewCategoryForm();
    },
    editCategory(categoryId) {

      this.editingProductId = null;
      this.editingCategoryId = categoryId;
      const category = this.categoriesData.find(c => c._id === categoryId);
      if (category) {
        if (category.nameTranslations == undefined || category.nameTranslations.length == 0) {
          this.translationsCat = [{ language: 'ES', value: '' }];
        } else {
          this.translationsCat = category.nameTranslations;
        }

        this.newCategory = {
          name: category.name,
          nameTranslations: this.translationsCat,
          identifier: category.identifier,
          icon: category.icon
        };

        this.showNewCategoryForm = true;
        this.showNewProductForm = false;

        this.$nextTick(() => {
          this.$refs.editCategoryForm.scrollIntoView({ behavior: 'smooth' });
        });
      } else {
        alert(this.$t('categoryNotFound'));
      }
    },
    async saveCategory() {
      if(this.isDemo()){
        return;
      }
      if (!this.newCategory.name) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: this.$t('pleaseFillOutAllFields'),
        });
        return;
      }

      if (this.isEditingCategory) {
        // Editando una categoría existente
        const categoryIndex = this.categoriesData.findIndex(c => c._id === this.editingCategoryId);
        if (categoryIndex !== -1) {
          this.categoriesData.splice(categoryIndex, 1, {
            _id: this.editingCategoryId,
            ...this.newCategory
          });
        }
      } else {
        // Añadiendo una nueva categoría
        const newCategory = {
          _id: Date.now().toString(),
          name: this.newCategory.name,
          icon: this.newCategory.icon,
          parentCategoryId: null,
          businessName: "6689769a22c3538df38ba1bb"
        };
        this.categoriesData.push(newCategory);
      }

      // Guardar todas las categorías y productos antes de refrescar
      await this.saveAllChanges();
      // Refrescar las categorías después de guardar
      await this.refreshCategories();

      this.resetNewCategoryForm();
    },
    cancelNewCategory() {
      this.resetNewCategoryForm();
    },
    resetNewCategoryForm() {
      this.newCategory = {
        name: '',
      };
      this.editingCategoryId = null;
      this.showNewCategoryForm = false;
    },
    async refreshCategories() {
      try {
        const response2 = await getAllCategoriesAction();
        this.categoriesData = response2.categories;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    async refreshProducts() {
      try {
        const response = await getAllProductAction();
        this.jsonData = response.products;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    confirmSaveAll() {
      if(this.isDemo()){
        return;
      }
      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('doYouWantToSaveAllChanges'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yesSaveIt')
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveAllChanges();
          Swal.fire(
            this.$t('saved'),
            this.$t('yourChangesHaveBeenSaved'),
            'success'
          )
        }
      })
    },
    getFlagSrc(language) {
      const country = this.languages.find(c => c.code === language);
      return country ? country.flag : '';
    },
    getCountryName(language) {
      const country = this.languages.find(c => c.code === language);
      return country ? `${this.$t('translation')} (${country.name})` : `${this.$t('translation')} (${language})`;
    },
    addInput(input) {
      input.push({ language: 'ES', value: '' });
    },
    removeInput(input, index) {
      input.splice(index, 1);
    },
    toggleLanguage(index) {
      const languages = ['ES', 'FR', 'EN'];
      const currentLangIndex = languages.indexOf(this.newProduct.nameTranslations[index].language);
      this.newProduct.nameTranslations[index].language = languages[(currentLangIndex + 1) % languages.length];
    },
    toggleLanguageDesc(index) {
      const languagesDesc = ['ES', 'FR', 'EN'];
      const currentLangIndexDesc = languagesDesc.indexOf(this.newProduct.descriptionTranslations[index].language);
      this.newProduct.descriptionTranslations[index].language = languagesDesc[(currentLangIndexDesc + 1) % languagesDesc.length];
    },
    toggleLanguageCat(index) {
      const languagesCat = ['ES', 'FR', 'EN'];
      const currentLangIndexCat = languagesCat.indexOf(this.newCategory.nameTranslations[index].language);
      this.newCategory.nameTranslations[index].language = languagesCat[(currentLangIndexCat + 1) % languagesCat.length];
    },
    async saveAllChanges() {
      if(this.isDemo()){
        return;
      }
      const cleanedJsonData = this.jsonData.map(product => {
        const newProduct = { ...product };
        if (!isNaN(newProduct._id)) {
          newProduct._id = null;
        }
        if (newProduct.category && !isNaN(newProduct.category._id)) {
          newProduct.category._id = null;
        }
        return newProduct;
      });

      const cleanedCategoriesData = this.categoriesData.map(category => {
        const newCategory = { ...category };
        if (!isNaN(newCategory._id)) {
          newCategory._id = null;
        }
        return newCategory;
      });

      await saveAllAction({ products: cleanedJsonData, categories: cleanedCategoriesData });
      this.showSaveButton = false;
    },
    updateJson() {
      const updatedJsonData = [];

      this.categories.forEach(category => {
        category.products.forEach(product => {
          updatedJsonData.push({
            ...product,
            category: {
              _id: category._id,
              name: category.name
            }
          });
        });
      });

      this.jsonData = updatedJsonData;
    },
    confirmDeleteProduct(productId) {
      if(this.isDemo()){
        return;
      }
      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('doYouWantToDeleteThisProduct'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yesDeleteIt')
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteProduct(productId);
          Swal.fire(
            this.$t('deleted'),
            this.$t('yourProductHasBeenDeleted'),
            'success'
          ).then((result) => {
              this.getAll();
            })
        }
      })
    },
    deleteProduct(productId) {
      if(this.isDemo()){
        return;
      }
      this.jsonData = this.jsonData.filter(product => product._id !== productId);
      // Guardar todos los productos y categorías antes de refrescar
      this.saveAllChanges();
      // Refrescar los productos después de eliminar
      this.refreshProducts();
    },
    confirmDeleteCategory(categoryId) {
      if(this.isDemo()){
        return;
      }
      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('doYouWantToDeleteThisCategory'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yesDeleteIt')
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteCategory(categoryId);
          Swal.fire(
            this.$t('deleted'),
            this.$t('yourCategoryHasBeenDeleted'),
            'success'
          ).then((result) => {
              this.getAll();
            })
        }
      })
    },
    deleteCategory(categoryId) {
      this.categoriesData = this.categoriesData.filter(category => category._id !== categoryId);
      // Guardar todas las categorías y productos antes de refrescar
      this.saveAllChanges();
      // Refrescar las categorías después de eliminar
      this.refreshCategories();
    },
  }
};
</script>




<style scoped>
.md-card img{
    width: auto !important;
    height: 100px;
}
.flag{
  font-size:32px;
}
.country-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.country-input .flag {
  width: 30px;
  height: 20px;
  margin-right: 10px;
}
.name-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.name-input .flag {
  width: 30px;
  height: 20px;
  margin-right: 10px;
}
.category {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.product {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product span {
  flex-grow: 1;
}
.icon-food{
  width:40px;
}
.icon-food img{
  height:40px !important;
}

.md-select-menu .md-list-item .md-list-item-content span{
  padding-top: 5px;
  padding-bottom: 5px;
}
.md-list-item-text img{  
  height:40px !important;
  width:40px;
}
.md-select-menu .md-list-item .md-list-item-content {
    min-height: 50px;
    text-align: center;
}

</style>
