<template>
  <div>
    <FormValidate
      class="createexercise"
      v-if="(preloadedData && preloadedDataReady && optionElements && optionBodyZone)"
      :inputs="formInputs"
      :requiredFields="['name', 'bodyZone']"
      buttonLabel="Save Exercise"
      :formTitle="labelform"
      :preloadedData="preloadedDataReady"
      @cancel="cancel()"
      @formSubmitted="handleFormSubmit">
    
      <div slot="top" class="text-secondary mt-3 m-2">Elije la información por defecto de peso, series, repeticiones, etc, involucrada en este ejercicio, Podrás personalizar estos valores al momento de crear una rutina para tus alumnos.</div>
    </FormValidate>
    <FormValidate
        v-if="(!preloadedData && optionElements && optionBodyZone)"
      class="createexercise"
      :inputs="formInputs"
      :requiredFields="['name', 'bodyZone']"
      buttonLabel="Save Exercise"
      :formTitle="labelform"
      @cancel="cancel()"
      @formSubmitted="handleFormSubmit">
    
      <div slot="top" class="text-secondary mt-3 m-2">Elije la información por defecto de peso, series, repeticiones, etc, involucrada en este ejercicio, Podrás personalizar estos valores al momento de crear una rutina para tus alumnos.</div>
    </FormValidate>
  </div>
</template>

<script>
import FormValidate from '@/components/FormValidate.vue';
import { createExerciseAction, updateExerciseAction, getExerciseElementsAction,getBodyZonesElementsAction } from '@/pages/Training/actions';
import { getAllElementsAction } from '@/pages/Training/actions';
import Swal from "sweetalert2";

export default {
  name: "CreateExercise",
  components: {
    FormValidate
  },
  props: {
    preloadedData: {
      type: Object
    }
  },
  data() {
    return {
      optionElements:[],
      optionBodyZone:[],
      labelform:'New Exercise',
      preloadedDataModif: false,
      preloadedDataReady:false,
      formInputs: [
        { name: 'name', label: this.$t('name'), type: 'text' },
        { name: 'description', label: this.$t('description'), type: 'text' },
        { name: 'image', label: 'Image URL', type: 'image' },
      ],
    };
  },
  async created() {
     this.optionElements =  await getExerciseElementsAction();
     const elements = {
          name: 'elements',
          label: 'Elementos',
          type: 'select',
          multiple: true, // Permitir múltiples zonas del cuerpo
          options: this.optionElements.elements
        };
     this.formInputs.push(elements)

     this.optionBodyZone =  await getBodyZonesElementsAction();
     const bodyzoneselect = {
          name: 'bodyZone',
          label: this.$t('bodyZone'),
          type: 'select',
          multiple: true, // Permitir múltiples zonas del cuerpo
          options: this.optionBodyZone.result
        };
     this.formInputs.push(bodyzoneselect)

      this.initializePreloadedData(); // Inicializar el preloadedData
      if(this.preloadedData){
        this.labelform = this.$t("edit");
      }
  },
  methods: {  
    cancel(){
          this.$emit('cancel');
    },
  async initializePreloadedData() {
      if (this.preloadedData) {
        this.preloadedDataModif = { ...this.preloadedData };
      

        // Opcional: Asegúrate de que otros campos de preloadedData estén bien formateados
        this.preloadedDataModif.bodyZone = this.preloadedData.bodyZone || [];
        this.preloadedDataModif.repetitions = this.preloadedData.repetitions || 0;
        this.preloadedDataModif.sets = this.preloadedData.sets || 0;
        this.preloadedDataModif.weight = this.preloadedData.weight || 0;
        this.preloadedDataModif.duration = this.preloadedData.duration || 0;
        this.preloadedDataModif.distance = this.preloadedData.distance || 0;
        this.preloadedDataModif.speed = this.preloadedData.speed || 0;
        this.preloadedDataModif.rest = this.preloadedData.rest || 0;
        this.preloadedDataModif.image = this.preloadedData.image || '';

        this.preloadedDataReady = this.preloadedDataModif
      }
    },
    async loadElements() {
      try {
        const response = await getAllElementsAction();
        if (response.ok) {
          // Llenar el select de elementos con los elementos obtenidos
          this.formInputs.find(input => input.name === 'elements').options = response.elements.map(element => ({
            value: element._id,
            text: element.name
          }));
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to load elements: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error loading elements:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    async handleFormSubmit(formData) {
      try {
        let response;
        if (this.preloadedData && this.preloadedData._id) {
          // Si hay preloadedData, estamos editando un ejercicio existente
          response = await updateExerciseAction(
            this.preloadedData._id,
            formData
          );
        } else {
          // Si no hay preloadedData, estamos creando un nuevo ejercicio
          response = await createExerciseAction(
            formData
          );
        }

        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: this.preloadedData ? 'Exercise updated successfully' : 'Exercise created successfully',
            timer: 2000,
            showConfirmButton: false
          });
          this.$emit('formSubmitted');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to save exercise: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error saving exercise:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    }
  }
};
</script>
<style>
.createexercise .field-row.number-field {
  width: 100px !important;
  float:left;
  margin: 10px;
}
.createexercise .field-row.text-field,
.createexercise .field-row.select-field {
 
}
.createexercise .field-row.number-field input{
  width: 50px !important;
  text-align:center;
  background:#d1d1d1;
  border-bottom:0px !important;
  border-radius: 3px;
}

</style>
