import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";


export const getQrs = async (id) => {
  try {
    const { data } = await wencesApi.get(`/api/menus/${id}/qrs`);

    return {
      ok: true,
      qrs: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getAllMenuAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/menus`);

    return {
      ok: true,
      menus: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const getMenuByOrderId = async (orderId) => {
  try {
    const { data } = await wencesApi.get(`/api/menus/getbyorder/${orderId}`);
    return {
      ok: true,
      result: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const saveAllAction = async (jsonData) => {
  try {

    let dataToSend = JSON.parse(JSON.stringify(jsonData));

  // Eliminar los IDs temporales
    dataToSend = dataToSend.map(menu => {
      if (!menu._id || menu._id.length !== 24) {
        delete menu._id;
       // menu._id = null;
      }
      return menu;
    });


    const { data } = await wencesApi.post(`/api/menus/saveall`, 
      dataToSend
    );

    return {
      ok: true
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};