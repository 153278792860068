<template>
  <div :id="carouselId" class="carousel slide">
    <div class="carousel-inner">
      <div
        class="carousel-item"
        :class="{ active: index === 0 }"
        v-for="(image, index) in images"
        :key="index"
      >
        <img :src="image.src" class="d-block w-100" :alt="image.alt" />
        <div class="carousel-caption">
          <h5>{{ image.title }}</h5>
          <p>{{ image.description }}</p>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" :data-bs-target="'#' + carouselId" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" :data-bs-target="'#' + carouselId" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    id: {
      type: String,
      required: true
    },
    images:{ 
      type: Array
    }
  },
  computed: {
    carouselId() {
      return this.id;
    }
  }
};
</script>

<style>
/* Puedes agregar estilos personalizados si los necesitas */
.carousel-caption {
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente para mejor legibilidad */
  padding: 10px;
  border-radius: 10px;
}
</style>
