import { useAuthStore } from "../stores/auth.store";
import { AuthStatus } from "../interfaces";

const isNotAuthenticatedGuard = async (to, from, next) => {


    const currentUrl = window.location.href;
const subdomainPattern = /^https:\/\/.*\.app\.gotake\.it(?:\/#)?\/register$/;

  if (subdomainPattern.test(currentUrl)) {
    window.location.href = 'https://app.gotake.it/register';
    return; // Detiene la ejecución del guard
  }
  
  const authStore = useAuthStore();

  // console.log(to);
  await authStore.checkAuthStatus();

  authStore.authStatus === AuthStatus.Authenticated
    ? next()
    : next();
};

export default isNotAuthenticatedGuard;
