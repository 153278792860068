<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100" >
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>payment</md-icon>
          </div>
          <h4 class="title mb-2">Solicitudes de pago</h4>
              <div v-if="isGym()">
                <p class="md-text text-secondary"><md-icon>chat</md-icon>"Las solicitudes de pago se generan automáticamente solo para los clientes que registran accesos al gimnasio en los últimos 2 meses."</p>
                <strong class="md-text">"Para generar solicitudes de pago en clientes que no registraron accesos, puedes hacerlo en el listado de clientes."</strong>
                <small></small>
              </div>
        </md-card-header>
  <!-- Filtro de estado -->
          <div class="container">
              <div class="row mt-4 p-3">
                <div class="col-md-3 col-sm-12">   
                  <md-field>
                    <label>{{$t('filter')}}</label>
                    <md-select v-model="selectedStatus" @md-selected="selectedOrders = []">
                      <md-option value="">{{$t('all')}}</md-option>
                      <md-option value="cancelled">{{$t('cancelledOrders')}}</md-option>
                      <md-option value="close">Pagado</md-option>
                      <md-option value="pending">{{$t('paymentPending')}}</md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="col-md-3 col-sm-12"> 
                    <!-- Botones de Delete y Select All -->
                    <md-button 
                      class="md-danger md-warn mt-4 w-100" 
                      @click="deleteSelectedOrders"
                      :disabled="selectedOrders.length === 0"
                    >
                      {{$t('delete')}}
                    </md-button>
                </div>
                <div class="col-md-3 col-sm-12"> 
                    <md-button 
                      class="md-primary mt-4 px-3" 
                      @click="toggleSelectAll"
                    >
                      {{ areAllSelected ? $t('deselectAll') : $t('selectAll') }}
                    </md-button>
                </div>
                <div class="col-md-3 col-sm-12" v-if="false"> 
                    <md-button 
                      class="md-success md-warn mt-4 w-100" 
                      :disabled="selectedOrders.length === 0"
                      @click="createReceipt()"
                    >
                      <md-icon class="material-icons text-white">receipt_long</md-icon> 
                      {{$t('generateReceipt')}}
                    </md-button>
                </div>

              </div>
          </div>
        <md-card-content>



          <div class="loading-container mr-2 w-100 text-center p-4" v-if="isLoading">
            <md-progress-spinner 
              :md-diameter="80" 
              md-mode="indeterminate" 
              class="text-red bg-red md-red">
            </md-progress-spinner>
          </div>
          <div class="w-100 text-center customborder" v-if="filteredOrders.length == 0 && !isLoading">
             <h5 class="text-secondary p-4">{{$t('nothingtosee')}}</h5>
          </div>

          <md-table :class="notShowMobile()" v-model="filteredOrders" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Select">
                <md-checkbox v-model="selectedOrders" :value="item._id"></md-checkbox>
              </md-table-cell>
              <md-table-cell :md-label="$t('status')">
                

                <span class="badge mb-1" :class="getClass(item.status)">
                  {{ getLabel(item.status) }}
                  <PaymentButton v-if="stablishment && item.status == 'pending' && isClientGym()" :item="item" :stablishment="stablishment"/>
               
                            
                </span>
                <div v-if="item.payment && item.status == 'close'">  

                  <span class="badge badge-success" v-if="!item.payment.mercadoPagoPreferenceId && !item.payment.screenCapture"><md-icon class="text-white">price_check</md-icon class="text-white"> Pago manual</span>

                  <span target="_blank"  v-if="item.payment.screenCapture"  class="w-100 badge badge-success text-white" ><md-icon class="text-white">add_card</md-icon> Por transferencia o link</span>


                  <span v-if="item.payment.screenCapture" ><a target="_blank"   class="w-100 text-primary" :href="item.payment.screenCapture"><md-icon class="text-primary">newspaper</md-icon> Ver comprobante</a>
                  </span>

                  <span class="badge badge-success" v-if="item.payment.mercadoPagoPreferenceId"><md-icon class="text-white">payments</md-icon class="text-white">Mercado Pago</span>
               </div>


              </md-table-cell>
              <md-table-cell :md-label="$t('name')">
                <small>{{ item.buyerName }}</small>
              </md-table-cell>
              <md-table-cell :md-label="$t('email')">{{ item.buyerEmail }}</md-table-cell>



              <md-table-cell md-label="Total">
                <span class="badge-success badge">
                  {{ item.totalPrice }} 
                  <span v-if="item.currency">{{ item.currency }} 
                  </span>
                </span>
              </md-table-cell>



              <md-table-cell :md-label="$t('paymentDate')" v-if="item.createdAt">
               <strong>Período de pago:</strong> <span v-if="item.paymentDate">{{ localDateDay(item.paymentDate) }}</span><br>
               <strong>Solicitud enviada el:</strong> {{ localDateDay(item.createdAt) }}<br>
               <strong>Pagado el:</strong> <span v-if="item.payment">{{ localDateDay(item.payment.createdAt) }}</span>
             </md-table-cell>

              <md-table-cell :md-label="$t('products')">
                <div v-for="product in item.products" :key="product._id">
                  <span class="badge-success badge">{{ product.product.price }} 
                    <span v-if="item.currency">{{ item.currency }}</span>
                  </span> x 
                  <span class="badge badge-primary">{{ product.quantity }}</span>
                  <span>{{ product.product.name }}</span>
                </div>
              </md-table-cell>

              <md-table-cell :md-label="$t('stablishments')">
                <span v-if="item.stablishment"><md-icon>business</md-icon> {{ item.stablishment.name }}</span>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div :class="showMobile()">
            <div v-for="item in filteredOrders" class="border-bottom border-1 p-2 mb-3 card">

              <div  class="text-left">
                <md-checkbox v-model="selectedOrders" :value="item._id"></md-checkbox>
                <h5 v-if="item.stablishment && item.qr"><md-icon>business</md-icon>  {{ item.stablishment.name }} - {{item.qr.name}}</h5>
                <div> <strong> {{ item.buyerEmail }}</strong>  </div>
                <div>  <small>{{ item.buyerPhone }}</small>  </div>
             
              </div>

              <div  class="text-left  p-2">
                <div v-for="product in item.products" :key="product._id">
                  
                  <span> {{ product.product.name }}</span> <span class="badge-success badge">{{ product.product.price }} 
                    <span v-if="item.currency">{{ item.currency }}</span>
                  </span> x 
                  <span class="">{{ product.quantity }}</span>
                </div>
   
              <div v-if="item.createdAt">
               <strong>Período de pago:</strong> <span v-if="item.paymentDate">{{ localDateDay(item.paymentDate) }}</span><br>
               <strong>Solicitud enviada el:</strong> {{ localDateDay(item.createdAt) }}<br>
               <strong>Pagado el:</strong> <span v-if="item.payment">{{ localDateDay(item.payment.createdAt) }}</span>
             </div>
               </div>
                <span class="badge float-right" :class="getClass(item.status)">
                  {{ getLabel(item.status) }}
                  <PaymentButton v-if="stablishment && item.status == 'pending' && isClientGym()" :item="item" :stablishment="stablishment"/>



                <div v-if="item.payment && item.status == 'close'">  

                  <span class="badge badge-success" v-if="!item.payment.mercadoPagoPreferenceId && !item.payment.screenCapture"><md-icon class="text-white">price_check</md-icon class="text-white"> Pago manual</span>

                  <span target="_blank"  v-if="item.payment.screenCapture"  class="w-100 badge badge-success text-white" ><md-icon class="text-white">add_card</md-icon> Por transferencia o link</span>

                  <span class="badge badge-success" v-if="item.payment.mercadoPagoPreferenceId"><md-icon class="text-white">payments</md-icon class="text-white">Mercado Pago</span>

               </div>


                </span>
                  <span v-if="item.payment && item.status == 'close' && item.payment.screenCapture" class="w-100" ><a target="_blank"   class="w-100 text-primary" :href="item.payment.screenCapture"><md-icon class="text-primary">newspaper</md-icon> Ver comprobante</a>
                  </span>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import PaymentButton from "@/components/PaymentButton.vue";

import { uploadImageAction } from "@/pages/Products/actions";

import permissionsMixin from '@/mixins/permissionsMixin';

import { confirmPaymentOrderAction,payClientAction,onePayAction } from "@/pages/MercadoPago/actions";
import { getProfileStablishmentsAction } from "@/pages/Stablishments/actions";


import { getAllOrdersAction, getClientPaymentsAction, deleteAction, createReceiptAction } from "@/pages/Orders/actions"; // Asegúrate de tener la acción deleteAction importada
import { format } from 'date-fns';


export default {
  mixins: [permissionsMixin],
  components:{
    PaymentButton
  },
  created() {

    if(  this.$route.query && this.$route.query.orderId
     && this.$route.query.status
     && this.$route.query.preference_id){

      this.confirmPaymentOrder();
    }else{
      this.getAll();
    }
    this.getStablishments();
  },
  data() {
    return {
      imageUrl:false,
      isLoading: true,
      orderSelected: false,
      orders: [], // Todas las órdenes recibidas del backend
      selectedOrders: [], // Mantiene los IDs de las órdenes seleccionadas
      selectedStatus: '', // Estado seleccionado para filtrar
      stablishment:false,
      allStablishments: [],
      selectedImageFiles:[],
      screenCapture:[],
      isUploading:false,
      screenImages:false
    };
  },
  computed: {
    // Computed para filtrar las órdenes según el estado seleccionado
    filteredOrders() {
      if (this.selectedStatus) {
        return this.orders.filter(order => order.status === this.selectedStatus);
      }
      return this.orders; // Si no hay filtro, muestra todas las órdenes
    },
    // Computed para verificar si todas las órdenes visibles están seleccionadas
    areAllSelected() {
      return this.filteredOrders.length > 0 && this.filteredOrders.every(order => this.selectedOrders.includes(order._id));
    }
  },
  methods: {
    async getStablishments(){
      this.isLoading = true;

      const stablishmentsResponse = await getProfileStablishmentsAction();
      this.allStablishments = stablishmentsResponse.stablishments
      if(this.allStablishments){
       this.stablishment = this.allStablishments[0];
      }
    
      this.isLoading = false;
    },
    onImagesChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.selectedImageFiles = files; // Convertimos los archivos en un array
      this.createImagePreviews(this.selectedImageFiles);
    },
    createImagePreviews(files) {
       const file = files[0]
        if (file.type === 'application/pdf') {
          // Si es PDF, agregar un marcador de PDF al array de menuImages
          this.screenCapture = 'pdf-icon'; // solo usamos un marcador
        } else {
          // Si no es PDF, mostrar la imagen como de costumbre
          let reader = new FileReader();
          reader.onload = (e) => {
            this.screenCapture = e.target.result; // Agregamos cada imagen al array
          };
          reader.readAsDataURL(file);
        }
      
    },
    async uploadImage(file){
      const response = await uploadImageAction(file);
      if (response.ok) {
        let imageUrl = response.data.data.Location; // URL de la imagen subida
        return imageUrl;
      }else{
        return false;
      }
    },
    async uploadScreenCapture(){
      if(this.isDemo()){
            return;
          }
       this.isUploading = true;
        
        try {
          // Subir las imágenes de forma paralela usando Promise.all
          let newImages = [];
          if (this.selectedImageFiles.length > 0) {
            newImages = await this.uploadImage(this.selectedImageFiles[0]);
            
            
            // Verificar si alguna subida falló
            if (!newImages) {
              throw new Error(this.$t('errorUploadingImage'));
            }
            // Asignar las imágenes subidas al menú
            this.screenImages = newImages;
          }

        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.message || this.$t('errorUploadingImage'),
          });
        } finally {
          this.isUploading = false;
        }
    },
    async confirmPaymentOrder(){
        await confirmPaymentOrderAction(this.$route.query);

            Swal.fire({
          title: this.$t('success'),
          icon: 'success',
          confirmButtonText: 'OK'
        });

        this.getAll();
    },
    showMobile(){
      return 'd-sm-none';
    },
    notShowMobile(){
      return 'd-none d-sm-block';
    },
    localDate(utcDate) {
      const date = new Date(utcDate);
      return format(date, 'PPpp');  // Formato de fecha y hora según la hora local del navegador
    },
    localDateDay(utcDate) {
      const date = new Date(utcDate);
      return format(date, 'PP');  // Formato de fecha y hora según la hora local del navegador
    },
    getClass(status) {
      if (status == 'cancelled') {
        return "badge-danger";
      }
      if (status == 'close') {
        return "badge-success";
      }
      if (status == 'ongoing' || status == 'done') {
        return "badge-warning";
      }
      if (status == 'pending') {
        return "badge-danger";
      }
      return "badge-primary";
    },
    getLabel(status) {
      if (status == 'cancelled') {
        return this.$t('cancelledOrders');
      }
      if (status == 'close') {
        return 'Pagado';
      }
      if (status == 'ongoing') {
        return this.$t('inKitchen');
      }
      if (status == 'done') {
        return this.$t('alertingCustomer');
      }
      if (status == 'pending') {
        return this.$t('paymentPending');
      }
      return this.$t('newOrders');
    },
    async getAll() {
      try {
        const result = await getClientPaymentsAction();
        this.orders = result.orders;
        this.isLoading = false;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    // Método para eliminar órdenes seleccionadas
    async deleteSelectedOrders() {
      if (this.selectedOrders.length === 0) return;

      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('youWontBeAbleToRevertThis'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yesDeleteIt'),
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteAction(this.selectedOrders); // Aquí llamamos a la acción deleteAction
            this.getAll(); // Refrescar lista de órdenes
            this.selectedOrders = []; // Limpiar selección
            Swal.fire(this.$t('deleted'), '', 'success');
          } catch (error) {
            Swal.fire(this.$t('error'), error.message, 'error');
          }
        }
      });
    },
    // Método para seleccionar o deseleccionar todas las órdenes visibles
    toggleSelectAll() {
      if (this.areAllSelected) {
        // Si todas están seleccionadas, deseleccionamos todo
        this.selectedOrders = [];
      } else {
        // Si no, seleccionamos todas las órdenes filtradas
        this.selectedOrders = this.filteredOrders.map(order => order._id);
      }
    },
    async createReceipt() {
      if (this.selectedOrders.length === 0) return;

      Swal.fire({
        title: this.$t('areYouSure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('ok'),
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {

            await createReceiptAction({orders: this.selectedOrders});

            this.getAll(); // Refrescar lista de órdenes
            this.selectedOrders = []; // Limpiar selección
            Swal.fire(this.$t('receiptCreated'), '', 'success');
          } catch (error) {
            Swal.fire(this.$t('error'), error.message, 'error');
          }
        }
      });
    }
  },
};
</script>




<style scoped>
.menu {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.menu-actions {
  display: flex;
  justify-content: flex-end;
}
.product {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 5px;
}
.product span {
  flex-grow: 1;
}
</style>
