<template>
<div>
  <div class="md-layout">
      <div class="w-100">
        <router-link  class="text-white float-left" :to="{ path: '/stablishments' }" > <md-button class="md-primary md-sm"><md-icon class="material-icons">arrow_back</md-icon> 
            {{ $t('stablishments') }} </md-button>
        </router-link>
      </div>
      <div class="w-100">
        <div class="" v-if="!editing">
        <md-button  class="md-primary md-sm" @click="editing=true">{{$t('edit')}}</md-button>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100 w-100" v-else>
          <md-button  class="md-danger md-sm" @click="editing=false">{{$t('cancel')}}</md-button>
        </div>
        <Invoice v-if="stablishment"
          :defaultBusinessName="stablishment.name" 
          :invoice="invoice" 
          class="float-left"
          :edit="editing" 
          @save-invoice="saveInvoice($event)">
        </Invoice>
    </div>
  </div>
</div>
</template>

<script>

import {
  Invoice
} from "@/components";

import { getStablishmentAction,saveInvoiceAction } from "@/pages/Stablishments/actions";


export default {
  components: {
    Invoice,
  },
  data() {
    return {
      stablishment:false,
      invoice:{},
      editing:false
    };
  },
  created() {
    this.getAll();
  },
  methods: {
    
    async getAll() {
      try {
        const response = await getStablishmentAction(this.$route.params.stablishment_id);
       
        if(response.invoices){
          this.invoice = response.invoices[0];
        }  
        this.stablishment = response.stablishment;
       
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    async saveInvoice(event) {
      try {
        event.stablishment_id = this.$route.params.stablishment_id
        const response = await saveInvoiceAction(event);
        this.editing=false;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  }
};
</script>


