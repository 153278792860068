<template>
  <div class="md-layout">
      <div class="md-layout-item md-size-100">
    
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>sensor_door</md-icon>
          </div>

            <h4 class="title">Accesos por día</h4>
                <div>
                  <h6 class="title">Cantidad de accesos al gimnasio promedio por día</h6>
                </div>
            </md-card-header>
            <md-card-content>
                  <chart-card
                      v-if="ready"
                      header-animation="false"
                      :chart-data="roundedLineChart.data"
                      :chart-options="roundedLineChart.options"
                      chart-type="Bar"
                      chart-inside-header
                      no-footer
                      background-color="blue"
                      class="mt-4"
                    >   
                    </chart-card>
            </md-card-content>
          </md-card>

      
      </div>
  </div>
</template>

<script>
import { ingressByUserAction,ingressByDayAction } from "@/pages/Statistics/actions";


import { ChartCard } from "@/components";
export default {
  components: {
    ChartCard,
  },
  name: "IngressByUser",

  data() {
    return {  
      days : ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      ready:false,
      roundedLineChart: {
        data: {},
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 10,
          }),
          axisX: {
            showGrid: true,
          },
          low: 0,
          high: 100, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          showPoint: false,
        },
      },
    };
  },


  created() {
    this.fetchBusinessData();
  },

  methods: {
    async fetchBusinessData() {
      try {
        const response = await ingressByDayAction();
        this.roundedLineChart.data = response.data;
        this.roundedLineChart.options.high = (Math.max(...response.data.series[0])*2);

        this.ready = true
        
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    },
  },

};
</script>
