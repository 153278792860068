export default {
  addNewMenu: 'Add New Menu',
menuName: 'Menu Name',
deliveryConfirmation: 'Your order has already been delivered',
forgotPassword: 'I forgot my password',
sendEmail: 'Send email',
tokenExpired: 'The token has expired',
tryAgain: 'Try again with a new email',
alreadySubscribedUnlimited: 'You are already subscribed to the unlimited plan',
loadClients: 'Load clients',
  "paymentPerAccessOrQuota": "Payment per number of accesses or quotas",
accesses: 'Accesses',
  "pending": "Pending",
  "notEnrolledInAnyClass": "You are not enrolled in any class",
  "myProfile": "My profile",
    "done": "Done",
    "stickQRMessage": "Stick the QR code on the door of your establishment so your clients can scan it when entering to control access",
  "noQuotaLeft": "You have no slots left",
  "perMonth": "per month",
    "download": "Download",
    "select": "Select",
     "clientAccess": "Client access",
      "bodyZone": "Body zone",
      'perderpeso' : 'Lose weight',
'ganarmusculo' : 'Gain muscle',
'tonificacion' : 'Toning',
'movilidad' : 'Mobility',
  "inprogress": "In progress",
    'shoulders' : 'Shoulders',
'biceps' : 'Biceps',
'triceps' : 'Triceps',
'chest' : 'Chest',
'back' : 'Back',
'abdomen' : 'Abdomen',
'glutes' : 'Glutes',
'quadriceps' : 'Quadriceps',
'hamstrings' : 'Hamstrings',
'calves' : 'Calves',
    "enrolledPeople": "Enrolled people",
    "monthlyPayment": "Monthly payment",
     "unlimitedMonthlyPayment": "Monthly payment with unlimited access to the gym and unlimited class quotas",
  "unlimitedMonthlyPaymentWithQuota": "Monthly payment with unlimited access to the gym and {cupos} class slots per month",
  "limitedMonthlyPayment": "Monthly payment with {access} gym entries per month and {cupos} class slots per month",
  "limitedAccessUnlimitedQuota": "Monthly payment with {access} gym entries per month and unlimited class slots",
  "oneTimePayment": "One-time payment for {access} gym entries and {cupos} class slots",
  myClients: "My clients",
    "classLimitPerMonth": "Number of classes this client can enroll in",
  "mustLoginToSignUp": "You must log in to sign up",
    "emailExistsMessage": "This email already exists.",
      "quota": "Quota",
        "noQuotaLeftThisMonth": "You have no slots available for this month",
        "recurringActivitiesMessage": "Recurring activities do not allow registration for now, but we will improve this soon. Choose a specific date to enable registrations.",
  "allowOverQuotaMessage": "Choosing this option allows any client, who has no available slots but has paid their monthly fee, to register as well.",
  "anyClientCanSignUp": "Any client can sign up",
        "allowedAccessCount": "Number of allowed accesses (QR scan when entering the establishment)",
        "remainingQuota": "Remaining quota",
          "unlimitedAccess": "Unlimited access",
      "waitingForEntries": "Waiting for entries",
        "paymentMethod": "Payment method",
pay: 'Pay',
  "myClasses": "My classes",
    "availableSlots": "Available slots",
      "scanQrFirst": "You must scan the QR code first before you can click to enter.",
  "myCalendar": "My calendar",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
      "availableEntries": "Available entries",
    "Saturday": "Saturday",
    "Sunday": "Sunday",
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",
  "noSlotsAvailable": "No slots available for this class",
    "allready": "You are already enrolled in this class",
  "defineSpecificDate": "Define a specific date",
  "signUpForClass": "Sign up for a class",
    "signUpForThisClass": "Sign up for this class",
  "unlimitedQuota": "Unlimited quota",
    "temporaryIssue": "We are experiencing a temporary issue, please try again later.",
  "quotaMessage": "By setting quotas, the system will not allow people to keep registering once the limit is reached. To register, they must scan the QR code you place in your gym or use the link:",
  "seeDemo": "See demo",
    "enableRegistrations": "Enable registrations for this activity",
  "seeDemoBeforeSignUp": "I want to see a demo before signing up.",
    "leaveEmptyIfNoQuota": "Leave it empty if your classes have no defined quotas.",
  "uploadFile": "Upload file",
    "demoMessage": "This is a demo, you need to create your own account to modify options. You can do it at:",
  "uploadMenuMessage": "You can upload an image for each page of the menu or a PDF with the entire menu.",
  "imagemenu": "Show only the menu photo, without the option for the customer to place orders.",
  "gotakeitUsageMessage": "This allows you to start using Gotakeit without needing to load clients manually. They will create their profiles, and you will need to insert the payment date later.",
  "leaveEmptyForEmail": "Leave this empty if you want to use your email as the identifier.",
  "identifierExistsMessage": "This identifier has already been used by another user.",
    "lastEntry": "Last entry",
  "shareScheduleInfo": "You will be able to share the schedules with your clients by posting the link on your social media. They will also be able to view it on their phone when entering the gym by scanning the QR code.",
  "activityLabel": "Activity",
  "activityPlaceholder": "Activity name",
  "selectDateLabel": "Select date",
    "selfRegistrationMessage": "Allow people to register themselves when scanning the QR code if they do not have a profile created yet.",
  "startTimeLabel": "Start time",
  "endTimeLabel": "End time",
    "leaveScreenOpenMessage": "Leave this screen open in the browser at your establishment, and you will be able to verify every time someone enters by scanning the QR code.",
  "timePlaceholder": "HH:MM",
    "identifierMessage": "Insert an identifier that you can easily remember. You will use it here to access the gym each time you scan the QR code. Example: your phone number, ID, or email.",
  "removeButton": "Remove",
    "uploadImageMessage": "Upload an image that shows your face to identify you when entering the gym.",
  "addButton": "Add activity",
  "saveGridButton": "Save schedule",
    "addDailyActivityTitle": "Add Daily Activity",
  "activityLabel": "Activity",
  "activityPlaceholder": "E.g.: Yoga",
  "dayOfWeekLabel": "Day of the week",
  "startTimeLabel": "Start time",
  "endTimeLabel": "End time",
  "addButton": "Add Activity",
  "Lunes": "Monday",
  "Martes": "Tuesday",
  "Miércoles": "Wednesday",
  "Jueves": "Thursday",
  "Viernes": "Friday",
  "Sábado": "Saturday",
  "Domingo": "Sunday",
differentWaysToUse: 'Different ways to use Go Take It',
maxOrders: 'Maximum number of orders',
maxOrdersLimit: 'Maximum number of orders this establishment can take at the same time. If the number is exceeded, the QR will display the menu but will not allow new orders to be generated.',
rejected: 'Rejected',
kitchenStatusOrders: 'Orders in "kitchen" status',
imageSet1_step1_title: '1- Scan the QR',
imageSet1_step1_description: 'They scan the QR at a pool, beach, or market.',
imageSet1_step2_title: '2- Receive the order',
imageSet1_step2_description: 'The order is received on a tablet or smartphone.',
imageSet1_step3_title: '3- Notify the customer',
imageSet1_step3_description: 'The customer receives an alert when the dish is ready and goes to pick it up.',
generateReceipt: 'Generate receipt',
paymentsAndReceipts: 'Payments and receipts',
  waitingPeriod: "Waiting period", // Inglés
  waitingPeriodExplanation: "Choose the number of days you want to wait before showing a red banner to a client who hasn't paid.",
imageSet2_step1_title: '1- Scan the QR',
imageSet2_step1_description: 'Scan the QR and order the products you want.',
imageSet2_step2_title: '2- Receive the order',
imageSet2_step2_description: 'The restaurant receives the order in the kitchen.',
imageSet2_step3_title: '3- The waiter serves the dish',
imageSet2_step3_description: 'The waiter brings the order to the table.',

imageSet3_step1_title: '1- Customers view the menu',
imageSet3_step1_description: 'They view the menu but cannot create orders.',
imageSet3_step2_title: '2- The waiter takes the order',
imageSet3_step2_description: 'The waiter accesses the table QR and generates the order.',
imageSet3_step3_title: '3- Receive the order',
imageSet3_step3_description: 'The restaurant receives the order in the kitchen.',
imageSet3_step4_title: '4- The waiter serves the dish',
imageSet3_step4_description: 'The waiter brings the order to the table.',
imageSet3_step5_title: '5- Customers pay',
imageSet3_step5_description: 'Customers pay the traditional way or scan the QR to pay.',
completePayment: 'Complete your payment',
remainingAccesses: 'Remaining accesses',
sendMessageChat: 'Send us a message in the chat',
importantNotifications: 'Here you will receive important notifications about the activity of people in this establishment.',
selectAll: 'Select all',
filter: 'Filter',
perMonth: 'per month',
markAsPaid: 'Mark as paid',
remainingOrders: 'Remaining orders',
unsubscribe: 'Unsubscribe',
contactForHelp: 'If you have any questions about how the system works or how to subscribe, write to us in the chat and we will help you. Don’t worry, you will be talking to a human.',
subscribe: 'Subscribe',
unlimitedPlan: 'Unlimited plan',
noCreditsQRInactive: 'Without credits, your QRs will stop working',
cancelableSubscription: 'Cancelable subscription at any time, no commitment.',
subscriptionActivated: 'Your subscription has been activated, enjoy GoTakeIt without limits!',
inviteToSubscribe: 'We invite you to subscribe to the unlimited plan',
monthlySubscription: 'Monthly subscription of 10,000 Argentine pesos per month',
monthlySubscriptionEur: 'Monthly subscription of 12,00 € per month',
cancelSubscription: 'You can cancel the subscription at any time through MercadoPago',
unlimitedCredits: 'Unlimited credits, unlimited access to all features',
business: 'Business',
delete: 'Delete',
paymentDate: 'Payment date',
  "access_reminder": "You have {credits} credits left",
  "subscription_invite": "We invite you to subscribe by choosing the 'subscription' option in the menu",
  "payment_due": "{days} days left until your monthly payment is due",
   "access_denied": "Access denied",
  "payment_overdue": "Your payment was overdue by {days} days.",
  identifierNotFound: 'The identifier does not exist',
accepted: 'Accepted',
paymentDateInfo: 'Enter your clients with the payment date so the system knows when to deny access. By default, the system allows access if the date has not been entered.',
clientsStartUsing: 'Your clients start using it',
qrSettings: 'QR settings',
qrScanNotification: 'Your clients scan the QR every time they enter the establishment, and you receive a notification if they are allowed to enter or not',
employee_role: 'Waiter',
qrConfigurationInfo: 'Define settings for your different QRs and associate that configuration with the QR you want. You can set colors, languages, and phrases to use.',
client_role: 'Client',
deactivateQR: 'Deactivate QR',
digitalQrMenu: 'Digital QR menu for restaurants, beach parasol, pools, or hotels',
nameAlreadyTaken: 'That name has already been taken by another user',
accessControl: 'Access control for gyms, clubs, and private establishments',
restaurant_role: 'Administrator',
gym_role: 'Administrator',
insertClients: 'Insert your clients with a name and an identifier',
popupNotification: 'We will notify you with a popup on this screen when your order is ready',
activateQR: 'Activate QR',
toPay: 'To pay',
businessName: 'Your business name',
consentNotice: 'By using the Gotakeit app, you are consenting to our terms of use and privacy policy. You understand that your information is processed on behalf of the stablishment and will be shared with them.',
paymentPending: 'Payment pending',
goToMyApp: 'Go to my app',
menuTypesDescription: 'There are 4 types of menus you can choose from depending on the needs of your business',
users: 'Users',
addUser: 'Add user',
userRolesDescription: 'You can create another admin user or an employee role user. The employee role allows the user to use GO Take It on their phone to take orders and generate orders themselves for cases where the consumer can only view the menu but cannot generate orders themselves.',
orderSummaryEmail: 'We sent you an email with your order summary, remember to check SPAM and remove the email from that folder to correctly receive the alert when your order is ready.',
    "new_password": "New Password",
    "repeat_password": "Repeat Password",
    "change_password": "Change Password",
    "password_too_short": "Password must be at least 6 characters long.",
    "password_needs_symbol": "Password must contain at least one symbol.",
    "passwords_do_not_match": "Passwords do not match.",
    "password_changed": "Password successfully changed.",
    "error_changing_password": "Error changing password.",
emailSent: 'Email sent',
correctEmail: 'If the email address is correct, you will receive a link to reset your password',
passwordResetEmail: 'We will send you an email with a link to reset your password',
orderDelivered: 'Your order has already been delivered, you can place another order by returning to the menu',
onlymenu: 'Show only the menu, without the option to place orders',
sendNotifications: 'We will send notifications to this email when you receive orders',
activatePaymentSystem: 'Activate payment system for orders',
customersMustPay: 'Customers must pay when placing the order.',
needToSyncPayments: 'You need to synchronize with our payment system to activate this option.',
  syncpayments:'Synchronize with payment platform',
  ifyouunsync:'If you unsynchronize, you will no longer be able to receive payments.',
  stripeAccountSync:'Your account has been successfully synchronized.',
  nowYouCanAccess:'You will now receive payments directly into your account, we are not intermediaries. You can now activate the option in your menu for customers to pay for their orders.',
    connectStripeAccount: 'Connect Stripe Account',
  syncStripeInfo: 'Syncing Stripe will enable payments so customers have to pay for their orders before placing them.',
  noStripeAccount: 'If you don’t have a Stripe account, you can create one and return to this screen to sync it.',
  alreadyHaveStripeAccount: 'If you already have a connected Stripe account, you can sync it by clicking here.',
  syncMyStripeAccount: 'Sync my Stripe Account',
  createOrSyncStripeAccount: 'Create or Sync Stripe Account',
  error: 'Error',
  stripeConnectError: 'Could not connect with Stripe. Please try again.',
  haveStripeAccount: 'Do you have a Stripe account?',
  redirectSync: 'If you already have an account, we will redirect you to sync it.',
  yesSyncAccount: 'Yes, sync account',
  noCreateAccount: 'No, create a new account',
  stripeAccountCreated: 'Stripe Account Created',
  syncStripeInfoAfterCreation: 'Now you need to sync it with us to start receiving payments.',
  stripeCreateError: 'Could not create Stripe account. Please try again.',
  genericError: 'An error occurred. Please try again.',
  qrhelp: 'You can create QR codes to link them to a menu. Then print that QR and place it on restaurant tables, beach umbrellas, or poolside beds in a hotel.',
  dashboard: 'Dashboard',
  total: 'Total',
  myOrder: 'My order',
  accessBlocked: 'Access blocked',
  serviceStart: 'Service start',
  accessGranted: 'Access granted',
  logout: 'Logout',
  orderNotification: 'We will send you an email or SMS when your order is ready.',
  name: 'Name',
  enterName: 'Enter your name',
  email: 'Email',
  enterEmail: 'Enter your email',
  comments: 'Comments',
  enterComments: 'Enter comments',
  code: 'Code',
  yespaid: 'Already paid',
  nopaid: "Not yet paid",
  paid: "Paid",
  enter: 'Enter',
  accessIdentifier: 'Access identifier',
  accessIdentifierInfo: 'This identifier will be what the client must enter when scanning the QR, it can be their National Identification Document, passport, or client number. Something they can easily remember.',
  phone: 'Phone',
  producthelp:'Remember that if you create a new product, you must then link it to the menu where you want it to be seen',
  enterPhone: 'Enter phone number',
  confirmOrder: 'Confirm order',
  yourOrder: 'Your order',
  orderDetails: 'Your order will be sent to the kitchen once you click send. We will notify you when it is ready for pickup.',
  sendOrder: 'Send Order',
  cancel: 'Cancel',
  orderNumber: 'Order Number',
  scanphone: "Scan the QR with your phone or click",
  preparingOrder: 'We are preparing your order',
dontClose: 'We will notify you with a popup on this screen when your order is ready and also send you an email to the address you entered earlier',
  orderReadyTitle: 'Your order is ready!',
  orderReadyText: 'You can pick up your order now.',
  welcome: 'Welcome',
  instructions: 'Please follow the instructions.',
  settings: 'Settings',
  currency: 'Currency',
  position_currency: 'Position currency',
  primary_color: 'Primary Color',
  secondary_color: 'Secondary Color',
  menu_languages: 'Menu Languages',
  request_location: 'Request Location',
  request_additional_information: 'Request Additional Information',
  menu_terms: 'Menu terms',
  save: 'Save',
  success: 'Success!',
  business_info_updated: 'Business information updated successfully',
  error: 'Error!',
  error_updating_business_info: 'There was an error updating the business information',
  english: 'English',
  spanish: 'Spanish',
  french: 'French',
  german: 'German',
  italian: 'Italian',
  takeaway: 'Orders to be picked up when the consumer is alerted',
  onsite: 'The order is received at the place where the QR code is scanned',
  menu_type: 'Menu type',
  myMap: 'My map',
  drawMap: 'Draw map',
  deleteWall: 'Delete Wall',
  addProduct: 'Add product',
  addCategory: 'Add category',
  editCategory: 'Edit Category',
  addNewCategory: 'Add New Category',
  categoryName: 'Category Name',
  identifier: 'Identifier',
  editProduct: 'Edit Product',
  addNewProduct: 'Add New Product',
  productName: 'Product Name',
  description: 'Description',
  productPrice: 'Product Price',
  category: 'Category',
  selectImage: 'Select image',
  change: 'Change',
  remove: 'Remove',
  inventory: 'Inventory',
  selectCategoryToAssign: 'Select Category to Assign',
  assignCategory: 'Assign Category',
  noCategory: 'No category',
  pleaseSelectCategoryAndProduct: 'Please select a category and at least one product!',
  productNotFound: 'Product not found!',
  pleaseFillOutAllFields: 'Please fill out all fields!',
  errorUploadingImage: 'Error uploading image',
  categoryNotFound: 'Category not found!',
  areYouSure: 'Are you sure?',
  doYouWantToSaveAllChanges: 'Do you want to save all changes?',
  yesSaveIt: 'Yes, save it!',
  saved: 'Saved!',
  yourChangesHaveBeenSaved: 'Your changes have been saved.',
  doYouWantToDeleteThisProduct: 'Do you want to delete this product?',
  yesDeleteIt: 'Yes, delete it!',
  deleted: 'Deleted!',
  yourProductHasBeenDeleted: 'Your product has been deleted.',
  doYouWantToDeleteThisCategory: 'Do you want to delete this category?',
  yourCategoryHasBeenDeleted: 'Your category has been deleted.',
  stablishments: 'Stablishments',
  menus: 'Menus',
  qrCodes: 'QR codes',
  addQr: 'Add QR',
  saveAll: 'Save All',
  addNewQr: 'Add New QR',
  qrName: 'QR Name',
  viewMenu: 'View menu',
  youWontBeAbleToRevertThis: 'You won\'t be able to revert this!',
  yourQrHasBeenDeleted: 'Your QR has been deleted.',
  emailPlaceholder: 'Email...',
  passwordPlaceholder: 'Password...',
  login: 'Login',
  incorrectUserOrPassword: 'Incorrect user or password',
  loginError: 'Error logging in',
  addNewStablishment: 'Add new stablishment',
  map: 'Map',
  edit: 'Edit',
  remove: 'Remove',
  viewOrders: 'View orders',
  viewOrdersMap: 'View orders map',
  sendToKitchen: "Send to kitchen",
  sendAlert: "Send alert",
  finish: "Finish",
  createdAgo: "Created ago",
  inKitchenFor: "In kitchen for",
  qrCode: "QR Code",
  email: "Email",
  phone: "Phone",
  comments: "Comments",
  alertSentAgo: "Alert sent ago",
  alerting: "Alerting",
  details: "Details",
  backToPending: "Back to pending",
  backToOngoing: "Back to ongoing",
  cancelOrder: "Cancel order",   
  enableSoundAlerts: "Enable sound alerts",
  newOrders: "New Orders",
  inKitchen: "In Kitchen",
  alertingCustomer: "Alerting Customer",
  closedOrders: "Closed Orders",
  cancelledOrders: "Cancelled Orders",
  orders: "Orders",
  accountconfirm:"Your account has been confirmed, you can login now.",
  products: "Products",
    termsRequired: 'You must accept the terms and conditions.',
  address: "Address",
    restaurantName: 'Restaurant name...',
  logoTitle: 'Logo',
  confirmEmail: 'Confirm your email',
  confirmEmailText: 'Please click on the link we sent to your email to confirm your account.',
  register: 'Register',
  enterAddress: "Enter your address",
  logo: "Logo",
  coverImage: "Cover Image",
  avgOrderValue: 'AVG order value',
  sales: 'Sales',
  topCustomers: 'Top Customers',
  topOrderedProducts: 'Top Ordered Products',
  password: 'Password...',
  termsAndConditions: 'I agree to the',
ticket: 'Check',
  termsLinkText: 'terms and conditions',
  getStarted: 'Get Started',
  createApp: 'We create the app',
  createAppDesc: 'We create the app for your restaurant.',
  addProducts: 'You add products',
  addProductsDesc: 'You add the products to the menu and configure to generate the QR codes.',
  customersEnjoy: 'Your customers enjoy',
  customersEnjoyDesc: 'Your customers scan the QR code and place their orders.',
  restaurantNameExists: 'Restaurant name already exists.',
  errorCheckingRestaurantName: 'Error checking restaurant name.',
  nameRequired: 'required.',
  restaurantNameRequired: 'Restaurant name is required.',
  emailRequired: 'Email is required.',
  emailInvalid: 'Email format is not valid.',
  passwordRequired: 'Password is required.',
  userExists: 'User already exists.',
     "nifLabel": "N.I.F",
    "invoiceTitle": "Simplified Invoice",
    "dateLabel": "Date",
    "invoiceNumberLabel": "No",
    "tableLabel": "Table",
    "guestLabel": "Diners",
    "waiterLabel": "Waiter",
    "totalWithoutTaxLabel": "Total without VAT",
    "taxLabel": "VAT",
    "totalLabel": "Total",
    "tipLabel": "TIP",
    "thankYouMessage": "VAT Included - Thank you for your visit",
    "description": "Description",
    "quantity": "Qty",
    "importUsers": "Import Users from Excel File (.xls or .xlsx)",
    "matchColumns": "Match columns",
    "selectDateFormat": "Select date format",
    "previewImport": "Preview Import",
    "previewUsers": "Preview Users to Import",
    "noDataAvailable": "No data available for preview.",
    "confirmAndImport": "Confirm and Import",
    "resume": "Resume",
    "pause": "Pause",
    "error": "Error",
    "emptyFile": "The file is empty or has no data.",
    "missingHeaders": "The file must contain headers in the first row.",
    "limitExceeded": "Limit Exceeded",
    "importLimit": "Cannot import more than 3000 contacts in one execution.",
    "missingHeadersCSV": "The CSV file must contain headers in the first row.",
    "importCancelled": "Import Cancelled",
    "errorCreatingUser": "Error creating user {name}:",
    "importedUsers": "{createdUsers} users were successfully imported or edited."
};
