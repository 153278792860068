<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>payments</md-icon>
          </div>
            <h4 class="title">{{$t('settings')}}</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
           <div class="w-100  p-2 card">
               <label class="md-form-label text-primary">{{ $t('waitingPeriod') }}</label>                
               <small>({{$t('waitingPeriodExplanation')}})</small>

                <md-field>
                <md-select v-model="settings_waitingDays" >
                  <md-option value="1">1 día</md-option>
                  <md-option value="2">2 días</md-option>
                  <md-option value="3">3 días</md-option>
                  <md-option value="4">4 días</md-option>
                  <md-option value="5">5 días</md-option>
                  <md-option value="6">6 días</md-option>
                  <md-option value="7">7 días</md-option>
                  <md-option value="8">8 días</md-option>
                  <md-option value="9">9 días</md-option>
                  <md-option value="10">10 días</md-option>
                </md-select>
                </md-field>
            </div>

            <div class="float-left w-100 mt-3 p-2 card">
                <md-field class="mt-0 p-0 ">
                <md-checkbox v-model="settings_sendReminderEmail" class="mt-0"><span class=" text-primary">Enviar un email de recordatorio</span></md-checkbox>
                </md-field>
                <small>Se enviará un email de recordatorio cuando se acerca la fecha de pago y cuando hay retraso en el pago.</small>
               <small>(Se enviará solo a aquellos clientes que tengan una solicitud de pago en estado pendiente) 
              </small><small class="text-primary"> <router-link  to="/myorders">Ver solicitudes de pago</router-link></small>
            </div>

            <div class="float-left w-100 mt-3 p-2 card">
                <md-field class="mt-0 p-0 ">
                <md-checkbox v-model="settings_register" class="mt-0"><span class=" text-primary">Permitir auto registro</span></md-checkbox>
                </md-field>
                <small> {{ $t('selfRegistrationMessage') }}</small>
               <small>({{$t('gotakeitUsageMessage')}})</small>
            </div>

            <div class="float-left w-100 mt-4">
              <div class="w-100  p-2">
                 <h6>Elije de que manera quieres que el sistema cobre a tus clientes</h6>
              </div>
              <div class="w-100 card p-2">
                  <md-field class="mt-0  p-0">
                    <md-checkbox v-model="settings_mercadopago" class="mt-0"><span class=" text-primary">Con Mercado Pago:</span></md-checkbox>  
                    <img width="30px"  class="d-inline mercadopago" :src="mercadopago"/> 
                  </md-field>  <small>Si elijes esta opción el sistema generará una suscripcion directa de tu cuenta de Mercado Pago con debito automático a tus clientes. Cuando el cliente pague, el sistema marcara ese mes como "Pagado"</small>
                <div class="w-100" v-if="settings_mercadopago">

                  <div>
                      <md-button class="md-raised md-primary" @click="conectarMercadoPago()" :disabled="isLoading || mp_client">
                        Sincronizar con Mercado Pago
                        <div class="loading-container mr-2" v-if="isLoading">
                          <md-progress-spinner 
                            :md-diameter="20" 
                            md-mode="indeterminate" 
                            class="md-secondary mr-2">
                          </md-progress-spinner>
                        </div>
                      </md-button>
                  </div>
                  <div v-if="mp_client" class="text-success w-100 mb-4 ">
                   <md-icon class="text-success">check</md-icon>
                    Ya estás sincronizado.
                    <div class="md-layout-item md-size-100  p-2 w-100 float-left">               
                  

                        <div class="w-100 float-left">
                        <small class="pt-2 text-secondary">Los clientes al conectarse en su cuenta verán un boton "PAGAR" con el monto a pagar que tu definas en el listado de clientes.</small></div>
                    </div>
                    <div class="w-100  float-left">
                    <md-button class="md-raised md-danger" @click="unSync()" :disabled="isLoading">
                      Desincronizar <md-icon>link_off</md-icon>
                     
                    </md-button>
                  </div>
                  </div>
                  </div>
              </div>
              <div class="w-100  p-2 card mt-4">
                  <md-field class="mt-2  p-0">
                    <md-checkbox v-model="settings_paymentLink" class="mt-0"><span class="text-primary">Con Link o transferencia bancaria:</span></md-checkbox>   
                  </md-field> <small> El sistema le pedirá a tu cliente que pague a través del link de pago o cualquier cuenta bancaria que tu prefieras.</small>

                  <md-field class="mt-2 p-0" v-if="settings_paymentLink">
                   <label>Link de pago, CVU, Alias...</label> <md-input v-model="settings_paymentInfo" type="string">Subir comprobante:</md-input>   
                  </md-field>  

                  <md-field class="mt-4  p-0" v-if="settings_paymentLink">
                    <md-checkbox v-model="settings_uploadRecipt" class="mt-0">Marcar como pagado automáticamente:</md-checkbox>   
                  </md-field>  
                  <small v-if="settings_paymentLink">El sistema le pedirá a tu cliente que suba el comprobante de pago, cuando el cliente lo suba, el sistema marcara el mes como "pagado". Luego podrás verificar los comprobantes en el listado de clientes opción "comprobantes" para chequear que sean correctos</small>
              </div>
            </div>
         
          <div class="md-layout mt-4">
            <div class="md-layout-item md-size-50">
              <md-button class="md-raised md-primary" @click="saveSettings">{{ $t('save') }}</md-button>
            </div>
          </div>


          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>


<script>
import { saveAction, getSettingsAction } from "@/pages/Auth/actions";

import { hasMercadoPagoAction,removeMercadoPagoAction,unSyncAction } from "@/pages/MercadoPago/actions";
import permissionsMixin from '@/mixins/permissionsMixin';
import Swal from 'sweetalert2';


export default {
  mixins: [permissionsMixin],
  data() {
    return {
      settings:{
          register:true,
          waitingDays:10,
      },
      mercadopago: process.env.BASE_URL+'img/mercado-pago.png',
      mp_client:false,
      isLoading: false, // Estado de carga
      authorizationCode: null, // Código de autorización devuelto por Mercado Pago
      role: false,
      nameBusiness: "",
      emailBusiness: "",
      useredit:false,
      subdomain: "",
      currencyOptions:[],
      settings_paymentLink: '',
      settings_waitingDays: 10,
      settings_mercadoago: false,
      settings_register: false,
      settings_paymentInfo: '',
      settings_uploadRecipt: false,
      settings_sendReminderEmail:false
    };
  },
  created(){
    this.hasMercadoPago();
  },
  methods: {
    async hasMercadoPago(){
      const result = await hasMercadoPagoAction();
      console.log(result.data)
      if(result.data){
        this.mp_client = result.data

      }
    },
    async fetchBusinessData() {
      try {
        const response = await getSettingsAction();
        const data = response.result;
        this.emailBusiness = data.email === "contact@rodisio.com" ? "" : data.email || "";
        this.nameBusiness = data.name || "";

        this.settings_paymentLink = data.paymentLink;
        this.settings_waitingDays = data.waitingDays;
        this.settings_mercadopago = data.mercadoPago;
        this.settings_register = data.register;
        this.settings_paymentInfo = data.paymentInfo;
        this.settings_uploadRecipt = data.uploadRecipt;
        this.settings_sendReminderEmail = data.sendReminderEmail;

          

      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    },

    async saveSettings() {
      if(this.isDemo()){
        return;
      }
      try {

        const data = {
          paymentLink: this.settings_paymentLink,
          waitingDays: this.settings_waitingDays,
          mercadoPago: this.settings_mercadopago,
          register: this.settings_register,
          paymentInfo: this.settings_paymentInfo,
          uploadRecipt: this.settings_uploadRecipt,
          sendReminderEmail: this.settings_sendReminderEmail,
        }


        await saveAction(data);
        Swal.fire({
          title: this.$t('success'),
          text: this.$t('business_info_updated'),
          icon: 'success',
          confirmButtonText: 'OK'
        });
      } catch (error) {
        console.error("Error saving business data:", error);
        Swal.fire({
          title: this.$t('error'),
          text: this.$t('error_updating_business_info'),
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },

    async unSync(){
      const result = await unSyncAction();
      this.mp_client = null
    },

    async conectarMercadoPago() {
      this.isLoading = true;

      const subdomain = window.location.hostname.split('.')[0];

      // URL de autorización de Mercado Pago con parámetros
      const clientId = process.env.VUE_APP_MP_CLIENT_ID_OAUTH; // Aquí va tu client_id de Mercado Pago
      const redirectUri = encodeURIComponent('https://app.gotake.it/mercadopago/oauth'); // Cambia a tu URL de redirección configurada
      const state = subdomain + '-' +Date.now(); // Opcional: un parámetro state para evitar CSRF
      localStorage.setItem('mercadoapagooauth',state);
      const scope = encodeURIComponent('offline_access read write'); // Scopes correctos, sin duplicación

      // Construir la URL de autorización
      const authorizationUrl = `https://auth.mercadopago.com.ar/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;

      // Redirigir al usuario a la URL de autorización
      window.location.href = authorizationUrl;

      this.isLoading = false;
    },
    // Método para extraer el "authorization_code" de la URL cuando el cliente vuelva de Mercado Pago
    obtenerAuthorizationCode() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code'); // Obtener el "code" de la URL
      if (code) {
        this.authorizationCode = code; // Guardar el código de autorización
        // Aquí puedes enviar el código a tu backend para intercambiarlo por un access token
      }
    }
  },
  mounted() {
    // Cuando el componente se monte, intenta obtener el authorization_code si está presente
    this.obtenerAuthorizationCode();

    this.fetchBusinessData();
  }
};
</script>

<style scoped>
.mercadopago {
      background: #00bcff;
    width: 30px;
    height: 30px;
    border-radius: 7px;
}
.loading-container {
  display: inline-block;
}
</style>
