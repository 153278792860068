import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";

export const checkAuthAction = async () => {
  try {


    const localToken = localStorage.getItem("token");
    if (localToken && localToken.length < 10) {
      return { ok: false };
    }

    const { data } = await wencesApi.get("/api/users/check-status", {
      headers: {
        Authorization: `Bearer ${localToken}`,
      },
    });
    return {
      ok: true,
      user: data.user,
      token: data.token,
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
      };
    }

    throw new Error("No se pudo verificar la sesión");
  }
};
